import { IWorksheet } from '../../shared/models/worksheet';
import ConstantsTool from '../../shared/utils/ConstantsTool';
import Config from '../../shared/utils/config';
import { Dispatch } from 'redux';
import { ITemplate, replaceZoneByLinkedText } from '../../redux/reducers/createWorksheet';

// const getWorksheetIdFromUrl = () => {
//     let worksheetIdInUrl = "";
//     let pathName = window.location.pathname;

//     if (pathName.includes(Routes.WORKSHEET_MAKER)) {
//         try {
//             worksheetIdInUrl = window.location.hash.split("-")[1];
//         } catch (e) {}
//     } else {
//         const params = new URLSearchParams(window.location.search);
//         worksheetIdInUrl = params.get("worksheetId");
//     }
//     return worksheetIdInUrl;
// };

export const getSourceUserBehaviors = (pagesWorksheets: any[]) => {
    let sourceUserBehaviors: number[] = [];
    pagesWorksheets.forEach((page: any) => {
        if (
            page.sourcePage != null &&
            page.sourcePage != undefined &&
            !sourceUserBehaviors.includes(page.sourcePage)
        ) {
            sourceUserBehaviors.push(page.sourcePage);
        }
    });
    return sourceUserBehaviors.sort();
};

export const getUseInteractiveElement = (pagesWorksheets: any[]) => {
    for (let index = 0; index < pagesWorksheets.length; index++) {
        const resourceItems = pagesWorksheets[index].resourceItems;
        for (let i = 0; i < resourceItems.length; i++) {
            const resourceItem = resourceItems[i];
            if (
                resourceItem?.type === ConstantsTool.TYPE_RESOURCE_SHAPE ||
                resourceItem?.type ===
                    ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE
            ) {
                return true;
            }
        }
    }
    return false;
};

export const modifyWsBaseOnEditModeFromUrl = (
    worksheet: IWorksheet,
    userId: string
) => {
    let worksheetId = "";
    let editMode = Config.EDIT_WS_MODE.CLONE;
    try {
        let splitValues = window.location.hash.split("&");
        worksheetId = splitValues[1].split("=")[1];
        editMode = parseInt(splitValues[2].split("=")[1]);
    } catch (e) {}
    if (
        !(
            (editMode == Config.EDIT_WS_MODE.EDIT ||
                !window.location.hash.includes("edit")) &&
            userId === worksheet.authorId
        )
    ) {
        worksheet.authorId = userId;
        worksheet.id = null;
    }
    return worksheet;
};

export const getEditMode = (data: { userId: string; authorId: string }) => {
    let { userId, authorId } = data;
    let editMode = Config.EDIT_WS_MODE.CLONE;
    try {
        let splitValues = window.location.hash.split("&");
        editMode = parseInt(splitValues[2].split("=")[1]);
    } catch (e) {}
    if (editMode == Config.EDIT_WS_MODE.EDIT && userId === authorId) {
        editMode = Config.EDIT_WS_MODE.EDIT;
    }
    return editMode;
};

export const generateStoreThumbnailContent = (zones: Array<any>, content: object, dispatch: Dispatch) => {
    const generateLinkedCommonFields = (zone: any) => ({
        id: Math.floor(Math.random() * 999999999).toString(),
        x: zone.x,
        y: zone.y,
        width: zone.width,
        height: zone.height,
        positionIndex: zone.positionIndex,
        customZoneID: zone.id,
        rotate: zone.rotate,
    });

    const resultItems = zones.map((zone) => {
        let result = null;
        const linkedCommonFields = generateLinkedCommonFields(zone);

        if (checkZoneContentIsImage(content?.[zone.fieldName])) {
            result = {
                ...ConstantsTool.DEFAULT_TEXT_LINKED_IMAGE,
                ...linkedCommonFields,
                type: ConstantsTool.RESOURCE_IMAGE,
                imageAttribute: {
                    ...zone.imageAttribute,
                    url: content?.[zone.fieldName] || ConstantsTool.DEFAULT_IMAGE,
                },
            };

            dispatch(
                replaceZoneByLinkedText({
                    zoneID: zone.id,
                    pageIndex: zone.pageIndex,
                    linkedText: result,
                    isMathFormula: false,
                })
            );
        } else if (zone.type === ConstantsTool.TYPE_RESOURCE_ZONE) {
            result = {
                ...ConstantsTool.DEFAULT_TEXT_LINKED_ZONE,
                ...linkedCommonFields,
                textAttribute: {
                    ...zone.textAttribute,
                    content: zone.fieldName,
                },
            };

            dispatch(
                replaceZoneByLinkedText({
                    zoneID: zone.id,
                    pageIndex: zone.pageIndex,
                    linkedText: result,
                    isMathFormula: false
                })
            );
        }

        return result;
    });

    return resultItems.filter((item) => item !== null);
};

export const getDimensionOfWorksheet = (pageType: string = 'template', templateData: ITemplate) => {
    const { orientation, spacing, width: templateWidth, height: templateHeight } = templateData;
    let width: number;
    let height: number;

    if (templateWidth && templateHeight) {
        width = templateWidth;
        height = templateHeight;
    } else if (pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail'] || pageType === 'thumbnail') {
        width = ConstantsTool.WIDTH_STORE_THUMBNAIL;
        height = templateData['marketplace'] === 'tes' ? ConstantsTool.HEIGHT_TES_THUMBNAIL : ConstantsTool.HEIGHT_STORE_THUMBNAIL;
    } else if (orientation === 'horizontal') {
        width = ConstantsTool.HEIGHT_A4;
        height = ConstantsTool.WIDTH_A4;
    } else {
        width = ConstantsTool.WIDTH_A4;
        height = ConstantsTool.HEIGHT_A4;
    }

    return { width, height, spacing, };
};

export const checkZoneContentIsImage = (zoneContent: string = '') => {
    const imageExtensions = ['.png', '.jpg', '.jpeg', '.gif', '.bmp', '.webp'];

    const lowerCaseZoneContent = zoneContent.toLowerCase();

    return zoneContent.startsWith('http') && imageExtensions.some(ext => lowerCaseZoneContent.includes(ext));
};

export const extractMathExpression = (inputString: string = ''): {type: 'string' | 'math', content: string}[] => {
    const pattern = /<math>(.*?)<\/math>/g; // Regular expression pattern to match math expressions enclosed in <math> tags
    const items: {type: 'string' | 'math', content: string}[] = [];
    let lastIndex = 0;
    let match: RegExpExecArray | null;

    while ((match = pattern.exec(inputString)) !== null) {
        const startIndex = match.index;
        const endIndex = pattern.lastIndex;

        if (startIndex > lastIndex) {
            items.push({ type: 'string', content: inputString.substring(lastIndex, startIndex) });
        }

        const mathContent = match[1];
        if (mathContent) {
            items.push({ type: 'math', content: mathContent });
        }

        lastIndex = endIndex;
    }

    if (lastIndex < inputString.length) {
        items.push({ type: 'string', content: inputString.substring(lastIndex) });
    }

    return items;
};