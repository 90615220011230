import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';

import './styles.scss';
import AnswerFieldsTable from './answer-fields-table';
import { useAppSelector } from '../../../../redux/hook';
import { useEffect, useState } from 'react';
import { getDataFromAPI, updateDataAPI } from '../../../../utils/query';
import { useDispatch } from 'react-redux';
import { setContent, setTemplate, setTemplateChanged, setTemplateZoneDisplayMode } from '../../../../redux/reducers/createWorksheet';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import TemplateTags from './template-tags';

import axios from 'axios';
import { getTemplateFields } from '../../../../utils/template';
import PromptInteractive from '../prompt-interactive';
import TabCreatePixelTemplate from './tab-create-pixel-template';
import SpacingTemplateSetting from './spacing-template-setting';

function TabTemplatePage({}) {
    const dispatch = useDispatch();

    const {pageType, template, pagesWorksheet, tempTemplateZones} = useAppSelector(state => state.createWorksheetState);
    const [loadingAIContent, setLoadingAIContent] = useState(false);
    const [categories, setCategories] = useState([]);

    useEffect(() => {
        fetchAllCategories();
    }, []);

    const fetchAllCategories = async () => {
        const res = await getDataFromAPI('auto-content/categories');
        setCategories(res.data);
    }

    const generateTestContent = async(testMode: string) => {
        setLoadingAIContent(true);
        const fields = getTemplateFields(template, pagesWorksheet[0].resourceItems, tempTemplateZones);
        try {
            dispatch(setTemplateZoneDisplayMode('zoneOnly'));
            dispatch(setContent({ data: {} }));
            const resFieldsFormat = await updateDataAPI('POST', 'auto-content/generate-template-fields-format-from-fields-list/?templateID=' + template.id, fields);
            await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
                activity: template.activity,
                note: template.note,
                avoidDuplicationNote: template.avoidDuplicationNote,
            });
            const res = await updateDataAPI('POST', 'auto-content/generate-template-test-content/?templateID=' + template.id + '&testMode=' + testMode);
            dispatch(setContent({ data: res.data }));
            dispatch(setTemplate({...template, fieldsFormat: resFieldsFormat.data }));
        } catch (err) {
            window.alert('Fields Setting Error');
        }
        setLoadingAIContent(false);
    }

    const updateTemplate = async(field: string, value: any, syncAPI = false) => {
        if (!syncAPI) {
            dispatch(setTemplateChanged(true));
        };
        if (template && template.id) {
            dispatch(setTemplate({...template, [field]: value}));
            if (syncAPI) {
                await updateDataAPI('PATCH', 'auto-content/templates/' + template.id + '/', {
                    [field]: value
                });
            }
        } else {
            dispatch(setTemplate({...template, [field]: value}));
        }
    }

    const uploadOriginalImage = async (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files) {
            return;
        }

        const formData = new FormData();
        formData.append('file', event.target.files[0]);

        console.log(event.target.files);

        const res = await axios.post(ConstantsTool.API_URL + 'auto-content/upload-auto-content-template/', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                Authorization: 'Token ' + localStorage.getItem(ConstantsTool.LOCAL_STORAGE_TOKEN)
            },
        });

        updateTemplate('originalImageUrl', ConstantsTool.S3_TEMPLATE_RESOURCE_URL + res.data);
    }

    return (
        <div className="tab-template-page">
            <Accordion defaultActiveKey="overview" flush>
                <Accordion.Item eventKey="overview">
                    <Accordion.Header>Overview</Accordion.Header>
                    <Accordion.Body>
                        <Form>
                            {pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                                <PromptInteractive />
                            )}
                            <Form.Group>
                                <Form.Label><strong>Name</strong></Form.Label>
                                <Form.Control value={template?.name} onChange={e => updateTemplate('name', e.target.value)} />
                            </Form.Group>
                            {template && template.id && (
                                <>
                                    {pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                                        <>
                                            <Form.Group>
                                                <Form.Label><strong>Request</strong></Form.Label>
                                                <Form.Control as="textarea" rows={3} value={template?.activity} onChange={e => updateTemplate('activity', e.target.value)} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label><strong>Note</strong></Form.Label>
                                                <Form.Control as="textarea" rows={4} value={template?.note} onChange={e => updateTemplate('note', e.target.value)} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Avoid Duplication Note</Form.Label>
                                                <Form.Text>Don't reuse</Form.Text>
                                                <Form.Control as="textarea" rows={2} value={template?.avoidDuplicationNote} onChange={e => updateTemplate('avoidDuplicationNote', e.target.value)} />
                                                <Form.Text>from your previous answers</Form.Text>
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Note for Designer</Form.Label>
                                                <Form.Control as="textarea" rows={3} value={template?.noteForDesigner} onChange={e => updateTemplate('noteForDesigner', e.target.value)} />
                                            </Form.Group>
                                            <Form.Group>
                                                <Form.Label>Original Image</Form.Label>
                                                <Form.Control accept="image/*" type="file"
                                                    onClick={(event) => {
                                                        event.currentTarget.value = null;
                                                    }}
                                                    onChange={uploadOriginalImage} />
                                                {template?.originalImageUrl && (
                                                    <img src={template.originalImageUrl} className="template-original-image" />
                                                )}
                                            </Form.Group>
                                        </>
                                    )}
                                    <Form.Group>
                                        <Form.Label>Orientation</Form.Label>
                                        <Form.Select onChange={e => updateTemplate('orientation', e.target.value)} value={template?.orientation}>
                                            <option value="vertical">Vertical</option>
                                            <option value="horizontal">Horizontal</option>
                                        </Form.Select>
                                    </Form.Group>
                                    {(pageType === ConstantsTool.TEMPLATE_TYPES['answerSheet'] || pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail']) && (
                                        <Form.Group>
                                            <Form.Label>Store</Form.Label>
                                            <Form.Control onChange={e => updateTemplate('store', e.target.value)} value={template?.store} />
                                        </Form.Group>
                                    )}
                                    {pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                                        <Form.Group>
                                            <Form.Check
                                                id="auto-add-headline-checkbox"
                                                label="Auto Headline"
                                                type="checkbox"
                                                checked={template?.autoAddHeadline}
                                                onChange={e => updateTemplate('autoAddHeadline', !template.autoAddHeadline)}
                                            />
                                            <Form.Check
                                                id="auto-add-frame-checkbox"
                                                label="Auto Frame"
                                                type="checkbox"
                                                checked={template?.autoAddFrame}
                                                onChange={e => updateTemplate('autoAddFrame', !template.autoAddFrame)}
                                            />
                                            <Form.Check
                                                id="auto-add-name-checkbox"
                                                label="Auto Name"
                                                type="checkbox"
                                                checked={template?.autoAddName}
                                                onChange={e => updateTemplate('autoAddName', !template.autoAddName)}
                                            />
                                        </Form.Group>
                                    )}
                                </>
                            )}
                        </Form>
                    </Accordion.Body>
                </Accordion.Item>
                {template && template.id && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                    <Accordion.Item eventKey="Template Size">
                        <Accordion.Header>Template Size</Accordion.Header>
                        <Accordion.Body>
                            <SpacingTemplateSetting />
                        </Accordion.Body>
                    </Accordion.Item>
                )}

                {template && template.id && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                    <Accordion.Item eventKey="answerkey">
                        <Accordion.Header>Answer Key</Accordion.Header>
                        <Accordion.Body>
                            <AnswerFieldsTable answerFields={template?.answerFields} />
                        </Accordion.Body>
                    </Accordion.Item>
                )}
                {template && template.id && (pageType === ConstantsTool.TEMPLATE_TYPES['template'] || pageType === ConstantsTool.TEMPLATE_TYPES['storeThumbnail']) && (
                    <Accordion.Item eventKey="prompt">
                        <Accordion.Header>Tags</Accordion.Header>
                        <Accordion.Body>
                            <h3>Tags</h3>
                            <TemplateTags
                                template={template}
                                tags={template?.tagsData}
                                categories={categories}
                                refreshCategories={fetchAllCategories} />
                        </Accordion.Body>
                    </Accordion.Item>
                )}
                {template && template.id && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                    <Accordion.Item eventKey="pixelTemplate">
                        <Accordion.Header>Pixel Template</Accordion.Header>
                        <Accordion.Body>
                            <TabCreatePixelTemplate />
                        </Accordion.Body>
                    </Accordion.Item>
                )}
            </Accordion>


        </div>
    );
}

export default TabTemplatePage;