import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Config from "../../../../shared/utils/config";
import { addEventGa } from "../../../lib/ga";
import {
    checkAvailableReferralByEmailAction,
    sendReferralMailAction,
    updateCurrentRecipientEmailStatusAction,
} from "../../../redux/action/referral.action";
import { ResourceAppState } from "../../../redux/reducer/root.reducerModule";
import { validateEmail } from "../../../utils";
import Routes from "../../../utils/routes";
import useComponentVisible from "../../common/click-out-side";
import "./index.scss";
const InviteContent = () => {
    const dispatch = useDispatch();
    const [currentEmail, setCurrentEmail] = useState("");
    const [disableInput, setDisableInput] = useState(false);
    const [emails, setEmails] = useState([]);

    const [message, setMessage] = useState<any>("");
    const [activeCopy, setActiveCopy] = useState(false);
    const [protocol, setProtocol] = useState("https://");
    const [referralLink, setReferralLink] = useState("");
    const [host, setHost] = useState("worksheetzone.org");
    const validateReferralEmailStatus = useSelector(
        (state: ResourceAppState) =>
            state.referralState.validateReferralEmailStatus
    );

    const userId = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState?.data?.id
    );
    const ref = useRef();

    // useComponentVisible(ref, (e) => {
    //     if (currentEmail) {
    //         let result = validateEmail(currentEmail.trim());
    //         if (result) {
    //             dispatch(
    //                 checkAvailableReferralByEmailAction(currentEmail.trim())
    //             );
    //         }
    //     }
    // });
    const { isComponentVisible } = useComponentVisible(ref);
    useEffect(() => {
        if (isComponentVisible && currentEmail) {
            let result = validateEmail(currentEmail.trim());
            if (result) {
                dispatch(
                    checkAvailableReferralByEmailAction(currentEmail.trim())
                );
            }
        }
    }, [isComponentVisible]);
    // const referralLink = window.location.origin + "/referral-login/" + userId;
    useEffect(() => {
        if (typeof window !== "undefined") {
            setProtocol(window?.location?.protocol + '//');
            setHost(window?.location?.host ?? host);
            let eleUrl = document.querySelector(".link-refer .text-url.value");
            eleUrl &&
                eleUrl.addEventListener("copy", (event: any) => {
                    addEventGa({
                        action: "refer_copy_link",
                        params: {},
                    });
                });
        }
    }, []);
    useEffect(() => {
        if (!!userId && typeof window !== "undefined") {
            setReferralLink(
                window?.location?.origin + Routes.REFERRAL_LOGIN + "/" + userId
            );
        }
    }, [userId]);
    useEffect(() => {
        if (currentEmail) {
            switch (validateReferralEmailStatus) {
                case Config.INVALID_EMAIL: {
                    setEmails((prevEmails) => [...prevEmails, currentEmail]);
                    setCurrentEmail("");
                    setMessage(
                        <p className="text-err">
                            Please enter a valid email address
                        </p>
                    );
                    setDisableInput(true);
                    break;
                }
                case Config.EXISTED_UESER: {
                    setMessage(
                        <p className="text-ok">
                            {currentEmail} already uses Worksheetzone
                        </p>
                    );
                    setCurrentEmail("");
                    break;
                }
                case Config.AVAILABLE_REFERER: {
                    setEmails((prevEmails) => [
                        ...prevEmails,
                        currentEmail.trim(),
                    ]);
                    setCurrentEmail("");
                    setMessage("");
                    break;
                }

                default:
                    break;
            }
            dispatch(
                updateCurrentRecipientEmailStatusAction(
                    Config.DEFAULT_REFERRAL_STATUS
                )
            );
        }
    }, [validateReferralEmailStatus]);

    const handleClickCopy = async () => {
        const copy = async () => {
            addEventGa({
                action: "refer_copy_link",
                params: {},
            });
            await navigator.clipboard?.writeText(
                typeof window !== "undefined" ? referralLink : ""
            );
            setActiveCopy(true);
        };
        copy();
    };

    useEffect(() => {
        if (activeCopy) {
            let timer = setTimeout(() => {
                setActiveCopy(false);
            }, 5000);
            return () => {
                clearTimeout(timer);
            };
        }
    }, [activeCopy]);
    const handleChange = (e) => {
        e.preventDefault();
        setCurrentEmail(e.target.value);
    };

    const handleKeyPress = (e) => {
        if (e.keyCode === 32 || e.keyCode === 13) {
            dispatch(checkAvailableReferralByEmailAction(currentEmail.trim()));
        }
    };

    const handelSentMail = () => {
        if (emails.length > 0) {
            addEventGa({
                action: "refer_send_email",
                params: {},
            });
            addEventGa({
                action: "invite_success",
                params: {},
            });

            dispatch(sendReferralMailAction(emails));
            setMessage(
                <p className="text-ok">
                    {emails.length} people was successfully invited to
                    WorksheetZone!
                </p>
            );
            setEmails([]);
        } else {
            setMessage(<p className="text-err">Please enter email address</p>);
        }
    };

    return (
        <>
            <div className="invite-friend-container">
                {/* <div className="text-header-invite">
                    <p>
                        They can sign up for any plan and you get your bonus
                        downloads the minute they sign up on the website.
                    </p>
                </div> */}
                <div className="invite-content">
                    <div className="text-content-left">
                        <p>Invite your friends</p>
                    </div>
                    <div className="invite-content-container">
                        <div className="new" ref={ref}>
                            {emails.map((email, index) => (
                                <EmailComponent
                                    key={index}
                                    currentEmail={email}
                                    setEmails={setEmails}
                                    setDisableInput={setDisableInput}
                                    setMessage={setMessage}
                                />
                            ))}
                            <input
                                title="email friend"
                                placeholder="email@gmail.com"
                                value={currentEmail}
                                onChange={handleChange}
                                onKeyDown={(e) => handleKeyPress(e)}
                                disabled={disableInput}
                            ></input>
                        </div>

                        <div
                            className="button-send-refer"
                            onClick={handelSentMail}
                        >
                            <div className="img-icon">
                                <img
                                    src="/images/refer-friend/btn-send-refer.png"
                                    alt="refer-friend"
                                />
                            </div>
                            <p>Send</p>
                        </div>
                    </div>
                    {!!message ? (
                        <div className="message">{message}</div>
                    ) : (
                        <></>
                    )}
                </div>
                <div className="invite-content">
                    <div className="text-content-left">
                        <p>Share the referral link</p>
                    </div>
                    <div className="invite-content-container">
                        <div className="link-refer">
                            {/* {!isTablet ? (
                                <div className="text-url http">
                                    <p>{protocol}</p>
                                </div>
                            ) : (
                                <></>
                            )} */}

                            <div className="text-url value">
                                <p>
                                    {host +
                                        Routes.REFERRAL_LOGIN +
                                        "/" +
                                        userId}
                                </p>
                            </div>
                        </div>
                        <div
                            className="button-copy-refer"
                            onClick={handleClickCopy}
                        >
                            <div className="img-icon">
                                <img
                                    src="/images/refer-friend/btn-copy-refer.png"
                                    alt="refer-friend"
                                />
                            </div>
                            <p>Copy</p>
                        </div>
                    </div>
                    {activeCopy ? (
                        <div className="message">
                            <p className="text-ok">
                                Your link has been copied!
                            </p>
                        </div>
                    ) : (
                        <></>
                    )}
                </div>
            </div>
        </>
    );
};
const EmailComponent = ({
    currentEmail,
    setEmails,
    setMessage,
    setDisableInput,
}: {
    currentEmail;
    setEmails;
    setMessage;
    setDisableInput;
}) => {
    const dispatch = useDispatch();
    const handleRemoveEmail = (currentEmail) => {
        setEmails((prevEmails) =>
            prevEmails.filter((email) => email !== currentEmail)
        );
        const result = validateEmail(currentEmail.trim());
        if (!result) {
            setMessage("");
            setDisableInput(false);
        }
        dispatch(
            updateCurrentRecipientEmailStatusAction(
                Config.DEFAULT_REFERRAL_STATUS
            )
        );
    };

    return (
        <>
            <div className="email-friend-container">
                <div
                    className="email-content"
                    style={
                        validateEmail(currentEmail.trim())
                            ? {}
                            : { border: "1px solid #F87171" }
                    }
                >
                    <div className="img-user-email">
                        <p>{currentEmail.charAt(0)}</p>
                    </div>
                    <p>{currentEmail}</p>
                    <div
                        className="img-close-email"
                        onClick={() => handleRemoveEmail(currentEmail)}
                    >
                        {!validateEmail(currentEmail.trim()) ? (
                            <>
                                <img
                                    src="/images/refer-friend/icon-close-red.png"
                                    alt="refer-friend"
                                />
                            </>
                        ) : (
                            <>
                                <img
                                    src="/images/refer-friend/icon-close-green.png"
                                    alt="refer-friend"
                                />
                            </>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};
export default InviteContent;
