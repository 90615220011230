import { Fragment, useEffect, useState } from "react";
import { IResourceItemNew } from "../../../../../shared/models/resourceItemNew";
import { isImageSvg } from "../../../../../utils";
import { ImgSvgContent } from "../../../../../utils/create-ws";
import ResourceImageS3 from './ResourceImageS3';
import { isS3Link } from '../../../../../shared/utils';
import ConstantsTool from '../../../../../shared/utils/ConstantsTool';
import useImageLoader from './use-image-loaded';
import ResourceSeasonImages from './ResourceSeasonImages';

const listNameElement = [
    "circle",
    "ellipse",
    "path",
    "polygon",
    "polyline",
    "rect",
    "text",
    "textPath",
    "tref",
    "tspan",
];

export const ImageComponent = ({
    divWidth,
    divHeight,
    imageUrl,
    opacity,
    svgBlobURL,
    resourceItem,
    fieldName,
    onImageLoad
}: {
    divWidth: number,
    divHeight: number,
    imageUrl: string,
    opacity: number,
    svgBlobURL: string,
    resourceItem: IResourceItemNew,
    fieldName: string,
    onImageLoad: () => void,
}) => {
    const s3Link = isS3Link(imageUrl);

    return (
        <div
            className={"d-flex justify-content-center align-items-center"}
            style={{
                width: `${divWidth}px`,
                height: `${divHeight}px`,
                boxSizing: "border-box",
                opacity,
            }}
        >
            {imageUrl && (
                <>
                    {isImageSvg(resourceItem) ? (
                        svgBlobURL && (
                            <ImageNotS3Component
                                imageUrl={svgBlobURL}
                                onImageLoad={onImageLoad}
                                styles={{
                                    width: `${divWidth}px`,
                                    height: `${divHeight}px`,
                                }} />
                        )
                    ) : (
                        <>
                            {s3Link ? (
                                <ResourceImageS3
                                    fieldName={fieldName}
                                    s3URL={imageUrl}
                                    itemWidth={divWidth}
                                    itemHeight={divHeight}
                                    onImageLoad={onImageLoad}
                                />
                            ) : (
                                <ImageNotS3Component imageUrl={imageUrl} onImageLoad={onImageLoad} />
                            )}
                        </>
                    )}
                </>
            )}
        </div>
    )
}

export const ImageNotS3Component = ({
    imageUrl,
    onImageLoad,
    styles,
}: {
    imageUrl: string,
    onImageLoad: () => void,
    styles?: object,
}) => {
    const imageRef = useImageLoader(imageUrl, onImageLoad);

    return (
        <img
            className="content-element"
            ref={imageRef}
            src={imageUrl}
            // onLoad={onImageLoad}
            onError={onImageLoad}
            style={{ ...styles }}
        // alt={resourceItem.id}
        ></img>
    )
}

export const processImgSvg = (svg: string) => {
    let indexSvg = svg.indexOf("<svg");
    let indexSvgEnd = svg.indexOf("</svg>", indexSvg);
    let svgContent = svg.substring(indexSvg, indexSvgEnd + 7);
    const parser = new DOMParser();
    const xml = parser.parseFromString(svgContent, "text/xml");
    for (let i = 0; i < listNameElement.length; i++) {
        let listElement = xml.querySelectorAll(listNameElement[i]);
        if (listElement.length) {
            listElement.forEach((el) => {
                let check = false;
                if (!el.getAttribute("fill")) {
                    let parent = el.parentElement;
                    while (parent) {
                        if (parent) {
                            const parentFill = parent.getAttribute("fill");
                            if (parentFill) {
                                check = true;
                                break;
                            }
                        }
                        parent = parent.parentElement;
                    }
                    if (!check) {
                        el.setAttribute("fill", "#000000");
                    }
                }
            });
        }
    }
    svgContent = new XMLSerializer().serializeToString(xml);
    return svgContent;
};

const ResourceImageComponent = ({
    resourceItem,
    currentElement,
    onImageLoad,
}: {
    resourceItem: IResourceItemNew;
    currentElement: IResourceItemNew;
    onImageLoad: () => void;
}) => {
    const fieldName: string = currentElement?.fieldName ?? resourceItem?.fieldName;
    const isSeasonIcon: boolean = fieldName && fieldName.startsWith(ConstantsTool.SEASON_ICON_ZONE);
    const width: number = currentElement?.width ?? resourceItem.width;
    const height: number = currentElement?.height ?? resourceItem.height;
    const resourceImageOpacity = currentElement?.opacity ?? resourceItem.opacity;
    const resourceImageUrl = currentElement?.imageAttribute?.url ?? resourceItem?.imageAttribute?.url;
    const [svgData, setSvgData] = useState('');
    const [svgBlobURL, setSVGBlobURL] = useState('');

    useEffect(() => {
        if (
            isImageSvg(resourceItem) &&
            !resourceItem.imageAttribute.svgContent
        ) {
            if (ImgSvgContent.data[resourceItem.id]) {
                let svgContent = ImgSvgContent.data[resourceItem.id];
                setSvgData(svgContent);
            } else {
                fetch(resourceItem.imageAttribute.url)
                    .then((response) => {
                        if (!response.ok) {
                            return Promise.reject(new Error("Server Error"));
                        }
                        return response.text();
                    })
                    .then((svg) => {
                        let svgContent = processImgSvg(svg);
                        setSvgData(svgContent);
                    });
            }
        }
    }, []);
    useEffect(() => {
        if (svgData.length) {
            let newSvgData = svgData;

            if (resourceItem || currentElement) {
                // Change width and height of svg data
                const parser = new DOMParser();
                const doc = parser.parseFromString(newSvgData, "image/svg+xml");
                const svgElement = doc.documentElement;

                // Set attributes on the SVG element
                svgElement.setAttribute("width", `${width}`); // Set the width attribute to 200
                svgElement.setAttribute("height", `${height}`); // Set the height attribute to 200

                // Convert the modified SVG element back to a string
                newSvgData = new XMLSerializer().serializeToString(svgElement);
            }

            if (
                currentElement?.imageAttribute?.changeColor ||
                resourceItem?.imageAttribute?.changeColor
            ) {
                let changeColor = currentElement
                    ? currentElement?.imageAttribute?.changeColor
                    : resourceItem?.imageAttribute?.changeColor;
                for (let key in changeColor) {
                    const searchPattern = new RegExp(key, "g");
                    newSvgData = newSvgData.replace(
                        searchPattern,
                        changeColor[key]
                    );
                }
            }
            const blob = new Blob([newSvgData], { type: "image/svg+xml" });
            const blobUrl = URL.createObjectURL(blob);
            setSVGBlobURL(blobUrl);
        }
    }, [
        svgData,
        currentElement?.imageAttribute?.changeColor,
        resourceItem?.imageAttribute?.changeColor,
    ]);

    return (
        <Fragment>
            {isSeasonIcon && resourceImageUrl ? (
                <ResourceSeasonImages
                    width={width}
                    height={height}
                    seasonIconAIResponseValue={resourceImageUrl}
                    opacity={resourceImageOpacity}
                    svgBlobURL={svgBlobURL}
                    fieldName={fieldName}
                    resourceItem={resourceItem}
                    onImageLoad={onImageLoad}
                />
            ) : (
                <ImageComponent
                    divWidth={width}
                    divHeight={height}
                    imageUrl={resourceImageUrl}
                    opacity={resourceImageOpacity}
                    svgBlobURL={svgBlobURL}
                    fieldName={fieldName}
                    resourceItem={resourceItem}
                    onImageLoad={onImageLoad}
                />
            )}

        </Fragment>
    );
};
export default ResourceImageComponent;
