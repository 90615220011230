import React, { MouseEvent, useSyncExternalStore, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../../redux/hook";
import { changePageIndex, updateIsOnDrag, updateIsOnEditText, updateSelectedText } from "../../../../redux/reducers/createWorksheet";
import {
    changeActiveTab,
    setResourceItemEditing,
    toggleShowListActivities,
    updateCurentInfo,
} from "../../../../redux/reducers/leftData";
import MainCrossword from "../../../../resource-user/components/crossword";
import ClueCrossword from "../../../../resource-user/components/crossword/clue";
import WordBank from "../../../../resource-user/components/crossword/word-bank";
import WorksheetzoneIcon from "../../../../resource-user/components/icons/worksheetzone-icon";
import ContentWordSearchView from "../../../../resource-user/components/word-search/right-panel/content-word-search";
import ConstantsResource from "../../../../resource-user/utils/ConstantsResource";
import { IResourceItemNew } from "../../../../shared/models/resourceItemNew";
import ConstantsMath from "../../../../shared/utils/ConstantsResourceMath";
import ConstantsTool from "../../../../shared/utils/ConstantsTool";
import Config from "../../../../shared/utils/config";
import groupElementStore from "../../../../syncExternalStore/groupElementStore";
import {
    canDragElement,
    canOverflowElement,
    getListUrlFillTheBlank,
    listOpenWsInfo,
    resourceCanDragGroup,
    resourceCanEditTextStyle,
    wordListBlockResource,
} from "../../../../utils";
import { ConstantsApp } from "../../../../utils/Constants";
import TextFillTheBlank from "./FillTheBlank/TextFillTheBlank";
import WordBankComponent from "./FillTheBlank/WordBank";
import GroupResourceItem from "./GroupElement";
import ResourceLineIntercctive from "./Line";
import ResourceNormalLineView from "./Line/NormalLine";
import EditableMathFormula from "./MathFormula";
import ResourceHandWriting from "./ResourceHandwriting";
import ResourceImageComponent from "./ResourceImage";
import ResourceMath from "./ResourceMath";
import ResourceNameTracing from "./ResourceNameTracing";
import ResourceRectComponent from "./ResourceRect";
import ResourceTable from "./ResourceTable";
import StudentInfoResource from "./StudentInfo";
import WordDirection from "./WordDirection";
import WordListComponent from "./WordList";
import TextMultipleChoice from "./MultipleChoices/TextMultipleChoice";
import CanvasText from "./canvas-text";
import "./index.scss";
import ShapeView from "./shape";
import ResourceNormalShapeView from "./shape/normal-shape";
import WordBankWordScramble from "./ResourceScramble/WordBank";
import ResourceSentenceScramble from "./ResourceScramble/SentenceScramble";
import ResourceWordScramble from "./ResourceScramble/WordScramble";
import ResourceConvertedIconShapeView from './shape/converted-icon-shape';
import ResourceContainer from './ResourceContainer';
export interface PopoverOriginProps {
    vertical: "top" | "bottom" | "";
    horizontal: "left" | "right" | "";
}
const checkEqual = (
    prevProps: {
        resourceId: string;
        setCurrentElement: (value: IResourceItemNew) => void;
        currentElement: IResourceItemNew;
        canClick: boolean;
        pageIndex: number;
        // setHover: (value: IResourceItemNew | null) => void;
        // hoverElement: IResourceItemNew;
        setSpaceToTopLeft: (value: any) => void;
        originPosition?: { x: number; y: number };
    },
    nextProps: {
        resourceId: string;
        setCurrentElement: (value: IResourceItemNew) => void;
        currentElement: IResourceItemNew;
        canClick: boolean;
        pageIndex: number;
        // setHover: (value: IResourceItemNew | null) => void;
        // hoverElement: IResourceItemNew;
        setSpaceToTopLeft: (value: any) => void;
        originPosition?: { x: number; y: number };
    }
) => {
    return (
        prevProps.resourceId === nextProps.resourceId &&
        prevProps.currentElement === nextProps.currentElement &&
        prevProps.canClick === nextProps.canClick &&
        prevProps.pageIndex === nextProps.pageIndex &&
        prevProps.originPosition === nextProps.originPosition
        // prevProps.hoverElement === nextProps.hoverElement
    );
};

const ElementWorksheet = React.forwardRef<
    HTMLDivElement,
    {
        resourceId: string;
        setCurrentElement: (value: IResourceItemNew) => void;
        currentElement: IResourceItemNew;
        canClick: boolean;
        pageIndex: number;
        setSpaceToTopLeft: (value: any) => void;
        originPosition?: { x: number; y: number };
        setActiveDarg: any;
        // setHover: (value: IResourceItemNew | null) => void;
        // hoverElement: IResourceItemNew;
        handleImageLoad?: () => void;
    }
>(
    (
        {
            resourceId,
            setCurrentElement,
            currentElement,
            canClick,
            pageIndex,
            setSpaceToTopLeft,
            originPosition = { x: 0, y: 0 },
            setActiveDarg,
            handleImageLoad,
            // setHover,
            // hoverElement,
        },
        ref
    ) => {
        const resourceItems: IResourceItemNew[] = useAppSelector(
            (state) =>
                state.createWorksheetState.pagesWorksheet[pageIndex]
                    .resourceItems
        );
        const resourceItem: IResourceItemNew = resourceItems.find(
            (el) => el.id === resourceId
        );
        const currentPageIndex = useAppSelector(
            (state) => state.createWorksheetState.pageIndex
        );
        const ratio: number = useAppSelector(
            (state) => state.createWorksheetState.ratio
        );
        const isOnEditText = useAppSelector(
            (state) => state.createWorksheetState.isOnEditText
        );
        const activities = useAppSelector(
            (state) => state.createWorksheetState.activities
        );
        const dispatch = useAppDispatch();
        const groupElementState = useSyncExternalStore(
            groupElementStore.subscribe,
            groupElementStore.read
        );

        const pageType = useAppSelector((state) => state.createWorksheetState.pageType);

        const [beAbleClickItem, setBeAbleClickItem] = useState<boolean>(canClick);

        const genElement = (resourceItem: IResourceItemNew) => {
            switch (resourceItem.type) {
                case ConstantsTool.RESOURCE_IMAGE:
                case ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE:
                case ConstantsTool.TYPE_RESOURCE_BORDER_FRAMES:
                    return (
                        <ResourceImageComponent
                            currentElement={currentElement}
                            resourceItem={resourceItem}
                            onImageLoad={handleImageLoad}
                        ></ResourceImageComponent>
                    );
                case ConstantsTool.RESOURCE_TEXT:
                    return (
                        <CanvasText
                            item={resourceItem}
                            pageIndex={pageIndex}
                            ratio={1}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_MATH_FORMULA:
                    return (
                        <EditableMathFormula
                            item={resourceItem}
                            pageIndex={pageIndex}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    );
                case ConstantsTool.RESOURCE_TEXT_MODULE:
                    return (
                        <CanvasText
                            item={resourceItem}
                            pageIndex={pageIndex}
                            ratio={1}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_MODULE:
                    return (
                        <div className="content-element">
                            <ContentWordSearchView
                                resourceItem={resourceItem}
                                ratioPage={ratio}
                                isToolV2={true}
                            ></ContentWordSearchView>
                        </div>
                    );
                case ConstantsTool.RESOURCE_BACKGROUND:
                    return <div className="content-element" style={resourceItem.backgroundColor ? { backgroundColor: resourceItem.backgroundColor } : {}}></div>;
                case ConstantsTool.TYPE_RESOURCE_BORDER:
                    return (
                        <div
                            className="content-element"
                            style={{
                                border: "3px solid #0c9eff",
                                boxSizing: "border-box",
                            }}
                        ></div>
                    );
                case ConstantsTool.TYPE_TEXT_FILL_THE_BLANK:
                    return (
                        <TextFillTheBlank
                            sentenceData={resourceItem}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        ></TextFillTheBlank>
                    );
                case ConstantsTool.TYPE_WORD_BANK:
                    return (
                        <WordBankComponent
                            resourceItem={resourceItem}
                            setCurrentElement={setCurrentElement}
                            currentElement={currentElement}
                        ></WordBankComponent>
                    );
                case ConstantsTool.TYPE_WORD_DIRECTION:
                    return (
                        <WordDirection
                            resourceItem={resourceItem}
                        ></WordDirection>
                    );
                case ConstantsTool.TYPE_WORD_LIST:
                    return (
                        <WordListComponent
                            resourceItem={resourceItem}
                        ></WordListComponent>
                    );
                case ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES:
                    return (
                        <TextMultipleChoice
                            item={resourceItem}
                        ></TextMultipleChoice>
                    );
                case ConstantsTool.TYPE_LOGO:
                    return (
                        <div
                            style={{
                                width: resourceItem.width,
                                height: resourceItem.height,
                            }}
                        >
                            <WorksheetzoneIcon
                                width={`${resourceItem.width}px`}
                                height={`${resourceItem.height}px`}
                            ></WorksheetzoneIcon>
                        </div>
                    );
                case ConstantsTool.TYPE_STUDENT_INFO:
                    return (
                        <StudentInfoResource
                            resourceItem={resourceItem}
                        ></StudentInfoResource>
                    );
                case ConstantsTool.TYPE_RESOURCE_LINE:
                    return (
                        <ResourceRectComponent
                            resourceItem={resourceItem}
                        ></ResourceRectComponent>
                    );
                case ConstantsTool.TYPE_RESOURCE_HANDWRITING:
                    return (
                        <ResourceHandWriting
                            resourceItem={resourceItem}
                            ratio={ratio}
                        ></ResourceHandWriting>
                    );
                case ConstantsTool.TYPE_RESOURCE_NAME_TRACING:
                    return (
                        <ResourceNameTracing
                            resourceItem={resourceItem}
                            ratio={ratio}
                        ></ResourceNameTracing>
                    );
                case ConstantsTool.TYPE_RESOURCE_SHAPE:
                    return (
                        <ShapeView
                            resourceItem={resourceItem}
                            currentItem={currentElement}
                            pageIndex={pageIndex}
                            setCurrentElement={setCurrentElement}
                        ></ShapeView>
                    );
                case ConstantsTool.TYPE_RESOURCE_CROSSWORD_GRID:
                    return <MainCrossword resourceItem={resourceItem} />;
                case ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_LIST:
                    return <ClueCrossword resourceItem={resourceItem} />;
                case ConstantsTool.TYPE_RESOURCE_CROSSWORD_WORD_BANK:
                    return <WordBank resourceItem={resourceItem} />;
                case ConstantsTool.TYPE_RESOURCE_LINE_INTERACTIVE:
                    return (
                        <ResourceLineIntercctive
                            resourceItem={resourceItem}
                            currentItem={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE:
                case ConstantsTool.TYPE_RESOURCE_ZONE:
                case ConstantsTool.TYPE_RESOURCE_ZONE_IMAGE:
                    return (
                        <ResourceNormalShapeView
                            resourceItem={resourceItem}
                            currentItem={currentElement}
                            setCurrentElement={setCurrentElement}
                            pageIndex={0}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_CONVERTED_ICON_SHAPE:
                    return (
                        <ResourceConvertedIconShapeView
                            resourceItem={resourceItem}
                            currentItem={currentElement}
                            setCurrentElement={setCurrentElement}
                            pageIndex={0}
                        />
                    )
                case ConstantsTool.TYPE_RESOURCE_NORMAL_LINE:
                    return (
                        <ResourceNormalLineView
                            resourceItem={resourceItem}
                            currentItem={currentElement}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_GROUP_ELEMENT:
                    return (
                        <GroupResourceItem
                            resourceItem={resourceItem}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                            setSpaceToTopLeft={setSpaceToTopLeft}
                            setActiveDarg={setActiveDarg}
                            handleImageLoad={handleImageLoad}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_MATH:
                    return (
                        <ResourceMath
                            resourceItem={resourceItem}
                            currentElement={currentElement}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_TABLE:
                    return (
                        <ResourceTable
                            resourceItem={resourceItem}
                            currentElement={currentElement}
                            setCurrentElement={setCurrentElement}
                        />
                    );
                case ConstantsTool.TYPE_RESOURCE_WORD_SCRAMBLE:
                    return <ResourceWordScramble resourceItem={resourceItem} />;
                case ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE:
                    return (
                        <ResourceSentenceScramble resourceItem={resourceItem} />
                    );
                case ConstantsTool.TYPE_RESOURCE_WORD_BANK_SCRAMBLE:
                    return <WordBankWordScramble resourceItem={resourceItem} />;
                case ConstantsTool.TYPE_RESOURCE_ZONE_CONTAINER:
                    return <ResourceContainer
                                resourceItem={resourceItem}
                                currentElement={currentElement}
                            />
                default:
                    return <div className="content-element shape">ok</div>;
            }
        };

        const saveTopLeftSpace = (
            event: any,
            resourceItem: IResourceItemNew
        ) => {
            let pages = document.getElementsByClassName("not-click");
            let position = pages[0].getBoundingClientRect();
            setSpaceToTopLeft({
                x: event.clientX - resourceItem.x * ratio - position.x,
                y: event.clientY - 24 - resourceItem.y * ratio,
                id: resourceItem.id,
            });
        };

        const handleClickElement = (event: MouseEvent) => {
            let resourceItemSelected = resourceItem;
            event.stopPropagation();
            if (event.shiftKey) {
                event.preventDefault();
            }
            if (currentElement?.id !== resourceItemSelected.id) {
                if (
                    !currentElement &&
                    resourceItemSelected.type !==
                    ConstantsTool.TYPE_RESOURCE_BORDER
                ) {
                    if (resourceItemSelected.isGroup) {
                        let parentElement = resourceItems.find((e) =>
                            e.resourceIds.includes(resourceItemSelected?.id)
                        );
                        if (
                            parentElement &&
                            parentElement?.id !==
                            groupElementState?.resourceGroupTmp?.id
                        ) {
                            resourceItemSelected = parentElement;
                        }
                    }
                    if (resourceItemSelected.textAttribute) {
                        dispatch(updateSelectedText());
                    }
                    setCurrentElement({ ...resourceItemSelected });
                    groupElementStore.updateSelectedId(
                        resourceItemSelected.id
                    );
                    // setHover(null);
                    if (
                        resourceItemSelected.type ===
                        ConstantsTool.TYPE_TEXT_FILL_THE_BLANK ||
                        resourceItemSelected.type ===
                        ConstantsTool.TYPE_RESOURCE_HANDWRITING ||
                        resourceItemSelected.type ===
                        ConstantsTool.TYPE_RESOURCE_NAME_TRACING ||
                        resourceItemSelected.type ===
                        ConstantsTool.TYPE_RESOURCE_SENTENCE_SCRAMBLE ||
                        resourceItemSelected.type ===
                        ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES ||
                        resourceItemSelected.type ===
                        ConstantsTool.TYPE_TEXT_MULTIPLE_CHOICES
                    ) {
                        dispatch(
                            setResourceItemEditing(resourceItemSelected.id)
                        );
                    } else {
                        dispatch(setResourceItemEditing(""));
                    }
                }
                if (
                    resourceItemSelected.activityId ||
                    listOpenWsInfo(resourceItemSelected)
                ) {
                    let activity = activities.find(
                        (el) => el.id === resourceItemSelected.activityId
                    );
                    if (
                        activity?.type !==
                        ConstantsResource.TAB_ACTIVE.FILL_IN_BLANK &&
                        !getListUrlFillTheBlank()
                    ) {
                        // dispatch(changeTabActiveActivities(activity.type));
                    }

                    dispatch(changeActiveTab(Config.RESOURCE_MODULES));
                    dispatch(toggleShowListActivities(false));
                }
            }
            // if (resourceItem.type === ConstantsTool.RESOURCE_BACKGROUND) {
            //     setCurrentElement(null);
            // }
            // if (
            //     resourceItem.type ===
            //     ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
            // ) {
            //     dispatch(changeActiveTab(Config.RESOURCE_BACKGROUND));
            // }
            if (
                resourceItemSelected.type === ConstantsTool.RESOURCE_BACKGROUND
            ) {
                dispatch(updateCurentInfo(null));
            }
            // if (
            //     resourceItem.type ===
            //     ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE
            // ) {
            //     dispatch(changeActiveTab(Config.RESOURCE_BACKGROUND));
            // }
            if (
                resourceItemSelected.type ===
                ConstantsTool.TYPE_RESOURCE_SHAPE &&
                !resourceItemSelected.idType?.includes(
                    ConstantsMath.PREFIX_MATH_ITEM
                )
            ) {
                dispatch(changeActiveTab(Config.RESOURCE_SHAPES));
            }
            if (canDragElement(resourceItemSelected) && !isOnEditText) {
                dispatch(updateIsOnDrag(true));
                setActiveDarg(true);
                saveTopLeftSpace(event, resourceItemSelected);
            }
            if (
                (resourceItemSelected.type === ConstantsTool.RESOURCE_TEXT ||
                    (resourceItemSelected.type ===
                        ConstantsTool.TYPE_TEXT_FILL_THE_BLANK &&
                        !wordListBlockResource(resourceItemSelected))) &&
                // ||resourceItem.idType?.includes("ws_title") ||
                // resourceItem.idType?.includes("ws_description")
                (event.detail === 2 || currentElement?.id === resourceItemSelected.id) ||
                (resourceItemSelected.type === ConstantsTool.TYPE_RESOURCE_MATH_FORMULA)
            ) {
                dispatch(updateIsOnEditText(true));
            }
            if (!resourceCanEditTextStyle(resourceItemSelected)) {
                dispatch(updateIsOnEditText(false));
            }
            if (currentPageIndex !== pageIndex) {
                dispatch(changePageIndex(pageIndex));
            }
            if (resourceCanDragGroup(resourceItem)) {
                const parentElement = document.getElementById(
                    "page-worksheet-container-x5-" + pageIndex
                );
                if (parentElement) {
                    let rect = parentElement.getBoundingClientRect();
                    groupElementStore.updateStartPoint(
                        (event.clientX - rect.x) / ratio,
                        (event.clientY - rect.y) / ratio
                    );
                    groupElementStore.updateOnDragGroup(true);
                }
            }
            if (
                resourceItemSelected.resourceIds.length > 0 &&
                !resourceItemSelected.id?.includes("tmp-") &&
                !event.shiftKey
            ) {
                groupElementStore.addResourceItems(
                    resourceItems.filter((element) =>
                        resourceItemSelected.resourceIds.includes(element.id)
                    )
                );
                groupElementStore.updateResourceGroup(resourceItemSelected);
            }
        };

        let tmpResourceItem = groupElementState.resourceItemsCurrent.find(
            (e) => e.id === resourceItem.id
        );
        let currentElementValue =
            tmpResourceItem ?? currentElement ?? resourceItem;
        let resourceItemValue = tmpResourceItem ?? resourceItem;
        let idHorver =
            currentElement === null &&
                !resourceItem.resourceIds.includes(groupElementState.selectedId)
                ? "new-hover-element"
                : "";

        return (
            <div
                id={ref ? ConstantsApp.ID_CURRENT_ELEMENT : null}
                style={{
                    touchAction: "pan-x pan-y pinch-zoom",
                    width: `${currentElementValue.width}px`,
                    height: `${currentElementValue.height}px`,
                    cursor: "auto",
                    position: "absolute",
                    transform: `translate(${currentElementValue.x - originPosition.x
                        }px, ${currentElementValue.y - originPosition.y
                        }px) rotate(${currentElementValue.rotate}deg)`,
                }}
                ref={ref}
                resource-item-id={resourceItem?.id}
                className={
                    (!beAbleClickItem ? "not-click" : "can-click") +
                    ((resourceItem?.customZoneID !== null && pageType === 'template') ? ' custom-zone-test-text' : '') +
                    (resourceItem.type !== ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE ? ' resource-item-element' : '')
                }
            >
                <div
                    id={idHorver}
                    style={{
                        width: `${currentElementValue.width}px`,
                        height: `${currentElementValue.height}px`,
                        overflow: canOverflowElement(resourceItem)
                            ? "none"
                            : "hidden",
                        // cursor: currentElement ? "move" : "auto",
                        position: "absolute",
                        top: "0px",
                        left: "0px",
                        transform: "scale(1)",
                        transformOrigin: "0px 0px",
                    }}
                    className={!beAbleClickItem ? "not-click" : "can-click"}
                >
                    <div
                        style={{
                            width: `${currentElementValue.width}px`,
                            height: `${currentElementValue.height}px`,
                            position: "relative",
                            marginLeft: "0px",
                            marginTop: "0px",
                        }}
                        data-fieldName={resourceItem.fieldName}
                        data-fieldIndex={resourceItem.fieldIndex}
                    >
                        <div
                            style={{
                                width: `${currentElementValue.width}px`,
                                height: `${currentElementValue.height}px`,
                                position: "relative",
                            }}
                            onDragOver={(event) => {
                                event.preventDefault();
                            }}
                            className={!beAbleClickItem ? "not-click" : "can-click"}
                            onMouseDown={(e: any) => {
                                handleClickElement(e);
                            }}
                        // onMouseEnter={() => handleMouseEnter()}
                        // onMouseLeave={() => {
                        //     if (
                        //         isResourceBackground(resourceItem.type) ||
                        //         isResourceBorder(resourceItem.type)
                        //     ) {
                        //         setHover(null);
                        //     }
                        // }}
                        >
                            {genElement(resourceItemValue)}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
);

export default React.memo(ElementWorksheet, checkEqual);
