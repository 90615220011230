import { Fragment, useEffect, useState } from 'react';
import { useAppSelector } from '../../../../redux/hook';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { updateDataAPI } from '../../../../utils/query';
import { getDataFromAccessPath, formatZoneName } from '../../../../utils';
import { Spinner } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { setContent, setCurrentElementFocus, updateResourceItem } from '../../../../redux/reducers/createWorksheet';
import FieldNameControl from './field-name-control';
import ListSuggestionsFieldName from './list-suggestions-field-name';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';
import { IResourceItemNew } from '../../../../shared/models/resourceItemNew';

const FieldModal = ({type, currentElement, fieldName = '', fieldIndex, fieldDescription, fieldAnswerUserTitle, parentField, parentFieldIndex, staticValue, linkedZoneFieldName, linkedTemplate, hideModal, save} : {
    type: string,
    currentElement: IResourceItemNew,
    fieldName: string,
    fieldIndex: any,
    fieldDescription: string,
    fieldAnswerUserTitle: string,
    parentField: string,
    parentFieldIndex: any,
    staticValue: string,
    linkedZoneFieldName?: string,
    linkedTemplate?: number,
    hideModal: any
    save: any,
}) => {
    const dispatch = useDispatch();

    const resourceItems = useAppSelector(state => state.createWorksheetState.pagesWorksheet[0]?.resourceItems)
    const {pageType, ideaTemplate, content} = useAppSelector(state => state.createWorksheetState)

    const [newFieldName, setNewFieldName] = useState(fieldName ?? '');
    const [currentFieldDescription, setCurrentFieldDescription] = useState(fieldDescription ?? '');
    const [currentFieldAnswerUserTitle, setCurrentFieldAnswerUserTitle] = useState(fieldAnswerUserTitle ?? '');
    const [selectedParentField, setSelectedParentField] = useState(parentField ?? '');
    const [currentFieldIndex, setCurrentFieldIndex] = useState(fieldIndex ?? null);
    const [currentParentFieldIndex, setCurrentParentFieldIndex] = useState(parentFieldIndex ?? null);
    const [currentStaticValue, setCurrentStaticValue] = useState(staticValue ?? '');
    const [textContent, setTextContent] = useState('');
    const [accessPath, setAccessPath] = useState(null);
    const [currentLinkedZoneFieldName, setCurrentLinkedZoneFieldName] = useState(linkedZoneFieldName ?? '');
    const [currentLinkedTemplate, setCurrentLinkedTemplate] = useState(linkedTemplate ?? null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (pageType === 'page') {
            getFieldText();
        }

        dispatch(setCurrentElementFocus(false));

        if (currentElement.type === ConstantsTool.RESOURCE_TEXT) {
            setTextContent(currentElement.textAttribute.content);
        }
    }, []);

    const getFieldText = async() => {
        setLoading(true);
        const zones = resourceItems.filter(item => item.fieldName);
        try {
            const res = await updateDataAPI('POST', 'auto-content/generate-access-path-from-fields-list/', {
                zones: zones.map(zone => ({
                    id: zone.id,
                    fieldName: zone.fieldName,
                    fieldIndex: zone.fieldIndex,
                    parentField: zone.parentField,
                    parentFieldIndex: zone.parentFieldIndex,
                })),
                answers: ideaTemplate.templateData.answerFields,
                templateID: ideaTemplate.templateData.id,
            });
            const accessPaths = res.data['accessPaths'];
            let textContent: string;
            if (accessPaths) {
                for (let i = 0; i < zones.length; i++) {
                    if (zones[i].fieldName === fieldName && zones[i].fieldIndex === fieldIndex
                            && zones[i].parentField === parentField && zones[i].parentFieldIndex === parentFieldIndex) {
                        textContent = getDataFromAccessPath(content, accessPaths[zones[i].id]);
                        setAccessPath(accessPaths[zones[i].id]);
                    }
                }
            }
            if (textContent) {
                setTextContent(textContent);
            }
        } catch (err) {
            console.error('An error occured when getting field text: ', err.message);
        }
        setLoading(false);
    }

    const saveFieldName = () => {
        let fieldName = null;
        if (newFieldName && newFieldName.length > 0) {
            fieldName = newFieldName.trim();
        }

        if (fieldName) {
            const formatedFieldName = formatZoneName(fieldName);
            if (fieldName === ConstantsTool.CONTAINER_ZONE && !currentLinkedZoneFieldName) {
                window.alert('Please field linked zone field name before saving!');
                return;
            }
            save(formatedFieldName, parseInt(currentFieldIndex), currentFieldDescription, selectedParentField, parseInt(currentParentFieldIndex), currentStaticValue, currentFieldAnswerUserTitle, currentLinkedZoneFieldName, currentLinkedTemplate);
        }
    }

    const saveFieldText = async() => {
        if (accessPath) {
            setLoading(true);
            try {
                const res = await updateDataAPI('POST', 'auto-content/update-idea-template-content/', {
                    ideaTemplateID: ideaTemplate.id,
                    changes: [{
                        accessPath,
                        value: textContent
                    }]
                });
                dispatch(
                    setContent({
                        data: res.data,
                    })
                );
                hideModal();
            } catch (err) {
                window.alert('save field value error');
            }
            setLoading(false);
        } else {
            window.alert('can not save field value');
        }
    }

    const saveTextItemContent = async() => {
        dispatch(
            updateResourceItem({
                currentElementId: currentElement.id,
                pageIndex: currentElement.pageIndex,
                attrValues: [
                    {
                        attr: 'textAttribute',
                        value: {
                            ...currentElement.textAttribute,
                            content: textContent,
                        },
                    },
                ],
            })
        );
    }

    if (loading) {
        return <Spinner animation="border" />;
    }

    return (
        <Modal show={true} onHide={hideModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) ? 'Set Field' : 'Field ' + fieldName}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form>
                    {(currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && (Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) || pageType === ConstantsTool.PAGE_TYPES['dataSetDesignRow'])) && (
                        <Row>
                            <FieldNameControl
                                newFieldName={newFieldName}
                                setNewFieldName={setNewFieldName}
                            />
                            {type === 'zone' && (
                                <Col>
                                    <ListSuggestionsFieldName handleChangeSuggestionFieldName={(suggestionFieldName) => setNewFieldName(suggestionFieldName)} />
                                </Col>
                            )}
                            {pageType === ConstantsTool.TEMPLATE_TYPES['template'] && newFieldName === ConstantsTool.CONTAINER_ZONE && type === 'zone' && (
                                <Fragment>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label style={{ whiteSpace: 'nowrap' }}>Linked Zone: </Form.Label>
                                            <Form.Control value={currentLinkedZoneFieldName} onChange={e => setCurrentLinkedZoneFieldName(e.target.value)} />
                                        </Form.Group>
                                    </Col>
                                    <Col>
                                        <Form.Group className="mb-3">
                                            <Form.Label style={{ whiteSpace: 'nowrap' }}>Linked Template: </Form.Label>
                                            <Form.Control type='number' value={currentLinkedTemplate} onChange={e => setCurrentLinkedTemplate(parseInt(e.target.value))} />
                                        </Form.Group>
                                    </Col>
                                </Fragment>
                            )}
                            {(Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) || pageType === ConstantsTool.PAGE_TYPES['dataSetDesignRow']) && type === 'zone' && (
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Field Index</Form.Label>
                                        <Form.Control value={currentFieldIndex} onChange={e => setCurrentFieldIndex(e.target.value)} type="number" min={1} />
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && type === 'answer' && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>User's Title</Form.Label>
                                    <Form.Control value={currentFieldAnswerUserTitle} onChange={e => setCurrentFieldAnswerUserTitle(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Field Description</Form.Label>
                                    <Form.Control value={currentFieldDescription} onChange={e => setCurrentFieldDescription(e.target.value)}
                                        as="textarea" rows={3} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                        <Row>
                            <Col>
                                {resourceItems && (
                                    <Form.Group className="mb-3">
                                        <Form.Label>Child of</Form.Label>
                                        <Form.Select
                                            onChange={e => setSelectedParentField(e.target.value)}
                                            value={selectedParentField}
                                        >
                                            <option value={null}></option>
                                            {resourceItems.filter(item => item.fieldName).reduce((uniqueFields, item) => {
                                                if (uniqueFields.indexOf(item.fieldName) === -1) {
                                                    uniqueFields.push(item.fieldName);
                                                }
                                                return uniqueFields;
                                            }, []).map((field, index) => (
                                                <option key={index} value={field}>{field}</option>
                                            ))}
                                        </Form.Select>
                                    </Form.Group>
                                )}
                            </Col>
                            {type === 'zone' && (
                                <Col>
                                    <Form.Group className="mb-3">
                                        <Form.Label>Field Index</Form.Label>
                                        <Form.Control value={currentParentFieldIndex} onChange={e => setCurrentParentFieldIndex(e.target.value)} type="number" min={1} />
                                    </Form.Group>
                                </Col>
                            )}
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && pageType === ConstantsTool.PAGE_TYPES['page'] && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Field Text</Form.Label>
                                    <Form.Control value={textContent} onChange={e => setTextContent(e.target.value)} as="textarea" rows={10} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && pageType === ConstantsTool.TEMPLATE_TYPES['template'] && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Static Value</Form.Label>
                                    <Form.Control value={currentStaticValue} onChange={e => setCurrentStaticValue(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.RESOURCE_TEXT && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Field Text</Form.Label>
                                    <Form.Control value={textContent} onChange={e => setTextContent(e.target.value)} as="textarea" rows={10} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                    {currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE && (
                        <Row>
                            <Col>
                                <Form.Group className="mb-3">
                                    <Form.Label>Shape Name</Form.Label>
                                    <Form.Control value={newFieldName} onChange={e => setNewFieldName(e.target.value)} />
                                </Form.Group>
                            </Col>
                        </Row>
                    )}
                </Form>
            </Modal.Body>
            <Modal.Footer>
                {currentElement.type === ConstantsTool.TYPE_RESOURCE_ZONE && (
                    (Object.values(ConstantsTool.TEMPLATE_TYPES).includes(pageType) || pageType === ConstantsTool.PAGE_TYPES['dataSetDesignRow']) ? (
                        <Button onClick={saveFieldName}>Save</Button>
                    ) : (
                        <Button onClick={saveFieldText}>Save</Button>
                    )
                )}

                {currentElement.type === ConstantsTool.RESOURCE_TEXT && (
                    <Button onClick={saveTextItemContent}>Save</Button>
                )}

                {currentElement.type === ConstantsTool.TYPE_RESOURCE_NORMAL_SHAPE && (
                    <Button onClick={saveFieldName}>Save</Button>
                )}
            </Modal.Footer>

        </Modal>
    )
}

export default FieldModal;