import { ICollection } from "../../shared/models/collection";
import { IUserInfo } from "../../shared/models/userInfo";
import { IWorksheet, Worksheet } from "../../shared/models/worksheet";
import Config from "../../shared/utils/config";
import * as gtm from "../lib/gtm";
import { removeDrawLineGameJoin } from "../redux/action/game.action";
import ConstantsResource from "./ConstantsResource";
import Constants from "./constants";
import Routes from "./routes";
// import * as htmlToImage from "html-to-image";
import { IActivity } from "../../shared/models/WorkSheetCreator";
import { ICrossword, Word } from "../../shared/models/crossword";
import {
    IResourceItemNew,
    ITextAttribute,
    ResourceItemNew,
} from "../../shared/models/resourceItemNew";
import { ISubText } from "../../shared/models/textStyle";
import { stringifyAndParse } from "../../shared/utils";
import ConstantsTool from "../../shared/utils/ConstantsTool";
import { SizeA4 } from "../../shared/utils/sizeA4";
import AllLanguage from "../data/all-language.json";
import categories from "../data/category-data.json";
import CcssJson from "../data/ccs-standards-data.json";
import TopicJson from "../data/topic-data.json";
import CreateWSConfig from "./create-ws-config";

export const groupWsFc = ({ worksheets }) => {
    if (worksheets?.length > 0) {
        return stringifyAndParse(worksheets).reduce((groupWs, ws) => {
            let workbookId = ws?.workbookId;
            if (workbookId) {
                groupWs[workbookId] = groupWs[workbookId] ?? [];
                groupWs[workbookId].push(ws);
            } else {
                let worksheetId = ws?.id;
                groupWs[worksheetId] = groupWs[worksheetId] ?? [];
                groupWs[worksheetId].push(ws);
            }

            return groupWs;
        }, {});
    }
    return {};
};
export const getAllCategory = () => {
    return categories;
};

export const getCategoriesWithParentIds = ({
    categories,
    parentIds,
}: {
    categories: any[];
    parentIds?: any[];
}) => {
    if (!parentIds || parentIds?.length == 0) {
        parentIds = [];
    }
    if (categories?.length) {
        categories = categories?.map((c) => {
            let newParentIds: any[] = JSON.parse(JSON.stringify(parentIds));
            c.parentIds = newParentIds;
            newParentIds = [...newParentIds, c.id];
            let subCategories = c?.children;
            if (subCategories?.length) {
                const subChild = getCategoriesWithParentIds({
                    categories: subCategories,
                    parentIds: newParentIds,
                });
                c.children = subChild;
            }
            return c;
        });
    }

    return categories;
};

export const isUniqueCollection = (collection: ICollection) => {
    if (collection.id) {
        if (
            collection.id ===
                Config.COLLECTION_DOWNLOAD.DOWNLOAD_COLLECTIONid ||
            collection.id === Config.COLLECTION_PRACTICE.PRACTICE_COLLECTIONid
        ) {
            return true;
        }
    }
    return false;
};

export const linkCollection = (collection: ICollection) => {
    if (!collection) {
        return;
    }
    return (
        "/collection/" + replaceForLink(collection.name) + "-" + collection.id
    );
};

export const isInteractiveWorksheet = (worksheet) => {
    if (worksheet?.id) {
        const isInteractive =
            worksheet?.answerLength > 0 ||
            worksheet?.game?.answers?.length > 0 ||
            worksheet?.type == Config.WORKSHEET_TYPE_INTERACTIVE;
        return isInteractive;
    }
    return null;
};

export const isProdWeb = () => {
    const PRODUCT_KEY = "production";
    return (
        process.env.NEXT_PUBLIC_PROD_MODE === PRODUCT_KEY ||
        process.env.REACT_APP_PROD_MODE === PRODUCT_KEY
    );
};

export const urlWorksheet = (worksheet) => {
    if (worksheet?.status == "noIndex") {
        return (
            "/" +
            replaceForLink(worksheet?.title) +
            "-" +
            Routes.WS_NO_INDEX +
            "-" +
            worksheet?.id
        );
    } else {
        return (
            "/" +
            replaceForLink(worksheet?.title) +
            "-" +
            Routes.PRINTABLE_INTERACTIVE +
            "-" +
            worksheet?.id
        );
    }
};

export const resetGa4Function = (
    key: string,
    value: string | number,
    isSendEvent = true
) => {
    let ga4Config = JSON.parse(
        localStorage.getItem(ConstantsResource.LOCAL_STORAGE_GA_CONFIG)
    );
    if (!ga4Config) {
        ga4Config = {};
        ga4Config[key] = value;
        localStorage.setItem(
            ConstantsResource.LOCAL_STORAGE_GA_CONFIG,
            JSON.stringify(ga4Config)
        );
        if (isSendEvent) {
            gtm.event("reset_ga4_configuration", ga4Config);
        }
    } else {
        if (!ga4Config[key]) {
            let newConfig = {
                ...ga4Config,
            };
            newConfig[key] = value;
            localStorage.setItem(
                ConstantsResource.LOCAL_STORAGE_GA_CONFIG,
                JSON.stringify(newConfig)
            );
            if (isSendEvent) {
                gtm.event("reset_ga4_configuration", newConfig);
            }
        }
    }
};

export const getScreenSizeFromURL = (
    str: string,
    screenSizes: any[],
    index?: number
) => {
    const regex = /w[0-9]*-h[0-9]*/gm;

    let m;
    let width,
        height = 0;
    try {
        while ((m = regex.exec(str)) !== null) {
            // This is necessary to avoid infinite loops with zero-width matches
            if (m.index === regex.lastIndex) {
                regex.lastIndex++;
            }

            // The result can be accessed through the m-variable.
            m.forEach((match, groupIndex) => {
                let arr = match.split("-");
                width = parseInt(arr[0].replace("w", ""));

                height = parseInt(arr[1].replace("h", ""));
            });
        }
        if (!width || !height) {
            if (screenSizes && screenSizes.length) {
                if (index || index === 0) {
                    width = screenSizes[index].width;
                    height = screenSizes[index].height;
                } else {
                    width = screenSizes[0].width;
                    height = screenSizes[0].height;
                }
            }
        }
    } catch (error) {}

    // url = url.trim();
    // if (url.endsWith("/")) {
    //     url = url.substring(0, url.length - 1);
    // }
    // let filename = url.split("/").pop();
    // console.log("filename ", filename);
    // let splitWord = filename.split("-");
    // let width = splitWord[splitWord.length - 3].replace("w", "");
    return { width, height };
};

export const updateAttrOfAnswer = (
    worksheet: IWorksheet,
    answer: any,
    newVersionInteractive: boolean,
    ratio: number,
    isFinish?: boolean,
    isMainPrint?: boolean
) => {
    let newTop = 0;
    let newLeft = 0;
    let newWidth = 0;
    let newHeight = 0;
    let positionTop = 0;
    let newFontSize = answer?.fontSize ?? 16;
    let imageCustomizeRatio = 1;
    // let elementWidth = isMainPrint ? ConfigWordSearch.WIDTH * 0.9 : 0;
    let elementWidth = isMainPrint
        ? document?.querySelector("#print-id")?.getBoundingClientRect()?.width
        : 0;
    // let elementWidth = 0;
    // if (isMainPrint) {
    //     newTop = answer.top;
    //     newLeft = answer.left;
    //     newWidth = answer.width;
    //     newHeight = answer.height;
    //     positionTop = newTop;
    // }

    if (newVersionInteractive) {
        // if (
        //     getScreenSizeFromURL(
        //         worksheet.game.images[0],
        //         worksheet.game.screenSizes
        //     ).height < window.innerHeight &&
        //     window.innerWidth > 768
        // ) {
        //     console.log("Ok");

        //     let paddingTop =
        //         (document
        //             .querySelector(".image-game-worksheet")
        //             ?.getBoundingClientRect()?.height -
        //             worksheet?.game?.screenSize.height) /
        //         2;
        //     newTop = answer.top * ratio + paddingTop;
        //     newLeft = answer.left * ratio;
        //     newWidth = answer.width * ratio;
        //     newHeight = answer.height * ratio;
        //     positionTop = answer.top * ratio;
        // } else {
        let images = [];
        let screenSizes = [];
        if (isFinish && worksheet.gameSubmitted?.screenSizes.length) {
            images = worksheet.gameSubmitted?.images;
            screenSizes = worksheet.gameSubmitted?.screenSizes;
        } else {
            images = worksheet.game.images;
            screenSizes = worksheet.game.screenSizes;
        }

        let ratioList = getRatioList(
            worksheet,
            newVersionInteractive,
            isFinish,
            elementWidth
        );

        let corePageWidth = 0;
        for (let index = 0; index < images.length; index++) {
            let imageWidth = getScreenSizeFromURL(
                images[index],
                screenSizes,
                index
            ).width;

            if (ratioList[index] * imageWidth > corePageWidth) {
                corePageWidth = imageWidth * ratioList[index];
            }
        }

        let currentPage = answer?.page ?? 0;
        let currentAnswerTop = answer.top;
        let previousPageLength = 0;
        for (let index = 0; index < answer?.page; index++) {
            currentAnswerTop -= getScreenSizeFromURL(
                images[index],
                screenSizes,
                index
            ).height;
        }

        for (let index = 0; index < answer.page; index++) {
            previousPageLength +=
                getScreenSizeFromURL(images[index], screenSizes, index).height *
                ratioList[index];
        }

        newTop = previousPageLength + currentAnswerTop * ratioList[currentPage];
        newWidth = answer.width * ratioList[currentPage];
        newHeight = answer.height * ratioList[currentPage];
        let additionRatio = 1;
        if (worksheet.originUrl === "user_created_ws") {
            additionRatio = 0.794;
        }
        if (worksheet.originUrl.includes("https://www.liveworksheets.com")) {
            newFontSize = newHeight / 1.5;
        } else {
            newFontSize = answer.fontSize
                ? (answer.fontSize * ratioList[answer.page]) / additionRatio
                : 16 * ratioList[answer.page];
        }

        newLeft =
            answer.left * ratioList[currentPage] +
            (corePageWidth -
                getScreenSizeFromURL(
                    images[currentPage],
                    screenSizes,
                    currentPage
                ).width *
                    ratioList[currentPage]) /
                2;
        imageCustomizeRatio = ratioList[currentPage];
        positionTop = newTop;

        // }
    } else {
        newTop = answer.top * ratio;
        newLeft = answer.left * ratio;
        newWidth = answer.width * ratio;
        newHeight = answer.height * ratio;
        let additionRatio = 1;
        if (worksheet.originUrl === "user_created_ws") {
            additionRatio = 0.794;
        }
        if (worksheet.originUrl.includes("https://www.liveworksheets.com")) {
            newFontSize = newHeight / 1.5;
        } else {
            newFontSize = answer.fontSize
                ? (answer.fontSize * ratio) / additionRatio
                : 16 * ratio;
        }
        // if (worksheet.originUrl === "user_created_ws") newTop -= 4 * ratio;
    }
    // newFontSize = newWidth < 24 ? 10 : newFontSize;

    return {
        top: newTop,
        left: newLeft,
        width: newWidth,
        height: newHeight,
        fontSize: newFontSize,
        imageCustomizeRatio: imageCustomizeRatio,
        positionTop,
    };
};

const getSizeOfImage = (
    newVersionInteractive: boolean,
    screenSizes: any[],
    pageIndex: number,
    elementWidth?: number
) => {
    if (newVersionInteractive) {
        let imageWidth =
            elementWidth || (elementWidth && elementWidth > 0)
                ? elementWidth
                : window.innerWidth;
        let screenSz = {
            width: 1,
            height: 1,
        };
        if (screenSizes?.length - 1 < pageIndex) {
            screenSz = screenSizes[screenSizes?.length - 1];
        } else {
            screenSz = screenSizes[pageIndex];
        }
        if (imageWidth <= 1024) {
            const elementImg =
                document?.getElementsByClassName("image-container");
            if (
                elementImg?.length &&
                (elementWidth || (elementWidth && elementWidth <= 0))
            ) {
                imageWidth = elementImg[0].clientWidth;
            }
            let ratio = imageWidth / screenSz?.width;

            return {
                width: screenSz.width * ratio,
                height: screenSz.height * ratio,
            };
        } else {
            let widthRatio = 1000 / screenSz.width;
            let heightRatio = window.innerHeight / screenSz.height;
            let ratio = widthRatio > heightRatio ? heightRatio : widthRatio;

            return {
                width: screenSz.width * ratio,
                height: screenSz.height * ratio,
            };
        }
    } else {
        let imgEle = document?.getElementsByClassName("other-page");
        if (imgEle && imgEle.length) {
            const boundingClient = imgEle[0]?.getBoundingClientRect();
            return {
                width: boundingClient.width,
                height: boundingClient.height,
            };
        }
    }

    return { width: 0, height: 0 };
};

export const convertChooseGameValue = (value: string) => {
    let convertedValue = value.replace("*", "").replace(/\$/g, "'");
    return convertedValue;
};

export const isNumeric = (n) => {
    return !isNaN(parseFloat(n)) && isFinite(n) && Number(n) >= 0;
};

export function getParamsForUpdateScore(
    worksheet: Worksheet,
    bonusScore: number,
    token: string
) {
    let categories: Array<any> = [];
    // for (let tagId of worksheet.tagIds) {
    //     categories.push({
    //         id: tagId,
    //         bonusScore: bonusScore,
    //     });
    // }

    // just push first categoryId of list sub CATEGORY_CHILDidS
    // let categoryIds = ConstantsResource.CATEGORY_CHILDidS;
    // for (let index = 0; index < categoryIds.length; index++) {
    //     let indexCategoriesChildId = worksheet.categoryIds.findIndex(
    //         (e) => e == categoryIds[index]
    //     );
    //     if (indexCategoriesChildId > -1) {
    //         if (worksheet.categoryIds.length > indexCategoriesChildId + 1) {
    //             categories.push({
    //                 id: worksheet.categoryIds[indexCategoriesChildId + 1],
    //                 bonusScore: bonusScore,
    //             });
    //             break;
    //         }
    //     }
    // }
    let params = {
        uuId: token,
        categories: categories ?? [],
    };
    //
    return params;
}

export const getRatioFromUrl = (url: string) => {
    try {
        let filename = url.split("/").pop();
        let splitWord = filename.split("-");
        let width = parseInt(splitWord[splitWord.length - 3].replace("w", ""));
        let height = parseInt(splitWord[splitWord.length - 2].replace("h", ""));
        return width / height;
    } catch (error) {
        return 1;
    }
};

export const getRatioFromUrlPreview = (url: string) => {
    try {
        let filename = url.split("/").pop();

        let splitWord = filename.split("-");
        let widthImage = parseInt(
            splitWord[splitWord.length - 4].replace("w", "")
        );
        let heightImage = parseInt(
            splitWord[splitWord.length - 3].replace("h", "")
        );
        return { widthImage, heightImage };
    } catch (error) {
        return { widthImage: 0, heightImage: 0 };
    }
};

//cloning method needed to copy object, instead of reference to object
export const cloneObj = (obj) => {
    let newObj = new Array();
    for (let i in obj) {
        if (obj[i] && typeof obj[i] == "object") newObj[i] = cloneObj(obj[i]);
        else newObj[i] = obj[i];
    }
    return newObj;
};

//cloning method needed to copy array, instead of reference to object
export const clone = (existingArray) => {
    let newObj = existingArray instanceof Array ? [] : {};
    for (let i in existingArray) {
        if (i == "clone") continue;
        if (existingArray[i] && typeof existingArray[i] == "object") {
            newObj[i] = clone(existingArray[i]);
        } else {
            newObj[i] = existingArray[i];
        }
    }
    return newObj;
};

export const getTimeAlgorithm = (
    minCycles,
    timeMultiplier,
    timeExponent,
    iteratorTime
) => {
    return (
        minCycles +
        Math.floor(Math.pow(Math.random(), timeExponent) * timeMultiplier) *
            iteratorTime
    );
};

// Function to generate random number
export const randomNumber = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min + 1)) + min;
};

export const shuffleArray = (array) => {
    let curId = array.length;
    // There remain elements to shuffle
    while (0 !== curId) {
        // Pick a remaining element
        let randId = Math.floor(Math.random() * curId);
        curId -= 1;
        // Swap it with the current element.
        let tmp = array[curId];
        array[curId] = array[randId];
        array[randId] = tmp;
    }
    return array;
};

export const getDirection = (direction: string) => {
    let xIncrement;
    let yIncrement;
    switch (direction) {
        case "left":
            xIncrement = 1;
            yIncrement = 0;
            break;
        case "down":
            xIncrement = 0;
            yIncrement = 1;
            break;
        case "right":
            xIncrement = -1;
            yIncrement = 0;
            break;
        case "up":
            xIncrement = 0;
            yIncrement = -1;
            break;
        case "left-down":
            xIncrement = 1;
            yIncrement = 1;
            break;
        case "left-up":
            xIncrement = 1;
            yIncrement = -1;
            break;
        case "right-down":
            xIncrement = -1;
            yIncrement = 1;
            break;
        case "right-up":
            xIncrement = -1;
            yIncrement = -1;
            break;
        default:
            //should be never
            xIncrement = 1;
            yIncrement = 0;
            break;
    }
    return { xIncrement, yIncrement };
};

export const getDirectionRestrictions = (id: number) => {
    let directionsArray = [];
    switch (id) {
        case 0:
            directionsArray = ["left", "down"];
            break;
        case 1:
            directionsArray = ["left", "down", "left-down"];
            break;
        case 2:
            directionsArray = ["left", "down", "right", "up"];
            break;
        default:
            directionsArray = [
                "left",
                "down",
                "right",
                "up",
                "left-down",
                "left-up",
                "right-down",
                "right-up",
            ];
            break;
    }
    return directionsArray;
};
const getRatioList = (
    worksheet: IWorksheet,
    newVersionInteractive: boolean,
    isFinish?: boolean,
    elementWidth?: number
) => {
    let images = [];
    let screenSizes = [];
    if (isFinish && worksheet.gameSubmitted?.screenSizes.length) {
        images = worksheet.gameSubmitted?.images;
        images.forEach((image, imageIndex) => {
            if (
                getScreenSizeFromURL(
                    image,
                    worksheet.game.screenSizes,
                    imageIndex
                ).width
            ) {
                screenSizes.push(
                    getScreenSizeFromURL(
                        image,
                        worksheet.game.screenSizes,
                        imageIndex
                    )
                );
            }
        });
    } else {
        images = worksheet.game.images;
        images.forEach((image, imageIndex) => {
            if (
                getScreenSizeFromURL(
                    image,
                    worksheet.game.screenSizes,
                    imageIndex
                ).width
            ) {
                screenSizes.push(
                    getScreenSizeFromURL(
                        image,
                        worksheet.game.screenSizes,
                        imageIndex
                    )
                );
            }
        });
    }
    if (!screenSizes.length) {
        screenSizes = worksheet.game.screenSizes;
    }

    if (!screenSizes.length) {
        screenSizes = worksheet.game.screenSizes;
    }

    let ratioList = [];

    images.forEach((image, index) => {
        let ratioWidth =
            getSizeOfImage(
                newVersionInteractive,
                screenSizes,
                index,
                elementWidth
            ).width / getScreenSizeFromURL(image, screenSizes, index).width;
        let ratioHeight =
            getSizeOfImage(
                newVersionInteractive,
                screenSizes,
                index,
                elementWidth
            ).height / getScreenSizeFromURL(image, screenSizes, index).height;

        ratioList.push(ratioWidth > ratioHeight ? ratioHeight : ratioWidth);
    });
    return ratioList;
};

export function checkWorksheetPage(pathName: string) {
    return pathName.includes(Routes.PRINTABLE_INTERACTIVE);
}

export function replaceForLink(str: string, regexReplace?: string) {
    if (!!!str) return "";
    let result = "";
    for (let i = 0; i < str.length; i++) {
        let c = str?.charAt(i);
        if (SPECIAL_CHARACTERS[c]) {
            c = REPLACEMENTS[SPECIAL_CHARACTERS[c]];
        }
        result += c;
    }

    return result
        .replace(/ /g, "-")
        .replace(/[&\/\\#,+()$~%.'":*?<>{}]/g, "-")
        .replace(/-{2,}/g, "-")
        .replace(/---/g, "-")
        .replace(/--/g, "-")
        .replace(/\b/g, "")
        .toLowerCase();
}

export const getTypeImageFromUrl = (url: string) => {
    if (/\.(jpg)$/.test(url)) {
        return "jpg";
    }
    if (/\.(jpeg)$/.test(url)) {
        return "jpeg";
    }
    if (/\.(png)$/.test(url)) {
        return "png";
    }
    if (/\.(webp)$/.test(url)) {
        return "webp";
    }
    if (/\.(avif)$/.test(url)) {
        return "avif";
    }
    if (/\.(gif)$/.test(url)) {
        return "gif";
    }
    if (/\.(svg)$/.test(url)) {
        return "svg";
    }
    return "";
};

const SPECIAL_CHARACTERS: any = {
    À: 0,
    Á: 1,
    Â: 2,
    Ã: 3,
    È: 4,
    É: 5,
    Ê: 6,
    Ì: 7,
    Í: 8,
    Ò: 9,
    Ó: 10,
    Ô: 11,
    Õ: 12,
    Ù: 13,
    Ú: 14,
    Ý: 15,
    à: 16,
    á: 17,
    â: 18,
    ã: 19,
    è: 20,
    é: 21,
    ê: 22,
    ì: 23,
    í: 24,
    ò: 25,
    ó: 26,
    ô: 27,
    õ: 28,
    ù: 29,
    ú: 30,
    ý: 31,
    Ă: 32,
    ă: 33,
    Đ: 34,
    đ: 35,
    Ĩ: 36,
    ĩ: 37,
    Ũ: 38,
    ũ: 39,
    Ơ: 40,
    ơ: 41,
    Ư: 42,
    ư: 43,
    Ạ: 44,
    ạ: 45,
    Ả: 46,
    ả: 47,
    Ấ: 48,
    ấ: 49,
    Ầ: 50,
    ầ: 51,
    Ẩ: 52,
    ẩ: 53,
    Ẫ: 54,
    ẫ: 55,
    Ậ: 56,
    ậ: 57,
    Ắ: 58,
    ắ: 59,
    Ằ: 60,
    ằ: 61,
    Ẳ: 62,
    ẳ: 63,
    Ẵ: 64,
    ẵ: 65,
    Ặ: 66,
    ặ: 67,
    Ẹ: 68,
    ẹ: 69,
    Ẻ: 70,
    ẻ: 71,
    Ẽ: 72,
    ẽ: 73,
    Ế: 74,
    ế: 75,
    Ề: 76,
    ề: 77,
    Ể: 78,
    ể: 79,
    Ễ: 80,
    ễ: 81,
    Ệ: 82,
    ệ: 83,
    Ỉ: 84,
    ỉ: 85,
    Ị: 86,
    ị: 87,
    Ọ: 88,
    ọ: 89,
    Ỏ: 90,
    ỏ: 91,
    Ố: 92,
    ố: 93,
    Ồ: 94,
    ồ: 95,
    Ổ: 96,
    ổ: 97,
    Ỗ: 98,
    ỗ: 99,
    Ộ: 100,
    ộ: 101,
    Ớ: 102,
    ớ: 103,
    Ờ: 104,
    ờ: 105,
    Ở: 106,
    ở: 107,
    Ỡ: 108,
    ỡ: 109,
    Ợ: 110,
    ợ: 111,
    Ụ: 112,
    ụ: 113,
    Ủ: 114,
    ủ: 115,
    Ứ: 116,
    ứ: 117,
    Ừ: 118,
    ừ: 119,
    Ử: 120,
    ử: 121,
    Ữ: 122,
    ữ: 123,
    Ự: 124,
    ự: 125,
    Ỹ: 126,
    Ỳ: 127,
};
const REPLACEMENTS = [
    "A",
    "A",
    "A",
    "A",
    "E",
    "E",
    "E",
    "I",
    "I",
    "O",
    "O",
    "O",
    "O",
    "U",
    "U",
    "Y",
    "a",
    "a",
    "a",
    "a",
    "e",
    "e",
    "e",
    "i",
    "i",
    "o",
    "o",
    "o",
    "o",
    "u",
    "u",
    "y",
    "A",
    "a",
    "D",
    "d",
    "I",
    "i",
    "U",
    "u",
    "O",
    "o",
    "U",
    "u",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "A",
    "a",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "E",
    "e",
    "I",
    "i",
    "I",
    "i",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "O",
    "o",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "U",
    "u",
    "Y",
    "Y",
];

export const getGrade = (worksheet: IWorksheet) => {
    let gradeString = "";
    let grades = worksheet.moreInfo?.grades;
    if (!grades || grades.length === 0) return "";
    grades.sort((firstGrade: string, secondGrade: string) => {
        return getGradePoint(firstGrade) - getGradePoint(secondGrade);
    });
    if (grades.length > 1) {
        gradeString = grades[0] + " - " + grades[grades.length - 1];
    } else {
        gradeString = grades[0];
    }
    return gradeString;
};

export const getAge = (worksheet: IWorksheet) => {
    let ageString = "";
    let newAge = worksheet.moreInfo?.newAge;
    if (!newAge || newAge.length === 0) return ageString;
    newAge.sort((firstAge, secondAge) => {
        return parseInt(firstAge) - parseInt(secondAge);
    });
    if (newAge.length > 1) {
        ageString = newAge[0] + " - " + newAge[newAge.length - 1];
    } else {
        ageString = newAge[0];
    }
    return ageString;
};

const getGradePoint = (grade: string) => {
    switch (grade) {
        case "Early Childhood":
            return 0;
        case "Kindergarten":
            return 1;
        case "Grade 1":
            return 2;
        case "Grade 2":
            return 3;
        case "Grade 3":
            return 4;
        case "Grade 4":
            return 5;
        case "Grade 5":
            return 6;
        case "Grade 6":
            return 7;
        case "Grade 7":
            return 8;
        case "Grade 8":
            return 9;
        case "Grade 9":
            return 10;
        case "Grade 10":
            return 11;
        case "Grade 11":
            return 12;
        case "Grade 12":
            return 13;
        case "College":
            return 14;
        case "Adult Education":
            return 15;
        default:
            return 0;
    }
};

export const scrollToTop = (isSmoothScroll: boolean = true) => {
    window.scroll({
        top: 0,
        left: 0,
        behavior: isSmoothScroll ? "smooth" : undefined,
    });
};

export const removeParam = (key: any, sourceURL: any) => {
    var rtn = sourceURL.split("?")[0],
        param,
        params_arr = [],
        queryString =
            sourceURL.indexOf("?") !== -1 ? sourceURL.split("?")[1] : "";
    if (queryString !== "") {
        params_arr = queryString.split("&");
        for (var i = params_arr.length - 1; i >= 0; i -= 1) {
            param = params_arr[i].split("=")[0];
            if (param === key) {
                params_arr.splice(i, 1);
            }
        }
        if (params_arr.length) rtn = rtn + "?" + params_arr.join("&");
    }
    return rtn;
};

export const removeDrawLine = (dispatch: any) => {
    dispatch(removeDrawLineGameJoin());
    // let listLine = document.querySelectorAll('[id^="done"]');
    // listLine.forEach((line) => {
    //     line.appendChild(document.createElement("div"));
    //     line.parentNode?.removeChild(line);
    // });
};

// export const genUrlWorksheet = (worksheet: IWorksheet) => {
//     // const category = getMainCategory(worksheet.categoryIds[0]);
//     return (
//         // "/" +
//         // category?.slug +
//         "/" +
//         replaceForLink(worksheet.title) +
//         "-" +
//         Routes.PRINTABLE_INTERACTIVE +
//         // Routes.WS_NO_INDEX +
//         "-" +
//         worksheet.id
//     );
// };

export function isLogined(userInfo: IUserInfo | null | undefined): boolean {
    return !!(
        userInfo &&
        userInfo.id &&
        userInfo.loginStatus == Config.LOGIN_SUCCESS
    );
}

// export const onMouseUpHandler = (
//     event: any,
//     idGamePanel: string,
//     dispatch: any
// ) => {
//     //
//     let svgItem = document.querySelector('[id^="drawing"]') as HTMLElement;
//     let svgItemBorder = document.querySelector(
//         '[id^="drawing-border"]'
//     ) as HTMLElement;
//     let gameContainerElement = document.querySelector(
//         "." + idGamePanel
//     ) as HTMLElement;
//     let eventTarget = event.target as HTMLElement;
//     let eventTargetClass = eventTarget.getAttribute("class");
//     let svgContainer = document.querySelector("#svg-draw");
//     if (svgItem && gameContainerElement && svgItemBorder) {
//         gameContainerElement.style.cursor = "auto";
//         //
//         if (eventTargetClass === "join-item") {
//             let targetIdItem = eventTarget.getAttribute("id");
//             let startIdElement = svgItem.getAttribute("id");
//             let pageId = startIdElement?.split("-")[2];
//             let eleGame = getElementGame(parseInt(pageId));
//             let screenWidth = eleGame?.getBoundingClientRect().width;
//             let targetPoint = new Offset({
//                 offsetX: parseInt(svgItem.getAttribute("x2") ?? ""),
//                 offsetY: parseInt(svgItem.getAttribute("y2") ?? ""),
//                 screenWidth,
//             });
//             let startPoint = new Offset({
//                 offsetX: parseInt(svgItem.getAttribute("x1") ?? ""),
//                 offsetY: parseInt(svgItem.getAttribute("y1") ?? ""),
//                 screenWidth,
//             });
//             const sizeTarget = eventTarget.getBoundingClientRect();
//             let rect = svgContainer.getBoundingClientRect(),
//                 offsetX = sizeTarget.x - rect.left,
//                 offsetY = sizeTarget.y - rect.top,
//                 endPoint = getPositionEndLine(
//                     offsetY,
//                     offsetX,
//                     sizeTarget.width,
//                     sizeTarget.height,
//                     [startPoint, targetPoint]
//                 );
//             if (endPoint !== null) {
//                 targetPoint = new Offset({
//                     offsetX: endPoint.x,
//                     offsetY: endPoint.y,
//                     screenWidth,
//                 });
//                 svgItem.setAttribute("x2", endPoint.x.toString());
//                 svgItem.setAttribute("y2", endPoint.y.toString());
//             }
//             let startIdItem = startIdElement?.split("-")[1];
//             if (startIdItem && targetIdItem && startIdItem !== targetIdItem) {
//                 let jointElement = document.querySelector(".join-item");
//                 let ratio = 1;
//                 if (jointElement) {
//                     try {
//                         ratio = parseFloat(
//                             jointElement.getAttribute("data-ratio")
//                         );
//                     } catch (error) { }
//                 }

//                 let listLine = document.querySelectorAll('[id^="done"]');

//                 listLine.forEach((line) => {
//                     let id: string = line.id;
//                     let idData: string[] = id.split("-");
//                     if (idData.includes(targetIdItem)) {
//                         idData = idData.filter((id) => id !== targetIdItem);
//                         dispatch(removeMatchingIdAndPointsAction([idData[1]]));
//                     }
//                 });
//                 dispatch(
//                     updateMatchingIdAndPointsAction(
//                         [startIdItem, targetIdItem],
//                         [startPoint, targetPoint],
//                         ratio
//                     )
//                 );
//                 svgItem.appendChild(document.createElement("div"));
//                 svgItem.parentNode?.removeChild(svgItem);
//                 svgItemBorder.appendChild(document.createElement("div"));
//                 svgItemBorder.parentNode?.removeChild(svgItemBorder);
//             } else {
//                 svgItem.appendChild(document.createElement("div"));
//                 svgItem.parentNode?.removeChild(svgItem);
//                 svgItemBorder.appendChild(document.createElement("div"));
//                 svgItemBorder.parentNode?.removeChild(svgItemBorder);
//             }
//         } else {
//             svgItem.appendChild(document.createElement("div"));
//             svgItem.parentNode?.removeChild(svgItem);
//             svgItemBorder.appendChild(document.createElement("div"));
//             svgItemBorder.parentNode?.removeChild(svgItemBorder);
//         }
//     }
// };

// export const onMouseOutHandler = (event: any, idGamePanel: string) => {
//     // const element = document.querySelector("." + idGamePanel) as HTMLElement;
//     // if (!element.contains(event.relatedTarget)) {
//     //     let svgItem = document.querySelector('[id^="drawing"]') as HTMLElement;
//     //     svgItem && svgItem.remove();
//     // }
// };
// export const onMouseMoveHandler = (
//     event: any,
//     idGamePanel: string,
//     isMobile: boolean
// ): void => {
//     // alert("onMouseMoveHandler ");

//     let svgContainer = document.querySelector("#svg-draw");
//     let svgItem = document.querySelector('[id^="drawing"]');
//     let svgItemBorder = document.querySelector('[id^="drawing-border"]');
//     let gameContainerElement = document.querySelector(
//         "." + idGamePanel
//     ) as HTMLElement;
//     if (svgContainer && svgItem && gameContainerElement && svgItemBorder) {
//         gameContainerElement.style.cursor =
//             "url(https://www.liveworksheets.com/lwsmaker/images/pencil.cur),url(https://www.liveworksheets.com/lwsmaker/images/pencil.gif), default";
//         let rect = svgContainer.getBoundingClientRect();
//         let offsetX;
//         let offsetY;
//         if (isMobile) {
//             offsetX = event.touches[0].clientX - rect.left;
//             offsetY = event.touches[0].clientY - rect.top;
//         } else {
//             offsetX = event.clientX - rect.left;
//             offsetY = event.clientY - rect.top;
//         }

//         svgItem.setAttribute("x2", offsetX.toString());
//         svgItem.setAttribute("y2", offsetY.toString());
//         svgItemBorder.setAttribute("x2", offsetX.toString());
//         svgItemBorder.setAttribute("y2", offsetY.toString());
//     }
// };

// export const onTouchEndHandler = (
//     event: any,
//     idGamePanel: string,
//     dispatch: any
// ) => {
//     let svgItem = document.querySelector('[id^="drawing"]') as HTMLElement;
//     let svgItemBorder = document.querySelector('[id^="drawing-border"]');
//     let gameContainerElement = document.querySelector(
//         "." + idGamePanel
//     ) as HTMLElement;
//     //
//     let targetList: Array<any> = [];
//     targetList.push(
//         document.elementFromPoint(
//             event.changedTouches[0].clientX + 0.00001,
//             event.changedTouches[0].clientY + 0.00001
//         )
//     );
//     targetList.push(
//         document.elementFromPoint(
//             event.changedTouches[0].clientX + 0.00001,
//             event.changedTouches[0].clientY - 0.00001
//         )
//     );
//     targetList.push(
//         document.elementFromPoint(
//             event.changedTouches[0].clientX - 0.00001,
//             event.changedTouches[0].clientY + 0.00001
//         )
//     );
//     targetList.push(
//         document.elementFromPoint(
//             event.changedTouches[0].clientX - 0.00001,
//             event.changedTouches[0].clientY + 0.00001
//         )
//     );

//     let eventTarget = event.target as HTMLElement;
//     // let eventTargetClass = eventTarget.getAttribute("class");
//     let touchEndTarget = getContainerElement(targetList);
//     let targetIdItem = eventTarget.getAttribute("id");
//     let touchEndTargetClass = touchEndTarget?.getAttribute("class");
//     let touchEndTargetId = touchEndTarget?.getAttribute("id");
//     let svgContainer = document.querySelector("#svg-draw");

//     const findPoint = (id: string, startPoint: any, targetPoint: any) => {
//         let targetAnswer = document.getElementById(id);
//         const sizeTarget = targetAnswer.getBoundingClientRect();
//         let rect = svgContainer.getBoundingClientRect(),
//             offsetX = sizeTarget.x - rect.left,
//             offsetY = sizeTarget.y - rect.top,
//             endPoint = getPositionEndLine(
//                 offsetY,
//                 offsetX,
//                 sizeTarget.width,
//                 sizeTarget.height,
//                 [startPoint, targetPoint]
//             );
//         return endPoint;
//     };

//     if (svgItem && gameContainerElement && svgItemBorder) {
//         gameContainerElement.style.cursor = "auto";
//         // //
//         if (
//             touchEndTargetClass === "join-item" &&
//             touchEndTargetId !== targetIdItem
//         ) {
//             let startIdElement = svgItem.getAttribute("id");
//             let pageId = startIdElement?.split("-")[2];
//             let eleGame = getElementGame(parseInt(pageId));
//             let screenWidth = eleGame?.getBoundingClientRect().width;

//             let targetPoint = new Offset({
//                 offsetX: parseInt(svgItem.getAttribute("x2") ?? ""),
//                 offsetY: parseInt(svgItem.getAttribute("y2") ?? ""),
//                 screenWidth,
//             });
//             let startPointTmp = new Offset({
//                 offsetX: parseInt(svgItem.getAttribute("x1") ?? ""),
//                 offsetY: parseInt(svgItem.getAttribute("y1") ?? ""),
//                 screenWidth,
//             });
//             let startIdItem = startIdElement?.split("-")[1];
//             const startPointFind = findPoint(
//                 "box-answer-id-" + startIdItem,
//                 targetPoint,
//                 startPointTmp
//             );
//             const startPoint = new Offset({
//                 offsetX: startPointFind.x,
//                 offsetY: startPointFind.y,
//                 screenWidth,
//             });
//             const endPoint = findPoint(
//                 "box-answer-id-" + touchEndTargetId,
//                 startPoint,
//                 targetPoint
//             );
//             if (endPoint !== null) {
//                 targetPoint = new Offset({
//                     offsetX: endPoint.x,
//                     offsetY: endPoint.y,
//                     screenWidth,
//                 });
//                 svgItem.setAttribute("x2", endPoint.x.toString());
//                 svgItem.setAttribute("y2", endPoint.y.toString());
//             }
//             if (startIdItem && touchEndTargetId) {
//                 let jointElement = document.querySelector(".join-item");
//                 let ratio = 1;
//                 if (jointElement) {
//                     try {
//                         ratio = parseFloat(
//                             jointElement.getAttribute("data-ratio")
//                         );
//                     } catch (error) { }
//                 }

//                 let listLine = document.querySelectorAll('[id^="done-"]');
//                 let deleteIds = [];
//                 listLine.forEach((line) => {
//                     let id: string = line.id;
//                     let idData: string[] = id.split("-");
//                     if (idData.includes(touchEndTargetId)) {
//                         let idDataChange = idData.filter(
//                             (id) => id !== touchEndTargetId
//                         );
//                         deleteIds.push(idDataChange[1]);
//                     }
//                     if (idData.includes(startIdItem)) {
//                         let idDataChange = idData.filter(
//                             (id) => id !== startIdItem
//                         );
//                         deleteIds.push(idDataChange[1]);
//                     }
//                 });
//                 console.log(deleteIds);
//                 dispatch(removeMatchingIdAndPointsAction(deleteIds));

//                 dispatch(
//                     updateMatchingIdAndPointsAction(
//                         [startIdItem, touchEndTargetId],
//                         [startPoint, targetPoint],
//                         ratio
//                     )
//                 );
//                 svgItem.appendChild(document.createElement("div"));
//                 svgItem.parentNode?.removeChild(svgItem);
//                 svgItemBorder.appendChild(document.createElement("div"));
//                 svgItemBorder.parentNode?.removeChild(svgItemBorder);
//             }
//         } else {
//             //
//             svgItem.appendChild(document.createElement("div"));
//             svgItem.parentNode?.removeChild(svgItem);
//             svgItemBorder.appendChild(document.createElement("div"));
//             svgItemBorder.parentNode?.removeChild(svgItemBorder);
//         }
//     }
// };
export const randomRgba = ({ opacity }: { opacity: number }) => {
    var o = Math.round,
        r = Math.random,
        s = 255;
    return (
        "rgba(" +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        o(r() * s) +
        "," +
        // r().toFixed(1) +
        opacity +
        ")"
    );
};

//toov2, toolv1
export const downloadPdfFromBase64 = async ({
    base64,
    fileName,
    imageType = "png",
}: {
    base64: string[];
    fileName: string;
    imageType?: string;
}) => {
    try {
        const jsPDF = (await import("jspdf")).default;
        const doc = new jsPDF("p", "pt", "a4", true);
        const width = doc.internal.pageSize.getWidth();
        const height = doc.internal.pageSize.getHeight();
        doc.deletePage(1);
        base64.forEach((image, index) => {
            doc.addPage();
            doc.addImage(
                image,
                imageType,
                0,
                0,
                width,
                height,
                `${index + 1}`,
                "FAST"
            );
        });

        if (!fileName.endsWith(".pdf")) {
            fileName += ".pdf";
        }
        doc.save(fileName);
    } catch (error) {
        console.log("error", error);
    }
};

export const getWidthHeightPuzzleCell = () => {
    let elementPuzzleCell = document.querySelector("#puzzle_cell0-0");
    let widthEle = elementPuzzleCell?.getBoundingClientRect()?.width;
    let heightEle = elementPuzzleCell?.getBoundingClientRect()?.height;
    return {
        widthEle,
        heightEle,
    };
};

export const getEndCellPosition = ({
    startX,
    startY,
    direction,
    wordLength,
}: {
    startX: number;
    startY: number;
    direction: string;
    wordLength: number;
}) => {
    let endCell = [];
    switch (direction) {
        case Constants.DIRECTIONS_ARRAY[0]:
            endCell = [startX + wordLength, startY];
            break;
        case Constants.DIRECTIONS_ARRAY[1]:
            endCell = [startX, startY + wordLength];
            break;
        case Constants.DIRECTIONS_ARRAY[2]:
            endCell = [startX - wordLength, startY];
            break;
        case Constants.DIRECTIONS_ARRAY[3]:
            endCell = [startX, startY - wordLength];
            break;

        case Constants.DIRECTIONS_ARRAY[4]:
            endCell = [startX + wordLength, startY + wordLength];
            break;
        case Constants.DIRECTIONS_ARRAY[5]:
            endCell = [startX + wordLength, startY - wordLength];
            break;
        case Constants.DIRECTIONS_ARRAY[6]:
            endCell = [startX - wordLength, startY + wordLength];
            break;
        case Constants.DIRECTIONS_ARRAY[7]:
            endCell = [startX - wordLength, startY - wordLength];
            break;
        default:
            break;
    }

    return endCell;
};
export const formatMonth = ({ value }: { value: string }) => {
    if (value?.includes("_")) {
        let splitValue = value.split("_");
        let month: any = Number(splitValue[0]);
        let year: any = Number(splitValue[1]);
        const date = new Date(year, month, 0); // 2009-11-10
        month = date.toLocaleString("default", { month: "long" });

        return month + ", " + year;
    }
    return value;
};

export function setSession(key: string, value: any) {
    if (typeof sessionStorage !== "undefined") {
        sessionStorage.setItem(key, value);
    }
}

export function getSession(key: string) {
    if (typeof sessionStorage !== "undefined") {
        return sessionStorage.getItem(key);
    }
    return "";
}

//toov2, toolv1
export const copyTextToClipboard = (text: string) => {
    try {
        if (!navigator.clipboard) {
            let textArea = document.createElement("textarea");
            textArea.value = text;

            // Avoid scrolling to bottom
            textArea.style.top = "0";
            textArea.style.left = "0";
            textArea.style.position = "fixed";

            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();

            try {
                const successful = document.execCommand("copy");
                const msg = successful ? "successful" : "unsuccessful";
                // console.log("Fallback: Copying text command was " + msg);
            } catch (err) {
                // console.error("Fallback: Oops, unable to copy", err);
            }

            document.body.removeChild(textArea);
            return;
        }
        navigator.clipboard.writeText(text);
    } catch (error) {
        console.log("error", error);
    }
};

export const calcWithRatio = (value, ratio = 1) => {
    return value / ratio;
};
export const getResponseDataLogin = (
    response,
    userId,
    teacherLogin = false
) => {
    let objectSend = {
        userId,
        email: response?.email,
        name: response?.name,
        avatar: response?.picture,
        type: response?.type,
        teacherLogin,
    };

    if (!response?.email) {
        const user = response?.user;
        if (user) {
            objectSend.email = user?.email;
            objectSend.name =
                user?.name?.firstName + " " + user?.name?.lastName;
        }
    }

    return objectSend;
};

export const getImageSource = (srcImage) => {
    return srcImage["src"] ? srcImage["src"] : srcImage;
};

export const getUrlAvt = ({ userInfo, src }: { userInfo; src }) => {
    return userInfo?.email
        ? userInfo?.avatar?.length
            ? userInfo?.avatar
            : ConstantsResource.DEFAULT_AVT_USER
        : src;
};

export const changeElementStyle = ({
    prefixId,
    className,
    property = "display",
    value = "none",
}: {
    prefixId?: string;
    className?: string;
    property?: string;
    value?: string;
}) => {
    let elements = document?.querySelectorAll(
        prefixId ? `[id^="${prefixId}"]` : `.${className}`
    );
    elements?.forEach((a: any) => {
        if (a?.style) {
            a.style[property] = value;
        }
    });
};

export const getTextSize = (
    textData,
    fontSize: number,
    fontFamily: string,
    maxWidth?: number,
    lineHeight?: string,
    isBold?: string,
    isItalic?: string
) => {
    let text = document.createElement("div");
    document.body.appendChild(text);
    text.style.fontFamily = fontFamily + " !important";
    text.style.fontSize = fontSize + "px";
    text.style.height = "auto";
    text.style.width = "auto";
    text.style.position = "fixed";
    text.style.whiteSpace = "pre-wrap";
    text.style.zIndex = "1000";
    text.style.top = "0";
    text.style.left = "100px";
    text.style.fontWeight = isBold ? "bold" : "normal";
    text.style.textDecoration = isItalic ? "italic" : "normal";
    text.style.lineHeight = lineHeight ? lineHeight : "1.4";
    text.style.wordBreak = "break-word";

    if (maxWidth) {
        text.style.maxWidth = maxWidth + "px";
    }

    text.innerHTML =
        textData + (textData[textData.length - 1] === "\n" ? "a" : "");
    let height = text.clientHeight;
    let width = text.clientWidth;
    document.body.removeChild(text);
    return {
        width: isItalic ? width * 1.1 : width,
        height: height,
    };
};

export const getTextHeight = (
    textData,
    fontSize: number,
    fontFamily: string,
    width: number
) => {
    let text = document.createElement("div");
    document.body.appendChild(text);
    text.style.fontFamily = fontFamily;
    text.style.fontSize = fontSize + "px";
    text.style.height = "auto";
    text.style.width = width + "px";
    text.style.position = "fixed";
    text.style.whiteSpace = "no-wrap";
    text.style.zIndex = "1000";
    text.style.top = "0";
    text.style.left = "100px";
    text.style.lineHeight = "1.4";

    text.innerHTML = textData;
    let height = text.clientHeight;
    document.body.removeChild(text);

    return height;
};
export const genDisplay = (show: boolean) => {
    return show ? "block" : "none";
};

export const compareTwoArray = (array1, array2) => {
    // if the other array is a falsy value, return
    if (!array1 || !array2) return false;
    // if the argument is the same array, we can be sure the contents are same as well
    if (array1 === array2) return true;
    // compare lengths - can save a lot of time
    if (array1.length != array2.length) return false;

    for (let i = 0, l = array1.length; i < l; i++) {
        // Check if we have nested arrays
        if (array1[i] instanceof Array && array2[i] instanceof Array) {
            // recurse into the nested arrays
            if (!compareTwoArray(array1[i], array2[i])) return false;
        } else if (array1[i] != array2[i]) {
            // Warning - two different object instances will never be equal: {x:20} != {x:20}
            return false;
        }
    }
    return true;
};
export const getCanvasWidth = () => {
    let canvas = document.getElementsByClassName("canvas-paint");

    if (canvas.length) {
        let canvasWidth = canvas[0].getBoundingClientRect().width;
        return canvasWidth;
    }
    return 1000;
};

export const updateDrawDataByRatio = (
    canvasDrawRatio: number,
    drawData: any
) => {
    if (canvasDrawRatio) {
        let coordsDraw = drawData.coordsDraw;
        for (let coordDraw of coordsDraw) {
            coordDraw.strokeWidth *= canvasDrawRatio;
            let coords = coordDraw.coords;
            for (let coord of coords) {
                coord.x *= canvasDrawRatio;
                coord.y *= canvasDrawRatio;
                coord.moveX *= canvasDrawRatio;
                coord.moveY *= canvasDrawRatio;
            }
        }
    }
    return drawData;
};

const getRatioOfImage = (image: string, screenSizes: any[]) => {
    const MAX_TABLET_WIDTH = 1024;
    if (window.innerWidth <= MAX_TABLET_WIDTH) {
        return (
            window.innerWidth / getScreenSizeFromURL(image, screenSizes).width
        );
    }
    let widthRatio = 1000 / getScreenSizeFromURL(image, screenSizes).width;
    let heightRatio =
        window.innerHeight / getScreenSizeFromURL(image, screenSizes).height;
    return widthRatio < heightRatio ? widthRatio : heightRatio;
};

export const getCanvasRatioByWorksheetImage = (
    images: string[],
    screenSizes: any[]
) => {
    let canvasRatio = 1;

    for (let image of images) {
        if (getRatioOfImage(image, screenSizes) < canvasRatio) {
            canvasRatio = getRatioOfImage(image, screenSizes);
        }
    }
    return canvasRatio;
};

export const checkHideInitWordSearchAnswer = (projectName) => {
    return (
        projectName === Config.PROJECT_CLASSROOM ||
        projectName === Config.PROJECT_WEB
    );
};

export const getDayName = (day: number) => {
    if (day % 10 === 1 && day !== 11) {
        return day + "st";
    } else if (day % 10 === 2 && day !== 12) {
        return day + "nd";
    } else if (day % 10 === 3 && day !== 13) {
        return day + "rd";
    } else {
        return day + "th";
    }
};

export const formatAMPM = (date) => {
    const monthNames = [
        "Jan",
        "Feb",
        "Mar",
        "Apr",
        "May",
        "Jun",
        "Jul",
        "Aug",
        "Sep",
        "Oct",
        "Nov",
        "Dec",
    ];
    if (date === -1) {
        return 0;
    }

    date = new Date(date);
    let day = date.getDate();
    let month = date.getMonth();
    let year = date.getFullYear();
    let dayName = getDayName(day);
    let monthName = monthNames[month];
    let fullDateString = monthName + " " + year + " " + dayName + ", ";

    let hours = date.getHours();
    let minutes = date.getMinutes();
    let ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    let strTime = hours + ":" + minutes + " " + ampm;
    return checkIsToolCreate() ? fullDateString + strTime : strTime;
};
export const getWorksheetFromToolV2 = (originUrl) => {
    return [
        Config.USER_CREATED_TOOL_V2_WORD_SEARCH,
        Config.USER_CREATED_TOOL_V2_HAND_WRITING,
        Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK,
        Config.USER_CREATED_TOOL_V2_NAME_TRACING,
        Config.USER_CREATED_TOOL_V2_CUSTOMIZE_WORKSHEET,
        Config.USER_CREATED_WORD_SEARCH,
        Config.USER_CREATED_TOOL_V2_CROSSWORD,
        Config.USER_CREATED_TOOL_V2_ADDITION,
        Config.USER_CREATED_TOOL_V2_SUBTRACTION,
        Config.USER_CREATED_TOOL_V2_MULTIPLICATION,
        Config.USER_CREATED_TOOL_V2_DIVISION,
        Config.USER_CREATED_TOOL_V2_WORD_SCRAMBLE,
        Config.USER_CREATED_TOOL_V2_SENTENCE_SCRAMBLE,
    ].includes(originUrl);
};
export const getOriginUrlFromCurrentActivity = (
    currentActivityToolV2: string
) => {
    switch (currentActivityToolV2) {
        case Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE:
            return Config.USER_CREATED_TOOL_V2_CUSTOMIZE_WORKSHEET;
        case Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE:
            return Config.USER_CREATED_TOOL_V2_WORD_SEARCH;
        case Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE:
            return Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK;
        case Config.ACTIVITY_TYPE.HANDWRITING.TYPE:
            return Config.USER_CREATED_TOOL_V2_HAND_WRITING;
        case Config.ACTIVITY_TYPE.NAME_TRACING.TYPE:
            return Config.USER_CREATED_TOOL_V2_NAME_TRACING;
        case Config.ACTIVITY_TYPE.CROSSWORD.TYPE:
            return Config.USER_CREATED_TOOL_V2_CROSSWORD;
        case Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE:
            return Config.USER_CREATED_TOOL_V2_MULTIPLE_CHOICES;
        case Config.ACTIVITY_TYPE.ADDITION.TYPE:
            return Config.USER_CREATED_TOOL_V2_ADDITION;
        case Config.ACTIVITY_TYPE.SUBTRACTION.TYPE:
            return Config.USER_CREATED_TOOL_V2_SUBTRACTION;
        case Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE:
            return Config.USER_CREATED_TOOL_V2_MULTIPLICATION;
        case Config.ACTIVITY_TYPE.DIVISION.TYPE:
            return Config.USER_CREATED_TOOL_V2_DIVISION;
        case Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE:
            return Config.USER_CREATED_TOOL_V2_WORD_SCRAMBLE;
        case Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE:
            return Config.USER_CREATED_TOOL_V2_SENTENCE_SCRAMBLE;
        default:
            throw new Error("Not match any activity");
    }
};

export const getTypeActivityFromOriginUrl = (originUrl: string) => {
    switch (originUrl) {
        case Config.USER_CREATED_TOOL_V2_CUSTOMIZE_WORKSHEET:
            return Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;
        case Config.USER_CREATED_TOOL_V2_WORD_SEARCH:
            return Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE;
        case Config.USER_CREATED_TOOL_V2_FILL_THE_BLANK:
            return Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE;
        case Config.USER_CREATED_TOOL_V2_HAND_WRITING:
            return Config.ACTIVITY_TYPE.HANDWRITING.TYPE;
        case Config.USER_CREATED_TOOL_V2_NAME_TRACING:
            return Config.ACTIVITY_TYPE.NAME_TRACING.TYPE;
        case Config.USER_CREATED_TOOL_V2_CROSSWORD:
            return Config.ACTIVITY_TYPE.CROSSWORD.TYPE;
        case Config.USER_CREATED_TOOL_V2_MULTIPLE_CHOICES:
            return Config.ACTIVITY_TYPE.MULTIPLE_CHOICES.TYPE;
        case Config.USER_CREATED_TOOL_V2_ADDITION:
            return Config.ACTIVITY_TYPE.ADDITION.TYPE;
        case Config.USER_CREATED_TOOL_V2_SUBTRACTION:
            return Config.ACTIVITY_TYPE.SUBTRACTION.TYPE;
        case Config.USER_CREATED_TOOL_V2_MULTIPLICATION:
            return Config.ACTIVITY_TYPE.MULTIPLICATION.TYPE;
        case Config.USER_CREATED_TOOL_V2_DIVISION:
            return Config.ACTIVITY_TYPE.DIVISION.TYPE;
        case Config.USER_CREATED_TOOL_V2_WORD_SCRAMBLE:
            return Config.ACTIVITY_TYPE.WORD_SCRAMBLE.TYPE;
        case Config.USER_CREATED_TOOL_V2_SENTENCE_SCRAMBLE:
            return Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE;
        default:
            return "";
    }
};

export const addRemoveClassListHide = (
    isAdd: boolean,
    className: string = "hide-important"
) => {
    let allFillTheBlank = document.querySelectorAll(".sentence-text");
    for (let i = 0; i < allFillTheBlank?.length; i++) {
        let node = allFillTheBlank[i];
        if (isAdd) {
            node.classList.add(className);
        } else {
            node.classList.remove(className);
        }
    }
    let allFillTheBlankShape = document.querySelectorAll(
        ".sentence-text-shape"
    );
    for (let i = 0; i < allFillTheBlankShape?.length; i++) {
        let node = allFillTheBlankShape[i];
        if (isAdd) {
            node.classList.add(className + "-shape");
        } else {
            node.classList.remove(className + "-shape");
        }
    }
    let allMultipleChoices = document.querySelectorAll(".answer");
    for (let i = 0; i < allMultipleChoices?.length; i++) {
        let node = allMultipleChoices[i];
        if (node.classList.item(node.classList.length - 1) === className) {
        }
        node.classList.remove("show-keys");
        if (isAdd) {
            node.classList.add(className);
        } else {
            node.classList.remove(className);
        }
    }
};

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const getTextEffectStyle = (item: IResourceItemNew) => {
    let effectData = item.textAttribute;
    if (!item?.textAttribute.fontColor)
        item.textAttribute.fontColor = "#212121";
    switch (item.textAttribute.textEffectId) {
        case CreateWSConfig.EFFECT_NONEid: {
            return {};
        }
        case CreateWSConfig.EFFECT_SHADOWid: {
            return {
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.015,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    ${handleCalculateWithFontSize(
                        effectData.blur * 0.015,
                        item
                    )}px
                    ${effectData.color}, ${effectData.transparency / 100})`,
            };
        }
        case CreateWSConfig.EFFECT_LIFTid: {
            return {
                textShadow: `0px
                    ${handleCalculateWithFontSize(0.45, item)}px
                    ${handleCalculateWithFontSize(
                        effectData.intensity * 0.02925 + 0.45,
                        item
                    )}px
                    rgba(0, 0, 0, ${(effectData.intensity * 0.55 + 5) / 100})`,
            };
        }
        case CreateWSConfig.EFFECT_HOLLOWid: {
            return {
                WebkitTextStroke: handleCalculateWithFontSize(
                    (effectData.thickness - 1) * 0.0075 + 0.0825,
                    item
                ),
            };
        }
        case CreateWSConfig.EFFECT_SPLICEid: {
            return {
                WebkitTextStroke: handleCalculateWithFontSize(
                    (effectData.thickness - 1) * 0.0075 + 0.0825,
                    item
                ),
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.015,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    ${effectData.color}`,
            };
        }
        case CreateWSConfig.EFFECT_ECHOid: {
            return {
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.015,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    ${effectData.color}, 0.5),
                                        ${
                                            handleCalculateWithFontSize(
                                                effectData.offset * 0.015,
                                                item
                                            ) *
                                            -Math.sin(
                                                toRadians(effectData.direction)
                                            ) *
                                            2
                                        }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.015,
                            item
                        ) *
                        Math.cos(toRadians(effectData.direction)) *
                        2
                    }px
                    0px
                    ${effectData.color}, 0.3)`,
            };
        }
        case CreateWSConfig.EFFECT_GLITCHid: {
            return {
                textShadow: `${
                    handleCalculateWithFontSize(
                        effectData.offset * 0.0075,
                        item
                    ) * -Math.sin(toRadians(effectData.direction))
                }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.0075,
                            item
                        ) * Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    rgb(0, 255, 255),
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.0075,
                            item
                        ) * Math.sin(toRadians(effectData.direction))
                    }px
                    ${
                        handleCalculateWithFontSize(
                            effectData.offset * 0.0075,
                            item
                        ) * -Math.cos(toRadians(effectData.direction))
                    }px
                    0px
                    ${effectData.color}`,
            };
        }
        case CreateWSConfig.EFFECT_NEONid: {
            return {
                filter: `drop-shadow(rgba(24, 40, 83, 0.95)
                  0px
                  0px
                  ${handleCalculateWithFontSize(
                      (effectData.intensity - 1) * 0.000904 + 0.150901,
                      item
                  )}px)
                  drop-shadow(rgba(47, 56, 83, 0.75)
                  0px
                  0px
                  ${handleCalculateWithFontSize(
                      (effectData.intensity - 1) * 0.00452 + 0.754507,
                      item
                  )}px)
                  drop-shadow(rgba(47, 56, 83, 0.44)
                  0px
                  0px
                  ${handleCalculateWithFontSize(
                      (effectData.intensity - 1) * 0.01356 + 2.26352,
                      item
                  )}px)`,
                color: `rgb(${effectData.intensity * 2.02 + 53},
                  ${effectData.intensity * 1.97 + 58},
                  ${effectData.intensity * 1.8 + 75})`,
            };
        }
        case CreateWSConfig.EFFECT_BACKGROUNDid: {
            return {
                fill: `#212121`,
                cornerRadius: effectData.roundness,
                padding: effectData.spread,
                opacity: effectData.transparency / 100,
                backgroundColor: effectData.color,
            };
        }
        default: {
            return {};
        }
    }
};

const handleCalculateWithFontSize = (value, item) => {
    if (item.fontSize < 10) {
        return value;
    }
    const newValue = (value / 9) * (item.fontSize - 9) + value;
    return newValue;
};

export const toRadians = (angleDegrees) => {
    return angleDegrees * (Math.PI / 180);
};

export const getStyleText = (
    item: IResourceItemNew,
    checkLineThroughText?: boolean
): any => {
    return {
        ...getTextEffectStyle(item),
        // transform: `rotate(${item.rotate})`,
        fontSize: item.textAttribute.fontSize,
        fontFamily: item.textAttribute.fontFamily,
        fontStyle: item.textAttribute.isItalic ? "italic" : "normal",
        fontWeight: item.textAttribute.isBold ? "bold" : "normal",
        textAlign: item.textAttribute.align ?? "justify",
        color: getTextEffectStyle(item).fill
            ? getTextEffectStyle(item).fill
            : item.textAttribute.fontColor
            ? item.textAttribute.fontColor
            : "#212121",
        textDecoration: checkLineThroughText ? "line-through" : "normal",
        wordBreak: "break-all",
    };
};

const convertPagesToolV2 = (pages: { width: number; height: number }[]) => {
    let minWidth = pages.reduce((min, currentElement) => {
        if (currentElement.width < min.width) {
            return currentElement;
        }
        return min;
    }, pages[0]).width;
    return pages.map((page) => {
        let ratio = minWidth / page.width;
        page.width *= ratio;
        page.height *= ratio;
        return page;
    });
};

export const getPageSizes = (
    worksheet: IWorksheet,
    isTablet: boolean,
    isToolV2?: boolean
) => {
    if (window) {
        let pageSizes = [];
        let images = worksheet.game.images;
        if (isTablet) {
            images.forEach((image, pageIndex) => {
                let screenSizes = worksheet.game.screenSizes;
                let originSize = getScreenSizeFromURL(
                    image,
                    screenSizes,
                    pageIndex
                );

                pageSizes.push({
                    width: window.innerWidth,
                    height:
                        (originSize.height * window.innerWidth) /
                        originSize.width,
                });
            });
        } else {
            images.forEach((image, pageIndex) => {
                let screenSizes = worksheet.game.screenSizes;
                const MAX_WIDTH = Config.INTERACTIVE_IMAGE_SIZE;
                const MAX_HEIGHT = window.innerHeight;
                let originSize = getScreenSizeFromURL(
                    image,
                    screenSizes,
                    pageIndex
                );
                let ratioWidth = MAX_WIDTH / originSize.width;
                let ratioHeight = MAX_HEIGHT / originSize.height;
                let ratio = ratioWidth > ratioHeight ? ratioHeight : ratioWidth;
                pageSizes.push({
                    width: originSize.width * ratio,
                    height: originSize.height * ratio,
                });
            });
        }

        if (isToolV2) {
            pageSizes = convertPagesToolV2(pageSizes);
        }

        return pageSizes;
    }
    return [];
};
export const getNameUser = ({
    userName,
    email,
}: {
    userName: string;
    email: string;
}) => {
    let emailText = email?.split("@");
    if (userName?.toLowerCase() === "anonymous") {
        userName = "";
    }
    return userName ? userName : email ? emailText[0] : "Anonymous";
};
export const getAvtUser = ({ avtUser }) => {
    return !avtUser ||
        avtUser?.trim() ==
            "https://files.liveworksheets.com/avatars/noavatar.jpg"
        ? "/images/other_button/default_avatar.svg"
        : avtUser;
};
export const getAllLanguage = () => {
    return AllLanguage;
};

export const getAllCategories = (categories: any) => {
    let result = [];
    for (let category of categories) {
        if (category?.id) {
            result.push(category);
        }
        let topics = category.children;
        if (topics?.length) {
            for (let topic of topics) {
                if (topic?.id) {
                    result.push(topic);
                }
                let subTopics = topic.children;
                if (subTopics?.length) {
                    for (let subTopic of subTopics) {
                        if (subTopic?.id) {
                            result.push(subTopic);
                            if (subTopic?.children?.length) {
                                for (let secondSubTopic of subTopic?.children) {
                                    if (secondSubTopic?.id) {
                                        result.push(secondSubTopic);
                                        if (secondSubTopic?.children?.length) {
                                            for (let thirdSubTopic of secondSubTopic?.children) {
                                                result.push(thirdSubTopic);
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    return result;
};
const getAllCategoriesV2 = (categories) => {
    let result = [];
    for (let category of categories) {
        let find = result?.find((c) => c?.id === category?.id);
        if (!find) {
            result = [...result, category];
        }
        let subCategories = category?.children;
        if (subCategories?.length) {
            let subResult = getAllCategoriesV2(subCategories);
            result = [...result, ...subResult];
        }
    }
    return result;
};

export function filterUniqueByName(arr: any[]) {
    // ham dang cap nay hung duc viet
    const uniqueNames = {};
    const result = [];
    for (const item of arr) {
        if (!uniqueNames[item.name]) {
            uniqueNames[item.name] = true;
            result.push(item);
        }
    }
    return result;
}
export const getListTag = () => {
    let subjectData: any = getSubjectData();
    const topicData = getTopicData();
    const ccssData = getCcssData();

    // let c1 = getAllCategories(subjectData.concat(topicData, ccssData));
    // let c2 = getAllCategoriesV2(subjectData.concat(topicData, ccssData));
    // console.log("xxx", c1?.length);
    // console.log("xxx", c2?.length);
    // function findDifferentItems(arr1, arr2) {
    //     const differences = [];

    //     for (const item1 of arr1) {
    //         let found = false;
    //         for (const item2 of arr2) {
    //             if (item1.id === item2.id) {
    //                 found = true;
    //                 break;
    //             }
    //         }
    //         // if (!found) {
    //         //     differences.push(item1);
    //         // }
    //         if (!found) {
    //             differences.push(item1);
    //         }
    //     }

    //     return differences;
    // }
    // const differentItems = findDifferentItems(c2, c1);
    // console.log("differentItems", differentItems);

    return filterUniqueByName(
        getAllCategoriesV2(subjectData.concat(topicData, ccssData))
    );
};

export const getInitTypeFromUrl = () => {
    let pathName = window.location.pathname;
    if (pathName.includes(Routes.WORD_SEARCH_MAKER)) {
        return Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE;
    }
    if (pathName.includes(Routes.HANDWRITING)) {
        return Config.ACTIVITY_TYPE.HANDWRITING.TYPE;
    }
    if (pathName.includes(Routes.FILL_IN_BLANK)) {
        return Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE;
    }
    if (pathName.includes(Routes.NAME_TRACING)) {
        return Config.ACTIVITY_TYPE.NAME_TRACING.TYPE;
    }
    if (pathName.includes(Routes.CROSSWORD)) {
        return Config.ACTIVITY_TYPE.CROSSWORD.TYPE;
    }
    if (pathName.includes(Routes.CUSTOMIZE_WORKSHEET)) {
        return Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE;
    }
    return Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE;
};

export const getTypeWorksheetFromGame = () => {
    const initType = getInitTypeFromUrl();
    const interactiveGames = [
        Config.ACTIVITY_TYPE.WORD_SEARCH.TYPE,
        Config.ACTIVITY_TYPE.FILL_IN_BLANK.TYPE,
        Config.ACTIVITY_TYPE.CROSSWORD.TYPE,
        Config.ACTIVITY_TYPE.CUSTOMIZE_WS.TYPE,
    ];
    return interactiveGames.includes(initType)
        ? Config.WORKSHEET_TYPE_INTERACTIVE
        : Config.WORKSHEET_TYPE_PDF;
};

export const getSizeFromShape = (row, column, ratio, widthCell = 34.5) => {
    widthCell = widthCell * ratio;
    let totalWidth = column * widthCell;
    let totalHeight = row * widthCell;
    return { totalWidth, totalHeight };
};

export const checkDisplayToolCreateLeftPanel = (projectName: string) => {
    return projectName === Config.PROJECT_TOOL_CREATE;
};

export const checkIsToolCreate = () => {
    return Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.TOOL_V1;
};

export const checkIsToolV2 = () => {
    return Config.MODULE_NAME === Config.MODULE_NAME_CONFIG.TOOL_V2;
};

export const isImageSvg = (resourceItem: IResourceItemNew) => {
    return (
        resourceItem?.imageAttribute?.url.endsWith("..svg") ||
        resourceItem?.imageAttribute?.url.endsWith(".svg") ||
        resourceItem.type === ConstantsTool.TYPE_RESOURCE_SHAPE
    );
};

export const getDefaultAnswerValue = (value) => {
    if (value) {
        let splitPosition = value.indexOf(":");
        let code = value.substring(1, splitPosition - 1);
        let userAnswer = value.substring(splitPosition + 1, value.length);
        return { code: code, answerValue: userAnswer };
    } else {
        return {
            code: CreateWSConfig.SPEAK_LANGUAGE_LIST[26].code,
            answerValue: "",
        };
    }
};

export const getDefaultLanguage = (value) => {
    if (value) {
        let code = getDefaultAnswerValue(value).code;
        for (let language of CreateWSConfig.SPEAK_LANGUAGE_LIST) {
            if (language.code === code) {
                return language;
            }
        }
    }
    return CreateWSConfig.SPEAK_LANGUAGE_LIST[26];
};

// function lineIntersection(
//     p1: { x: number; y: number },
//     p2: { x: number; y: number },
//     p3: { x: number; y: number },
//     p4: { x: number; y: number }
// ): { x: number; y: number } | null {
//     const denominator =
//         (p4.y - p3.y) * (p2.x - p1.x) - (p4.x - p3.x) * (p2.y - p1.y);
//     // Check if the lines are parallel (denominator is zero)
//     if (denominator === 0) {
//         return null;
//     }
//     const ua =
//         ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) /
//         denominator;
//     const ub =
//         ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) /
//         denominator;
//     // Check if the intersection point is within the line segments
//     if (ua >= 0 && ua <= 1 && ub >= 0 && ub <= 1) {
//         return {
//             x: p1.x + ua * (p2.x - p1.x),
//             y: p1.y + ua * (p2.y - p1.y),
//         };
//     }
//     return null; // No intersection
// }

// export const getPositionLine = (
//     top: number,
//     left: number,
//     width: number,
//     height: number,
//     position: { x: number; y: number }
// ): { x: number; y: number } => {
//     let x = left,
//         y = top,
//         maxX = x + width,
//         maxY = y + height;

//     //right position
//     if (
//         (position.x >= maxX && position.y >= y && position.y <= maxY) ||
//         lineIntersection(
//             { x, y },
//             position,
//             { x: maxX, y: maxY },
//             { x: maxX + 100, y: maxY }
//         ) ||
//         lineIntersection(
//             { x, y: maxY },
//             position,
//             { x: maxX, y },
//             { x: maxX + 100, y }
//         )
//     ) {
//         return { x: maxX + 1, y: maxY - height / 2 + 1 };
//     }

//     // bottom position
//     if (
//         (position.y >= maxY && position.x >= x && position.x <= maxX) ||
//         lineIntersection(
//             { x, y },
//             position,
//             { x: maxX, y: maxY },
//             { x: maxX, y: maxY + 100 }
//         ) ||
//         lineIntersection(
//             { x: maxX, y },
//             position,
//             { x, y: maxY },
//             { x, y: maxY + 100 }
//         )
//     ) {
//         return { x: maxX - width / 2 + 1, y: maxY + 1 };
//     }
//     //left position
//     if (
//         (position.x <= x && position.y >= y && position.y <= maxY) ||
//         lineIntersection(
//             { x: maxX, y: maxY },
//             position,
//             { x, y },
//             { x: x - 100, y }
//         ) ||
//         lineIntersection(
//             { x: maxX, y },
//             position,
//             { x, y: maxY },
//             { x: x - 100, y }
//         )
//     ) {
//         return { x: x - 1, y: maxY - height / 2 - 1 };
//     }
//     return { x: x + width / 2 + 1, y: y - 1 };
// };

export const getUserIdFromUrl = () => {
    let userId = "";
    if (window) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        userId = urlParams.get("userId");
    }
    return userId;
};

export const getUserPracticeIdFromUrl = () => {
    let userPracticeId = "";
    if (window) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        userPracticeId = urlParams.get("userPracticeId");
    }
    return userPracticeId;
};

export const getStudentNameFromUrl = () => {
    let studentName = "";
    if (window) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        studentName = urlParams.get("name");
    }
    return studentName;
};
export const getSubjectData = () => {
    return categories[0].children;
};
export const getTopicData = () => {
    return TopicJson.children;
};
export const getCcssData = () => {
    return CcssJson.children;
};
export const findCategoryByName = ({
    name,
    categories,
}: {
    name: string;
    categories?: any[];
}) => {
    if (!categories) {
        categories = [...getSubjectData(), ...getTopicData(), ...getCcssData()];
    }
    name = name?.toLocaleLowerCase();
    let result: any[] = [];
    if (categories?.length) {
        for (let category of categories) {
            let categoryName = category?.name?.toLowerCase();
            if (categoryName?.includes(name)) {
                result = [category];
            }
            let subCategories = category?.children;
            if (subCategories?.length) {
                let subResult = findCategoryByName({
                    name,
                    categories: subCategories,
                });
                result = [...result, ...subResult];
            }
        }
    }
    return result;
};
export const getUtmSourceFromUrl = () => {
    let utmSource = "";
    if (window) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        utmSource = urlParams.get("utm_source");
    }
    return utmSource;
};

export const getWordsearchDrawRatio = () => {
    if (window) {
        let images = document.getElementsByClassName("image-container");
        if (images.length) {
            let image = images[0];
            let width = image.getBoundingClientRect().width;
            return width / 1000;
        }
    }
    return 1;
};
export const updateQuestionRatio = () => {
    let BASE_CDL_IMAGE_WIDTH = 1239;
    let BASE_CDL_IMAGE_HEIGHT = 1754;

    if (window.innerWidth <= 1024) {
        return window.innerWidth / BASE_CDL_IMAGE_WIDTH;
    } else {
        return window.innerHeight / BASE_CDL_IMAGE_HEIGHT;
    }
};

// export const getElementGame = (pageId: number) => {
//     let eleGame = document.getElementById(`image-game-worksheet-${pageId}`);
//     if (!eleGame) {
//         eleGame = document.querySelector("#image-overview0");
//         if (!eleGame) {
//             eleGame = document.querySelector(".game-worksheets");
//         }
//     }
//     return eleGame;
// };

export const getResourcePosition = (
    resourceItem: IResourceItemNew,
    pageSizes: any,
    isInteractive?: boolean
) => {
    const BASE_WIDTH = ConstantsTool.WIDTH_A4;
    const BASE_HEIGHT = ConstantsTool.HEIGHT_A4;
    let currentPageSize = pageSizes[resourceItem.pageIndex];
    let additionTop = 0;
    for (let index = 0; index < resourceItem.pageIndex; index++) {
        let d = pageSizes[index].height;
        additionTop += d;
    }
    const ratioInteractive = isInteractive
        ? SizeA4.WIDTH_A4 / Config.INTERACTIVE_IMAGE_SIZE
        : 1;

    const page = document.getElementById(
        "page-worksheet-container-" + resourceItem.pageIndex
    );
    const mainScreen = document.getElementById(
        Constants.ID_ELEMENR_GAME_SCREEN
    );
    let dx,
        dy = 0;
    if (page && mainScreen) {
        let boundPage = page.getBoundingClientRect();
        let boundMain = mainScreen.getBoundingClientRect();
        dx = boundPage.x - boundMain.x;
        // dy = boundPage.y
    }

    if (resourceItem.type === ConstantsTool.TYPE_RESOURCE_BACKGROUND_IMAGE) {
        let width = pageSizes[resourceItem.pageIndex].width;
        let height = pageSizes[resourceItem.pageIndex].height;

        let newResourceItem = new ResourceItemNew({
            ...resourceItem,
            x: 0 + dx,
            y: additionTop,
            width: width,
            height: height,
        });
        return newResourceItem;
    } else {
        let widthRatio = currentPageSize.width / SizeA4.WIDTH_A4;
        let ratio = widthRatio * ratioInteractive;
        let newResourceItem = new ResourceItemNew({
            ...resourceItem,
            x: resourceItem.x * ratio + dx,
            y: resourceItem.y * ratio + additionTop,
            width: resourceItem.width * ratio,
            height: resourceItem.height * ratio,
        });
        let fontSize = newResourceItem.textAttribute?.fontSize;
        if (fontSize && newResourceItem.textAttribute) {
            newResourceItem.textAttribute.fontSize = fontSize * ratio;
            newResourceItem.textAttribute.subTexts.forEach((subText) => {
                subText.fontSize *= ratio;
            });
        }

        return newResourceItem;
    }
};

export const validateEmail = (email: string) => {
    const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    return regex.test(email);
};

export function formatDate(date: any) {
    var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    return [day, month, year].join("-");
}
export const getResourceRatio = (
    resourceItem: IResourceItemNew,
    pageSizes: any,
    isInteractive?: boolean
) => {
    let currentPageSize = pageSizes[resourceItem.pageIndex];
    const ratioInteractive = isInteractive
        ? SizeA4.WIDTH_A4 / Config.INTERACTIVE_IMAGE_SIZE
        : 1;

    let widthRatio = currentPageSize.width / SizeA4.WIDTH_A4;
    let ratio = widthRatio * ratioInteractive;

    return ratio;
};

export const calculateTimeDownload = (
    times: number,
    timeNextDownload: Date
) => {
    const newTime = new Date(timeNextDownload);
    if (
        times >= Config.INIT_TIMES_DOWNLOAD &&
        times <= Config.MAX_TIMES_DOWNLOAD
    ) {
        newTime.setMinutes(newTime.getMinutes() + times * 10);
        // newTime.setMinutes(newTime.getMinutes() + 1);
    } else if (times > Config.MAX_TIMES_DOWNLOAD) {
        newTime.setMinutes(
            newTime.getMinutes() + Config.MAX_TIMES_DOWNLOAD * 10
            // newTime.getMinutes() + 1
        );
    }
    return newTime;
};

export const calculateTimeRemaining = (endTime: Date) => {
    if (!endTime) return 0;
    const currentTime = new Date();
    const end = new Date(endTime);
    const timeRemaining = Math.floor(
        (end.getTime() - currentTime.getTime()) / 1000
    );

    return timeRemaining <= 0 ? 0 : timeRemaining;
};
export const removeTooltip = () => {
    let commonTooltip = document.querySelector(
        "#common-tooltip"
    ) as HTMLElement;
    if (commonTooltip) {
        commonTooltip.innerHTML = "";
    }
};

export const checkIsTester = () => {
    const str = getSession(Constants.TESTER_KEY_SAVE);
    return !!str;
};

export const getListTextStyles = (textAttribute: ITextAttribute) => {
    let { subTexts, content } = textAttribute;
    let listText: {
        text: string;
        subText?: ISubText;
        startOffset: number;
    }[] = [];
    if (!subTexts.length) {
        listText.push({ startOffset: 0, text: textAttribute.content });
    } else {
        let startOffset = 0;
        subTexts.forEach((element) => {
            let text = content.substring(startOffset, element.startOffset);
            if (text.length) {
                listText.push({ text: text, startOffset });
            }
            listText.push({
                text: content.slice(element.startOffset, element.endOffset),
                subText: element,
                startOffset: element.startOffset,
            });
            startOffset = element.endOffset;
        });
        if (startOffset < content.length) {
            listText.push({ text: content.slice(startOffset), startOffset });
        }
    }
    return listText;
};

export function rgbToHex(rgbString: string) {
    // Extract individual RGB components using a regular expression
    const match = rgbString.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);

    if (!match) {
        // Return the original string if the format is incorrect
        return rgbString;
    }

    // Extract RGB components from the match result
    const [, r, g, b] = match;

    // Convert each component to its hexadecimal representation
    const hexR = parseInt(r, 10).toString(16).padStart(2, "0");
    const hexG = parseInt(g, 10).toString(16).padStart(2, "0");
    const hexB = parseInt(b, 10).toString(16).padStart(2, "0");

    // Combine the components and return the hexadecimal color code
    return `#${hexR}${hexG}${hexB}`;
}

export const makeDivText = (
    textAttribute: ITextAttribute,
    node: HTMLElement
) => {
    const makeSpan = ({
        subText,
        node,
        key,
        text,
    }: {
        key: number;
        node: HTMLElement;
        subText?: ISubText;
        text: string;
    }) => {
        let spanElement = document.createElement("span");
        spanElement.className = "canvas-text";
        if (subText) {
            const {
                fontColor,
                isBold,
                isItalic,
                isStroke,
                strokeColor,
                strokeWidth,
                underline,
                fontFamily,
                fontSize,
            } = subText;
            spanElement.style.color = fontColor.length ? fontColor : null;
            spanElement.style.fontStyle = isItalic ? "italic" : "normal";
            spanElement.style.fontWeight = isBold ? "bold" : null;
            spanElement.style.textDecoration = underline ? "underline" : null;
            spanElement.style.fontFamily = fontFamily.length
                ? fontFamily
                : null;
            spanElement.style.fontSize = fontSize ? fontSize + "px" : null;
            spanElement.style.webkitTextStrokeWidth = isStroke ? `${strokeWidth}px` : null;
            spanElement.style.webkitTextStrokeColor = isStroke ? `${strokeColor}` : '';
        } else {
            spanElement.style.textDecoration = textAttribute.underline
                ? "underline"
                : null;
        }
        spanElement.textContent = text;
        node.append(spanElement);
    };

    if (node) {
        while (node.firstChild) {
            node.removeChild(node.firstChild);
        }
        let { subTexts } = textAttribute;
        if (subTexts.length) {
            let listText = getListTextStyles(textAttribute);
            listText.forEach((child) => {
                let { subText, text, startOffset } = child;
                makeSpan({
                    key: startOffset,
                    subText: subText,
                    text: text,
                    node,
                });
            });
        } else {
            node.innerHTML = textAttribute.content;
        }
    }
};

export const getSizeDivText = ({
    textAttribute,
    width,
    wordBreak = "break-word",
    measurementPurpose = "autoFontSize",
    test = false
}: {
    textAttribute: ITextAttribute;
    width: number;
    wordBreak?: "break-word" | "normal" | "keep-all";
    measurementPurpose?: "autoFontSize" | "calculateWordsPerLine";
    test: boolean;
}) => {
    let id;
    if (test) {
        id = 'testTempID';
    } else {
        id = 'ABC9KtJKNTt0FZhjs2E08FFhQ';
    }
    const bounding = getBoundingTextBox(textAttribute);
    let divElement = document.getElementById(id);
    if (!divElement) {
        divElement = document.createElement('div');
        divElement.className = 'canvas-text';
        divElement.id = id;
        document.body.appendChild(divElement);
    }
    if (measurementPurpose === 'autoFontSize') {
        divElement.style.width = width + 'px';
        divElement.style.height = 'fit-content';
    } else if (measurementPurpose === 'calculateWordsPerLine') {
        divElement.style.cssText = 'position: absolute; left: 650px; top: 100px; z-index: 1000';
        // don't set width here to calculate words per line by incresing the width of the div
    }
    divElement.style.opacity = id !== 'testTempID' ? "0" : "1";
    divElement.style.fontSize = textAttribute.fontSize + "px";
    divElement.style.fontFamily = textAttribute.fontFamily;
    divElement.style.fontStyle = textAttribute.isItalic ? "italic" : "normal";
    divElement.style.fontWeight = textAttribute.isBold ? "bold" : null;
    divElement.style.textDecoration = textAttribute.underline
        ? "underline"
        : "none";
    divElement.style.whiteSpace = "pre-line";
    divElement.style.wordBreak = wordBreak;
    divElement.style.lineHeight = textAttribute.lineHeight
        ? textAttribute.lineHeight.toString()
        : null;
    divElement.style.letterSpacing = textAttribute.letterSpacing + "em";
    divElement.style.outline = "none";
    divElement.style.border = "none";
    divElement.style.textTransform = textAttribute.textCase === 'AA' ? 'uppercase' : textAttribute.textCase === 'aa' ? 'lowercase' : textAttribute.textCase === 'Aa' ? 'lowercase' : 'none';
    divElement.style.paddingLeft = bounding.paddingLeft;
    divElement.style.textAlign = textAttribute.align;
    divElement.style.webkitTextStrokeWidth = textAttribute.isStroke ? `${textAttribute.strokeWidth}px` : null;
    divElement.style.webkitTextStrokeColor = textAttribute.isStroke ? `${textAttribute.strokeColor}` : '';

    makeDivText(textAttribute, divElement);
    const divHeight = divElement.offsetHeight;
    const divWidth = divElement.scrollWidth;
    if (id !== 'testTempID') {
        divElement.style.width = null;
        divElement.style.fontSize = null;
        divElement.style.fontFamily = null;
        divElement.style.fontStyle = null;
        divElement.style.fontWeight = null;
        divElement.style.textDecoration = "none";
        divElement.textContent = "";
        divElement.style.lineHeight = null;
        divElement.style.letterSpacing = null;
        divElement.style.lineHeight = null;
        divElement.style.webkitTextStrokeWidth = null;
        divElement.style.paddingLeft = null;
        divElement.style.textTransform = "none";
        divElement.innerHTML = "";
        while (divElement.firstChild) {
            divElement.removeChild(divElement.firstChild);
        }
    } else {
        console.log('>', divHeight)
    }
    return { width: divWidth, height: divHeight };
};

export const isWsWeb = (originUrl) => {
    if (!originUrl?.trim()) {
        return false;
    }
    return ConstantsResource.ORIGIN_URL_WEB.includes(
        originUrl?.toLocaleLowerCase()
    );
};
export const getConfigCrossword = (activity: IActivity): ICrossword => {
    const { showAnswerKey, showWordBank, grid, layout, questions } = activity;

    const words = questions.map(
        (question) =>
            new Word({
                value: question.question,
                ...question.crosswordAttribute,
            })
    );

    const result = {
        showAnswerKey: showAnswerKey === Config.SHOW_VALUE,
        showWordBank: showWordBank === Config.SHOW_VALUE,
        layout,
        grid: grid ? JSON.parse(grid) : [],
        words,
    };

    return result;
};

export function checkStrictEquality(value) {
    if (value === +value) {
        return true;
    } else {
        return false;
    }
}
export const getBoundingTextBox = (textAttribute: ITextAttribute) => {
    let { fontSize, lineHeight, subTexts, letterSpacing, align } =
        textAttribute;
    if (subTexts.length) {
        let fontSizeSub = subTexts.reduce((pre, next) => {
            if (pre.fontSize >= next.fontSize) return pre;
            return next;
        }, subTexts[0]).fontSize;
        fontSize = Math.max(fontSize, fontSizeSub);
    }
    let top = (fontSize * (ConstantsTool.LINE_HEIGHT_DEFAULT - lineHeight)) / 2;
    let paddingLeft = "0em";
    if (align === "right") {
        paddingLeft = letterSpacing + "em";
    }
    if (align === "center") {
        paddingLeft = letterSpacing / 2 + "em";
    }
    return { top, paddingLeft };
};

export const getVerticalAlignItemValue = (alignKeyword: string) => {
    switch (alignKeyword) {
        case 'top':
            return 'flex-start';
        case 'middle':
            return 'center';
        case 'bottom':
            return 'flex-end';
        default:
            return 'stretch';
    }
}

export const getJustifyContentItemValue = (textAlign: string) => {
    switch (textAlign) {
        case 'center':
            return 'center';
        case 'left':
            return 'flex-start';
        case 'right':
            return 'flex-end';
        default:
            return 'flex-start';
    }
}

export const hasPermission = (userRoles: string[] = [], permissions: string[] = []) => {
    if (userRoles.indexOf('admin') > -1) {
        return true;
    }

    if (userRoles !== null && userRoles !== undefined) {
        return userRoles.some(role => permissions.includes(role));
    }

    return false;
}

export const getLinesFromContent = (content: string, textAttribute: ITextAttribute, zoneDimenstion: object) => {
    let textContent = '';
    const lines = [];

    const words = content.trim().split(/\s+/);
    let line = '';

    for (const word of words) {
        textContent = line + ' ' + word;
        let { width: textWidth } = getSizeDivText({
            textAttribute: {
                ...textAttribute,
                subTexts: [],
                content: textContent.trimStart()
            },
            width: zoneDimenstion['width'],
            measurementPurpose: 'calculateWordsPerLine',
            test: false,
        })
        if (textWidth > zoneDimenstion['width']) {
            lines.push(line.trim());
            line = word;
        } else {
            line += ' ' + word;
        }
    }

    lines.push(line.trim());
    return lines;
}

export const isEmptyLine = (wordsInLine: string[] = []) => {
    return wordsInLine.length === 1 && wordsInLine[0] === '';
}