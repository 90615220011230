import StepConnector, {
    stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { styled } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ICollection } from "../../../shared/models/collection";
import { IWorksheet } from "../../../shared/models/worksheet";
import { updateUserDownloadedWorksheetAction } from "../../redux/action/game.action";
import {
    loginSuccess,
    updateUserEmail,
    updateUserSurveyAction,
} from "../../redux/action/userInfoResource.action";
import { ResourceAppState } from "../../redux/reducer/root.reducerModule";
import UserRepositoriesWeb from "../../redux/repositories/UserRepositories.web";
import {
    updateUserSentEmailAPI,
    updateUserSurveyAPI,
} from "../../redux/repositories/user";
import ConstantsResource from "../../utils/ConstantsResource";
import Cookies from "../../utils/cookies";
import { isLandingSightWords } from "../../utils/tracking";
import ActiveIcon from "../icons/active-step";
import NextStepIcon from "../icons/next-step";
import { downloadPdfFromImages } from "./image-to-pdf";
import "./index.scss";
// import LoginPopUp from "./login-popup";
import { replaceForLink } from "../../utils";
import DynamicComponent, {
    ExtractParamType,
} from "../common/dynamic-component";

export const ColorlibStepIconRoot = styled("div")<{
    ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
    backgroundColor:
        theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
    zIndex: 1,
    color: "#fff",
    width: "fit-content",
    height: "fit-content",
    display: "flex",
    borderRadius: "50%",
    justifyContent: "center",
    alignItems: "center",
    ...(ownerState.active && {
        backgroundColor: "#EFF0F7",
        boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
}));
export function ColorlibStepIcon(props: StepIconProps) {
    const { active, completed, className, icon } = props;
    const isTablet = useMediaQuery("(max-width:768px)");

    return (
        <ColorlibStepIconRoot
            ownerState={{ completed, active }}
            className={className}
        >
            {completed ? (
                <div
                    style={{
                        width: "32px",
                        height: "32px",
                        justifyContent: "center",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    {/* {isTablet ? icon : <CompletedIcon />} */}
                </div>
            ) : active ? (
                <ActiveIcon />
            ) : (
                <div
                    style={{
                        width: "32px",
                        height: "32px",
                        justifyContent: "center",
                        margin: "auto",
                        display: "flex",
                        alignItems: "center",
                    }}
                >
                    <NextStepIcon />
                </div>
            )}
        </ColorlibStepIconRoot>
    );
}
export const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
    [`&.${stepConnectorClasses.alternativeLabel}`]: {
        position: "unset",
        borderTopColor: "#E4E7EC",
    },
    [`&.${stepConnectorClasses.active}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderTopColor: "#020246",
        },
    },
    [`&.${stepConnectorClasses.completed}`]: {
        [`& .${stepConnectorClasses.line}`]: {
            borderTopColor: "#020246",
        },
    },
    [`& .${stepConnectorClasses.line}`]: {
        height: 0,
        border: 0,
        width: 2,
        borderRadius: 2,
        borderTopWidth: "12px !important",
        margin: "0 0 0 15px",
        borderTopColor: "#E4E7EC",
    },
}));

const PopUpStepAction = ({
    collection,
    setShowFormPopup,
    worksheetList,
    worksheetListName,
    worksheet,
    downloadWorksheet,
    setOpenParentPopup,
    isSaveToCollection,
    saveToCollection,
    otherAction,
    descriptionLogin,
    descriptionThanks,
    className,
    deniedDownload,
    isDownloadPopup,
    isClickLogin,
    linkAfterLogin,
    type,
    teacherLogin,
    title,
    checkReferralLogin,
    loginSuccessAction,
    isRefer,
    isToolV1,
    gaEventSuccessLogin,
}: {
    collection?: ICollection;
    worksheet?: IWorksheet;
    setShowFormPopup: Function;
    worksheetList?: IWorksheet[];
    worksheetListName?: string;
    downloadWorksheet?: Function;
    setOpenParentPopup?: Function;
    isSaveToCollection?: boolean;
    saveToCollection?: Function;
    otherAction?: Function;
    descriptionLogin?: string;
    descriptionThanks?: string;
    className?: string;
    deniedDownload?: boolean;
    isDownloadPopup?: boolean;
    isClickLogin?: boolean;
    linkAfterLogin?: string;
    type?: string;
    teacherLogin?: boolean;
    title?: string;
    checkReferralLogin?: Function;
    loginSuccessAction?: Function;
    isRefer?: boolean;
    isToolV1?: boolean;
    gaEventSuccessLogin?: Function;
}) => {
    let isFillTeacherForm = localStorage.getItem(
        ConstantsResource.LOCAL_STORAGE_KEY_TEACHER_FORM
    );

    let loginDate;
    let doneSurveyAccount;
    let isVerifyAccount;
    const dispatch = useDispatch();
    const userInfo = useSelector(
        (state: ResourceAppState) => state.userInfoResourceState.data
    );
    const categoryIds = useSelector(
        (state: any) =>
            state?.homeV2State?.currentPageData?.worksheet?.categoryIds
    );

    const [checkDate, setCheckDate] = useState(true);
    const [checkDoneSurveyAccount, setcheckDoneSurveyAccount] = useState(false);
    const [activeStep, setActiveStep] = useState(userInfo.email ? 1 : -1);
    const [openForm, setOpenForm] = useState(true);
    const [formData, setFormData] = useState({
        teachGrade: [...userInfo?.survey?.teachGrade],
        topicTeach: [...userInfo?.survey?.topicTeach],
        email: userInfo?.email,
        role: [...userInfo?.survey?.role],
        purpose: [...userInfo?.survey?.purpose],
    });
    let numStep = {
        fillEmail: 0,
        verifyEmail: 1,
        // role: 2,
        // teachGrade: 3,
        // topicTeach: 4,
        // purpose: 5,
    };
    let steps = [
        {
            label: 0,
            description:
                descriptionLogin ??
                "Log in to continue downloading worksheets!",
            stateValue: "email",
            eventName: "survey_form_teacher_email",
            eventParams: "email",
            errorMessage: "Invalid email",
        },
        {
            label: 1,
            description: "Worksheetzone teams",
        },
    ];

    const setLoginDate = (loginDate, activeStep) => {
        if (!loginDate && activeStep !== steps.length) {
            localStorage.setItem("loginDate", new Date().toString());
        }
    };
    const getUserInfo = async (token) => {
        let userInfo = await new UserRepositoriesWeb().loginByToken(token);
        if (userInfo) {
            if (userInfo?.isVerify) {
                localStorage.removeItem("isVerify");
            }
            dispatch(loginSuccess(userInfo));
        }
    };
    useEffect(() => {
        let isFillTeacherForm = localStorage.getItem(
            ConstantsResource.LOCAL_STORAGE_KEY_TEACHER_FORM
        );
        loginDate = localStorage.getItem("loginDate");
        doneSurveyAccount = localStorage.getItem("doneSurvey");
        let token = Cookies.get(ConstantsResource.COOKIE_KEY_WORKSHEET_TOKEN);
        if (token?.length) {
            getUserInfo(token);
        }
        if (isVerifyAccount) {
            // setActiveStep(1);
            setActiveStep(numStep.verifyEmail);
        } else {
            if (doneSurveyAccount) {
                // setActiveStep(2);
                // setActiveStep(numStep.role);
                setActiveStep(steps.length); //new

                setcheckDoneSurveyAccount(true);
            } else {
                // setActiveStep(0);
                setActiveStep(numStep.fillEmail);
            }
        }

        if (loginDate && !(isDownloadPopup || isClickLogin)) {
            const localStorageDate = new Date(loginDate).getDate();
            const currentDate = new Date().getDate();
            if (localStorageDate == currentDate) {
                setCheckDate(false);
            } else {
                setCheckDate(true);
            }
        }

        if (isFillTeacherForm) {
            if (isSaveToCollection) {
                saveToCollection && saveToCollection();
            } else if (otherAction) {
                otherAction();
            } else {
                downloadPdfFromImagesFunction();
                saveToDownloadList();
            }
        }
    }, []);

    useEffect(() => {
        if (userInfo?.email) {
            handleClosePopup();
        }
    }, [activeStep, userInfo?.email]);

    useEffect(() => {
        if (activeStep > 0) {
            localStorage.setItem(
                ConstantsResource.WORKSHEET_DOWNLOAD,
                window.location.pathname
            );
        }

        if (activeStep === 1) {
            updateUserSentEmailAPI({
                sentEmail: true,
                id: userInfo.id,
            });
        }

        if (activeStep > 2) {
            dispatch(
                updateUserSurveyAction(JSON.parse(JSON.stringify(formData)))
            );
            updateUserSurveyAPI({
                survey: formData,
                id: userInfo.id,
            });
        }
        const timeout = setTimeout(() => {
            if (
                typeof window !== "undefined" &&
                window.google &&
                activeStep == 0 &&
                document.getElementById("loginWithGoogle")
            ) {
                setOpenForm(true);
            }
        }, 100);
        return () => {
            clearTimeout(timeout);
        };
    }, [activeStep]);

    const saveToDownloadList = () => {
        if (collection?.id) {
            let worksheetIdList: string[] = [];
            worksheetList.map((worksheet) => {
                worksheetIdList.push(worksheet.id);
            });
            dispatch(
                updateUserDownloadedWorksheetAction(
                    userInfo.token,
                    worksheetIdList
                )
            );
        } else if (worksheet?.id) {
            if (!deniedDownload) {
                downloadWorksheet();
            }
        }
    };

    const trackingLogin = (email, type) => {
        const event = {
            ["email"]: "login_by_email",
            ["apple"]: "login_with_apple",
            ["google"]: "login_with_google",
            ["facebook"]: "login_with_facebook",
        };

        const eventName = event[type];
        if (!eventName) return;

        if (
            isLandingSightWords({
                categoryIds: categoryIds ?? [],
            })
        ) {
        }
    };

    const handleCallback = (res) => {
        const email = res.email;
        if (!email) {
            handleClosePopup();
            return;
        }
        gaEventSuccessLogin && gaEventSuccessLogin();
        trackingLogin(email, res.type);
        localStorage.setItem(
            "doneSurvey",
            ConstantsResource.LOCAL_STORAGE_IS_DONE_SURVEY
        );
        if (loginSuccessAction) {
            loginSuccessAction();
        }
        dispatch(updateUserEmail(email, teacherLogin));
        if (isToolV1) {
            window.location.reload();
            return;
        }
        if (!!linkAfterLogin) {
            window.location.href = linkAfterLogin;
            handleClosePopup();
            return;
        }
        if (res.sentEmail) {
            setFormData(res.survey);
            setActiveStep(steps.length);
        } else {
            let step = activeStep + 1;
            if (step === 1) {
                step += 1;
            }
            setActiveStep(step);
        }
        if (!!email) {
            if (isSaveToCollection) {
                saveToCollection && saveToCollection();
            }
        }
    };

    const handleCloseLogin = (event) => {
        if (event === "success") {
            setOpenForm(false);
            return;
        }
        handleClosePopup();
    };

    useEffect(() => {
        isVerifyAccount = localStorage.getItem("isVerify");
        if (!userInfo.email) {
            setActiveStep(numStep.fillEmail);
        } else {
            if (userInfo?.isVerify == false) {
                setActiveStep(numStep.verifyEmail);
            } else {
                if (userInfo.sentEmail) {
                    setFormData(userInfo.survey);
                    // if (userInfo?.survey?.role?.length == 0) {
                    //     setActiveStep(numStep.role);
                    // } else if (userInfo?.survey?.teachGrade?.length == 0) {
                    //     setActiveStep(numStep.teachGrade);
                    // } else if (userInfo?.survey?.topicTeach?.length == 0) {
                    //     setActiveStep(numStep.topicTeach);
                    // } else if (userInfo?.survey?.purpose?.length == 0) {
                    //     setActiveStep(numStep.purpose);
                    // } else {
                    //     setActiveStep(steps.length);
                    // }
                    setActiveStep(steps.length);
                } else {
                    setActiveStep(activeStep + 1);
                }
            }
        }
    }, []);

    const downloadAction = async () => {
        let downloaded = localStorage.getItem(
            ConstantsResource.EXIT_DOWNLOADED
        );
        let teacherFillForm = localStorage.getItem(
            ConstantsResource.LOCAL_STORAGE_KEY_TEACHER_FORM
        );

        if (
            ((!downloaded || teacherFillForm) && worksheet?.id) ||
            isDownloadPopup
        ) {
            downloadWorksheet && (await downloadWorksheet());
            localStorage.setItem(ConstantsResource.EXIT_DOWNLOADED, "true");
        }
    };
    const handleClosePopup = async () => {
        if (!!userInfo?.email) {
            if (isSaveToCollection) {
                saveToCollection && saveToCollection();
            } else if (otherAction) {
                otherAction();
            } else {
                downloadAction();
                // if (userInfo?.survey?.purpose?.length) {
                //     downloadAction();
                // } else {
                //     firstDownload && firstDownload();
                // }
            }
        }
        if (loginDate) {
            localStorage.removeItem("loginDate");
            localStorage.setItem("loginDate", new Date().toString());
        } else {
            setLoginDate(loginDate, activeStep);
        }
        setShowFormPopup(false);
        setOpenParentPopup && setOpenParentPopup(false);

        if (activeStep === steps.length) {
            downloadAction();
            localStorage.removeItem("doneSurvey");
            localStorage.removeItem("loginDate");
            localStorage.setItem(
                ConstantsResource.LOCAL_STORAGE_KEY_TEACHER_FORM,
                "true"
            );
        }
    };
    const downloadPdfFromImagesFunction = () => {
        let allData: any[] = [];

        worksheetList?.forEach((worksheet) => {
            worksheet.game.images.forEach((image) => {
                if (worksheet?.gameSubmitted?.images.length) {
                    allData.push({
                        url: image,
                    });
                } else {
                    allData.push({
                        url: image,
                        wsId: worksheet.id,
                        originUrl: worksheet.originUrl,
                    });
                }
            });
        });

        if (allData.length) {
            downloadPdfFromImages(
                allData,
                collection?.name ??
                    replaceForLink(
                        Array.isArray(worksheetListName)
                            ? worksheetListName.length
                                ? worksheetListName.join("-")
                                : ""
                            : !!worksheetListName
                            ? worksheetListName
                            : ""
                    ),
                "",
                dispatch
            );
        }
    };

    useEffect(() => {
        var element = document.getElementById("content-step");
        if (element) {
            element.addEventListener("keypress", (e) => {
                if (e.key === "Enter") {
                    if (activeStep > 0) {
                        setActiveStep(activeStep + 1);
                    }
                    e.preventDefault();
                }
            });
        }
    }, [activeStep]);

    return (
        <>
            {isFillTeacherForm ? (
                <></>
            ) : (
                <>
                    {checkDoneSurveyAccount ? (
                        <>
                            {/* {(checkDate || reRender) && ( */}
                            {checkDate ? (
                                activeStep == 0 && (
                                    // <LoginPopUp
                                    <DynamicComponent
                                        path="./login-popup"
                                        component={import("./login-popup")}
                                        open={openForm}
                                        onClose={handleCloseLogin}
                                        callback={handleCallback}
                                        teacherLogin={teacherLogin}
                                        title={title}
                                        checkReferralLogin={checkReferralLogin}
                                        isRefer={isRefer}
                                    />
                                )
                            ) : (
                                <></>
                            )}
                        </>
                    ) : activeStep == 0 ? (
                        <DynamicComponent
                            path="./login-popup"
                            component={import("./login-popup")}
                            open={openForm}
                            onClose={handleCloseLogin}
                            callback={handleCallback}
                            teacherLogin={teacherLogin}
                            title={title}
                            checkReferralLogin={checkReferralLogin}
                            isRefer={isRefer}
                        />
                    ) : (
                        <></>
                    )}
                </>
            )}
        </>
    );
};

export type PopUpStepActionType = ExtractParamType<typeof PopUpStepAction>;

export default PopUpStepAction;
