// import * as htmlToImage from "html-to-image";
import { isSafari } from ".";
import ConstantsTool from "../../shared/utils/ConstantsTool";
// import htmlToImage from "dom-to-image-more";
import Config from "../../shared/utils/config";
import { ConfigWordSearch } from "../../shared/utils/configWordSearch";

const addStyleWhenCaptureImage = (cloneDocument: Document) => {
    // For tool v2;
    const types = [
        Config.ACTIVITY_TYPE.NAME_TRACING.TYPE,
        Config.ACTIVITY_TYPE.SENTENCE_SCRAMBLE.TYPE,
    ];
    try {
        types.forEach((t) => {
            if (t === Config.ACTIVITY_TYPE.NAME_TRACING.TYPE) {
                const elementsClone = cloneDocument.querySelectorAll(
                    `.item-${t} .main-text`
                );
                if (elementsClone?.length > 0) {
                    elementsClone.forEach((e: HTMLElement) => {
                        const spanElement = e.querySelector("span");
                        if (spanElement) {
                            const spanHeight =
                                spanElement.getBoundingClientRect().height;
                            const mainTextHeight =
                                e.getBoundingClientRect().height;
                            let percentMove = spanHeight - mainTextHeight;
                            const MINUS = Math.abs(percentMove) * 0.22;
                            percentMove -= MINUS;
                            e.style.transform = `translateY(${percentMove}%)`;
                        }
                    });
                }
            } else {
                const elementsClone = cloneDocument.querySelectorAll(
                    `.item-2006-${t}`
                );

                if (elementsClone?.length > 0) {
                    elementsClone.forEach((e: HTMLElement) => {
                        const questionContainer =
                            e.querySelector(".text-container");
                        const inputs = e.querySelectorAll(".input");
                        const inputContainer = e.querySelector(".normal-input");

                        if (questionContainer) {
                            const prefix: HTMLElement =
                                questionContainer.querySelector(".prefix");
                            const shuffle: HTMLElement =
                                questionContainer.querySelector(".shuffle");
                            if (prefix) {
                                prefix.style.transform = `translateY(${5}%)`;
                            }
                            if (shuffle) {
                                shuffle.style.transform = `translateY(${5}%)`;
                            }
                        }

                        inputs.forEach((e: HTMLElement | any) => {
                            const div = document.createElement("div");
                            div.setAttribute("style", e.getAttribute("style"));
                            div.innerText = e?.value;
                            div.style.background = "transparent";
                            div.style.position = "absolute";
                            div.style.lineHeight = "var(--line-height)";
                            // textarea not support by html2canvas
                            inputContainer?.replaceChild(div, e);
                        });
                    });
                }
            }
        });
    } catch (error) {}
};

export const convertImagesToBase64 = async (elementId = "content-layer") => {
    let nodes = Array.from(document.querySelectorAll("#" + elementId)) as HTMLElement[];

    let imagesPromises = nodes.map(async (node) => {
        let scale = ConstantsTool.EXPORT_WIDTH_A4 / node.offsetWidth;
        // if (isSafari()) {
            console.log('safari');
            const html2canvas = (await import('html2canvas')).default;

            // Wait for all images to load
            const images = Array.from(node.getElementsByTagName('img'));
            await Promise.all(images.map(img => {
                if (img.complete) return Promise.resolve();
                return new Promise((resolve, reject) => {
                    img.onload = resolve;
                    img.onerror = reject;
                });
            }));

            await new Promise(resolve => setTimeout(resolve, 100));

            return new Promise((resolve, reject) => {
                html2canvas(node, {
                    logging: false,
                    useCORS: true,
                    allowTaint: true,
                    // onclone(document) {
                        // addStyleWhenCaptureImage(document);
                    // },
                    onclone: (clonedDoc) => {
                        // Ensure all styles are computed and applied
                        const elements = clonedDoc.getElementsByTagName('*');
                        // Array.from(elements).forEach(el => {
                        //     if (el instanceof HTMLElement) {
                        //         el.style.cssText = window.getComputedStyle(el).cssText;
                        //     }
                        // });
                    }
                }).then((canvas) => {
                    resolve(canvas.toDataURL());
                });
            });
        // }
        console.log('not safari');
        const htmlToImage = (await import("dom-to-image-more")).default;
        return new Promise((resolve, reject) => {
            htmlToImage
                .toPng(node, {
                    height: node.offsetHeight * scale,
                    width: node.offsetWidth * scale,
                    style: {
                        transform: "scale(" + scale + ")",
                        transformOrigin: "top left",
                        width: node.offsetWidth + "px",
                        height: node.offsetHeight + "px",
                    },
                    filter: node => {
                        if (node.classList && (
                            node.classList.contains('custom-zone') ||
                            // node.classList.contains('custom-zone-name') ||
                            node.classList.contains('custom-zone-suffle') ||
                            node.classList.contains('custom-zone-test-text') ||
                            node.classList.contains('not-click'))) {
                            return false;
                        }
                        return true;
                    }
                })
                .then(function (dataUrl: any) {
                    resolve(dataUrl);
                })
                .catch(function (err: any) {
                    reject(err);
                });
        });
    });
    let images = (await Promise.all(imagesPromises)) as string[];
    return images;
};

export const convertImagesToBase64ToPrint = async (elementName) => {
    try {
        let nodes = Array.from(
            document.querySelectorAll(elementName)
        ) as HTMLElement[];

        let imagesPromises = nodes.map(async (node) => {
            let boundingRect = node.getBoundingClientRect();
            let nodeWidth = boundingRect.width;

            let scale = ConfigWordSearch.WIDTH / nodeWidth;
            if (isSafari()) {
                const html2canvas = (await import("html2canvas")).default;

                try {
                    let ready = false;
                    let value = await html2canvas(node, {
                        logging: false,
                        useCORS: true,
                        scale: scale * 0.866,
                        x: 0,
                        y: 0,
                        onclone(document, element) {
                            if (element) {
                                ready = true;
                            }
                        },
                    });
                    if (ready) {
                        return value.toDataURL();
                    } else {
                        console.log("ready false", value.toDataURL());
                    }
                } catch (error) {
                    console.log("isSafari err", error);
                }
            }
            const htmlToImage = (await import("dom-to-image-more")).default;

            return new Promise((resolve, reject) => {
                htmlToImage

                    .toPng(node, {})
                    .then(function (dataUrl: any) {
                        resolve(dataUrl);
                    })
                    .catch(function (err: any) {
                        console.log("htmlToImage err", err);
                        reject(err);
                    });
            });
        });
        let images = (await Promise.all(imagesPromises)) as string[];
        return images;
    } catch (error) {
        console.log("convertImagesToBase64ToPrint", error);
    }
};

export const convertImgToBase64 = async (
    elementId = "right-word-search"
): Promise<string> => {
    const node = document.getElementById(elementId);
    const scale = ConfigWordSearch.WIDTH / node.offsetWidth;
    const htmlToImage = (await import("dom-to-image-more")).default;
    return new Promise((resolve, reject) => {
        htmlToImage
            .toPng(node, {
                height: node.offsetHeight * scale,
                width: node.offsetWidth * scale,
                style: {
                    transform: "scale(" + scale + ")",
                    transformOrigin: "top left",
                    width: node.offsetWidth + "px",
                    height: node.offsetHeight + "px",
                },
                filter: (node) => {
                    return node.className !== "image-background";
                },
            })
            .then(function (dataUrl: string) {
                resolve(dataUrl);
            })
            .catch(function (err: any) {
                reject(err);
            });
    });
};
