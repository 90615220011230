import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { IResourceItemNew, ITextAttribute } from '../../../../shared/models/resourceItemNew';
import {
    ISubText,
    SelectedText,
    SubText,
} from '../../../../shared/models/textStyle';
import { getBoundingTextBox, makeDivText, rgbToHex, getSizeDivText, getLinesFromContent, isEmptyLine } from '../../../utils';
import './index.scss';
import { useAppDispatch, useAppSelector } from '../../../../redux/hook';
import { updateResourceItem, setWordsCountDone } from '../../../../redux/reducers/createWorksheet';
import ConstantsTool from '../../../../shared/utils/ConstantsTool';

const CLASS_NAME_TEXT = 'canvas-text';

const CustomText = React.forwardRef<
    HTMLDivElement,
    {
        pageIndex: number;
        item: IResourceItemNew;
        textAttribute: ITextAttribute;
        opacity: number;
        isEditable?: boolean;
        onInput?(textAttribute: ITextAttribute): void;
        onSelectedText?(selectedText: SelectedText): void;
        selectedText?: SelectedText;
        isActive?: boolean;
    }
>(
    (
        {
            pageIndex,
            item,
            textAttribute,
            opacity,
            isEditable = false,
            onInput,
            onSelectedText,
            selectedText,
            isActive = false,
        },
        ref
    ) => {
        const dispatch = useAppDispatch();
        const fontSizeResourceItemsGroup = useAppSelector((state) => state.createWorksheetState.fontSizeResourceItemsGroup);
        const pageWorksheet = useAppSelector((state) => state.createWorksheetState.pagesWorksheet[pageIndex]);
        const { templateZoneDisplayMode, pageType, allFontsLoaded, autoResizeDone } = useAppSelector((state) => state.createWorksheetState);
        const refCurrent = useRef<HTMLDivElement>(null);
        const [focus, setFocus] = useState<boolean>(false);
        const [isOnEditText, setIsOnEditText] = useState(false);
        const [zoneDimenstion, setZoneDimenstion] = useState<object>({ width: item.width, height: item.height });

        useEffect(() => {
            const getOriginalDimenstionOfZone = () => {
                if (pageWorksheet.hasOwnProperty('resourceItems') && (item.customZoneID !== null)) {
                    const items = pageWorksheet['resourceItems'];

                    const originalZone = items.find((zone) => zone.id === item.customZoneID);

                    if (originalZone) {
                        setZoneDimenstion({
                            width: originalZone.width,
                            height: originalZone.height,
                        });
                    };
                };
            };

            getOriginalDimenstionOfZone();
        }, [item.customZoneID]);

        useEffect(() => {
            return () => {
                if (onSelectedText)
                    onSelectedText({ startOffset: -1, endOffset: -1 });
            };
        }, []);

        useLayoutEffect(() => {
            if (!isActive) {
                if (focus) {
                    setFocus(false);
                    makeContent();
                }
                if (isEditable) setIsOnEditText(false);
                resetSelectionText();
            }
        }, [isActive]);

        useLayoutEffect(() => {
            if (focus) {
                selectionText({
                    startOffset: 0,
                    endOffset: textAttribute.content.length,
                });
            }
        }, [focus]);

        useEffect(() => {
            if (selectedText?.startOffset < selectedText?.endOffset) {
                if (focus) {
                    makeContent();
                }
            }
            if (!focus) {
                makeContent();
            }
        }, [textAttribute]);

        useEffect(() => {
            if (
                selectedText &&
                selectedText.startOffset < selectedText.endOffset &&
                isActive
            ) {
                window.addEventListener("click", listenSelectText);
                window.addEventListener("keydown", listenKeyDown);
            }
            return () => {
                window.removeEventListener("click", listenSelectText);
                window.removeEventListener("keydown", listenKeyDown);
            };
        }, [selectedText, isActive]);

        useEffect(() => {
            if (
                selectedText &&
                selectedText.startOffset < selectedText.endOffset &&
                isActive
            ) {
                selectionText(selectedText);
            }
        }, [
            textAttribute?.fontColor,
            textAttribute?.fontFamily,
            textAttribute.subTexts.map((e) => e.fontColor),
            textAttribute.subTexts.map((e) => e.fontFamily),
        ]);

        useEffect(() => {
            const resizeText = () => {
                let hasPassageAndWordCountZoneInTextMode = templateZoneDisplayMode === 'textOnly' && item.fieldName === ConstantsTool.PASSAGE_ZONE && findWordCountZone();

                if (refCurrent.current && allFontsLoaded) {
                    const textComponent = refCurrent.current;
                    let currentFontSize = parseInt(textComponent.style.fontSize, 10);

                    if (textAttribute?.isAppliedAutoFontSize && item.fieldName !== ConstantsTool.WORD_COUNT_ZONE) {
                        dispatch(updateResourceItem({
                            currentElementId: item.id,
                            attrValues: [
                                {
                                    attr: 'textAttribute',
                                    value: {
                                        ...textAttribute,
                                        isAutoResizeDone: false,
                                    },
                                },
                            ],
                            pageIndex,
                        }));

                        const containerWidth = zoneDimenstion['width'];
                        const containerHeight = zoneDimenstion['height'];

                        const fontSizeMin = 7;

                        let { width: textWidth, height: textHeight } = getSizeDivText({
                            textAttribute,
                            width: item.width,
                            wordBreak: 'keep-all',
                            test: textAttribute.content.startsWith('It is')
                        });

                        const reduceFontSizeToFitTheZone = (counter: number) => {
                            if (textAttribute.content.startsWith('4 is 3 more than 12')) {
                                console.log('resizing', textAttribute.content, currentFontSize, 'text', textWidth, textHeight, 'container', containerWidth, containerHeight, 'counter', counter);
                            }
                            if ((textHeight > containerHeight || textWidth > containerWidth + 1) && currentFontSize > fontSizeMin && counter < 300) {
                                currentFontSize -= 1;

                                const textSize = getSizeDivText({
                                    textAttribute: {
                                        ...textAttribute,
                                        fontSize: currentFontSize,
                                    },
                                    width: item.width,
                                    wordBreak: 'keep-all',
                                    test: textAttribute.content.startsWith('The')
                                });
                                textWidth = textSize.width;
                                textHeight = textSize.height;

                                counter++;
                                setTimeout(() => reduceFontSizeToFitTheZone(counter), 0);
                            } else {
                                if (textAttribute.content.startsWith('<math>')) {
                                    currentFontSize -= 1;
                                }
                                updateTextComponentFontSize(currentFontSize);
                            }
                        }

                        reduceFontSizeToFitTheZone(0);

                        // if (pageType === ConstantsTool.PAGE_TYPES['thumbnail'] && textAttribute.content !== '') {
                        //     const resourceItemVerticalPadding = 20;

                        //     const increaseFontSizeToFitTheZone = (counter: number) => {
                        //         if (textHeight < (containerHeight - resourceItemVerticalPadding) && counter < 100) {
                        //             currentFontSize += 1;

                        //             textHeight = getSizeDivText({
                        //                 textAttribute: {
                        //                     ...textAttribute,
                        //                     fontSize: currentFontSize,
                        //                 },
                        //                 width: item.width,
                        //                 wordBreak: 'keep-all',
                        //                 test: textAttribute.content.startsWith(testStarter)
                        //             }).height;

                        //             counter++;
                        //             setTimeout(() => increaseFontSizeToFitTheZone(counter), 0);
                        //         } else {
                        //             updateTextComponentFontSize(currentFontSize);
                        //         }
                        //     };

                        //     increaseFontSizeToFitTheZone(0);
                        // }
                    }

                    if (hasPassageAndWordCountZoneInTextMode) {
                        setTimeout(() => calculateWordsPerLine(currentFontSize), 500);
                    }
                };
            };

            // Resize text initially and on window resize
            resizeText();

            window.addEventListener('resize', resizeText);

            // Clean up event listener on component unmount
            return () => {
                window.removeEventListener('resize', resizeText);
            };
        }, [
            textAttribute?.isAppliedAutoFontSize,
            textAttribute.fontSize,
            textAttribute.fontFamily,
            textAttribute.content,
            textAttribute.lineHeight,
            textAttribute.letterSpacing,
            zoneDimenstion,
            fontSizeResourceItemsGroup,
            allFontsLoaded
        ]);

        useEffect(() => {
            const handleFontSizeResourceItemGroup = () => {
                if (!autoResizeDone) return;

                if (textAttribute?.isAppliedAutoFontSize && item.fieldName !== null && item.fieldName !== ConstantsTool.WORD_COUNT_ZONE && pageIndex != null) {
                    const minFontSize = findFontSizeMinInGroup();
                    const textComponent = refCurrent.current;
                    let currentFontSize = parseInt(textComponent.style.fontSize, 10);

                    if (!minFontSize || currentFontSize === minFontSize) return;

                    updateTextComponentFontSize(minFontSize);
                }
            }

            handleFontSizeResourceItemGroup();
        }, [
            autoResizeDone,
            pageIndex,
            pageWorksheet,
            textAttribute.isAppliedAutoFontSize,
        ]);

        useEffect(() => {
            makeContent();
        }, [textAttribute?.isAppliedAutoFontSize]);

        const findFontSizeMinInGroup = () => {
            const filteredItems = [...pageWorksheet.resourceItems].filter(resourceItem => {
                if (resourceItem.fieldName === null || item.fieldName === null) {
                    return false;
                }
                // only group name before # in name
                let resourceItemName, itemName;

                if (resourceItem.fieldName === undefined || item.fieldName === undefined) {
                    return [];
                }

                if (resourceItem.fieldName.indexOf('#') > -1) {
                    resourceItemName = resourceItem.fieldName.substring(0, resourceItem.fieldName.indexOf('#'));
                } else {
                    resourceItemName = resourceItem.fieldName;
                }

                if (item.fieldName.indexOf('#') > -1) {
                    itemName = item.fieldName.substring(0, item.fieldName.indexOf('#'));
                } else {
                    itemName = item.fieldName;
                }
                return resourceItem.type === ConstantsTool.RESOURCE_TEXT && resourceItemName === itemName && resourceItem?.textAttribute.isAppliedAutoFontSize;
            });

            if (filteredItems.length === 0) {
                return undefined;
            }

            const fontSizes = filteredItems.map(i => i.textAttribute?.fontSize || 0);

            const minFontSize = Math.min(...fontSizes);

            return minFontSize;
        }

        const findWordCountZone = () => {
            return pageWorksheet.resourceItems.find((item: IResourceItemNew) => item.fieldName === ConstantsTool.WORD_COUNT_ZONE);
        }

        const updateTextComponentFontSize = (fontSize: number) => {
            const textComponent = refCurrent.current;
            textComponent.style.fontSize = `${fontSize}px`;
            if (textAttribute.content.startsWith('4 is 3 more than 12')) {
                console.log(textAttribute.content, 'resize done');
            }

            dispatch(updateResourceItem({
                currentElementId: item.id,
                attrValues: [
                    {
                    attr: 'textAttribute',
                    value: {
                        ...textAttribute,
                        subTexts: textAttribute.subTexts.map((subText) => ({ ...subText, fontSize })),
                        fontSize,
                        isAutoResizeDone: true,
                    },
                    },
                ],
                pageIndex,
            }));

        };

        const calculateWordsPerLine = (currentFontSize: number) => {
            const content = refCurrent.current?.textContent;
            if (content) {
                let lines = content.split('\n');
                let allLines = [];

                for (const line of lines) {
                    const childLines = getLinesFromContent(line, { ...textAttribute, fontSize: currentFontSize }, zoneDimenstion);
                    allLines.push(...childLines);
                };

                const wordCounts = allLines.map((line) => {
                    const words = line.trim().split(/\s+/).filter((word: string) => {
                        // Exclude special characters using a regular expression
                        return /[a-zA-Z0-9]+/.test(word);
                    });
                    return isEmptyLine(words) ? 0 : words.length;
                });

                setContentForWordCountZone(wordCounts)
            }
        };

        const setContentForWordCountZone = (wordCounts: number[]) => {
            let newContent = '';
            let numberWords = 0;

            for (let i = 0, length = wordCounts.length; i < length; i++) {
                numberWords += wordCounts[i];
                newContent += (i === 0 ? '' : '\n') + (wordCounts[i] !== 0 ? numberWords : '');
            }

            const wordCountItem = findWordCountZone();
            if (wordCountItem) {
                dispatch(updateResourceItem({
                    currentElementId: wordCountItem.id,
                    attrValues: [
                        {
                            attr: "textAttribute",
                            value: {
                                ...textAttribute,
                                content: newContent,
                            },
                        },
                    ],
                    pageIndex: pageIndex,
                }))
            }
            setTimeout(() => dispatch(setWordsCountDone(true)), 500);
        };

        const resetSelectionText = () => {
            if (onSelectedText && selectedText)
                if (
                    selectedText.startOffset !== -1 &&
                    selectedText.endOffset !== -1
                )
                    onSelectedText({ startOffset: -1, endOffset: -1 });
        };

        const makeContent = () => {
            if (refCurrent.current) {
                let node = refCurrent.current;
                makeDivText(textAttribute, node);
            }
        };

        const listenSelectText = (event: MouseEvent) => {
            if (document.activeElement.tagName !== "INPUT")
                selectionText(selectedText);
        };

        const selectionText = (selectedText: SelectedText) => {
            const getPosition = (
                listPosition: SelectedText[],
                selectedText: SelectedText
            ) => {
                let { startOffset, endOffset } = selectedText;
                let startIndex = listPosition.findIndex(
                    (e) =>
                        e.startOffset <= startOffset &&
                        e.endOffset > startOffset
                );

                let endIndex = listPosition.findIndex(
                    (e) =>
                        e.startOffset <= endOffset && e.endOffset >= endOffset
                );
                if (startIndex >= 0 && endIndex >= 0) {
                    startOffset -= listPosition[startIndex].startOffset;
                    endOffset -= listPosition[endIndex].startOffset;
                }
                return {
                    startIndex,
                    endIndex,
                    startOffset,
                    endOffset,
                };
            };

            const selection = window.getSelection();
            const editableDiv = refCurrent.current;
            if (
                !selection.toString().length &&
                editableDiv &&
                textAttribute.content.length
            ) {
                const range = document.createRange();
                let { startOffset, endOffset } = selectedText;
                let nodeStart = editableDiv.childNodes[0];
                let nodeEnd = editableDiv.childNodes[0];
                let chilSpans = editableDiv.querySelectorAll("span");
                if (chilSpans.length) {
                    let listPosition: SelectedText[] = [];
                    let offset = 0;
                    chilSpans.forEach((node, i) => {
                        let endOffset = offset + node.textContent.length;
                        listPosition.push({
                            startOffset: offset,
                            endOffset,
                        });
                        offset = endOffset;
                    });
                    let position = getPosition(listPosition, selectedText);
                    startOffset = position.startOffset;
                    endOffset = position.endOffset;
                    if (position.startIndex >= 0 && position.endIndex >= 0) {
                        nodeStart =
                            chilSpans[position.startIndex].childNodes[0];
                        nodeEnd = chilSpans[position.endIndex].childNodes[0];
                    }
                }
                if (
                    nodeStart.textContent.length >= startOffset &&
                    nodeEnd.textContent.length >= endOffset
                ) {
                    range.setStart(nodeStart, startOffset);
                    range.setEnd(nodeEnd, endOffset);
                    selection.removeAllRanges();
                    selection.addRange(range);
                }
            }
        };

        const onChangeEditText = (event: React.FormEvent<HTMLDivElement>) => {
            if (onInput && !(pageType === 'page' && templateZoneDisplayMode === 'textOnly')) {
                resetSelectionText();
                const node = event.currentTarget;
                let nodeChildSpans = node.querySelectorAll("span");
                let nodeChildDivs = node.querySelectorAll("div");
                onFindSelectionText(node);
                if (nodeChildSpans.length) {
                    let subTexts: ISubText[] = [];
                    let startOffset = 0;
                    nodeChildSpans.forEach((child) => {
                        if (!child.children.length) {
                            let text = child.innerText;
                            let {
                                color,
                                fontWeight,
                                fontStyle,
                                textDecoration,
                                fontFamily,
                                fontSize,
                            } = child.style;

                            if (
                                child.parentElement.tagName === "DIV" &&
                                startOffset
                            ) {
                                if (
                                    child.parentElement.querySelectorAll(
                                        "span"
                                    )[0] === child
                                ) {
                                    startOffset += 1;
                                }
                            }

                            let fontSizeNum = parseFloat(
                                fontSize.length ? fontSize : "0"
                            );
                            if (fontFamily.length) {
                                fontFamily = fontFamily.replaceAll(`\"`, "");
                            }
                            let subText = new SubText({
                                endOffset: startOffset + text.length,
                                startOffset,
                                fontColor: color?.length
                                    ? rgbToHex(color)
                                    : textAttribute.fontColor,
                                isBold: fontWeight === "bold",
                                isItalic: fontStyle === "italic",
                                underline: textDecoration === "underline",
                                fontSize:
                                    fontSizeNum > 0
                                        ? fontSizeNum
                                        : textAttribute.fontSize,
                                fontFamily: fontFamily.length
                                    ? fontFamily
                                    : textAttribute.fontFamily,
                            });
                            subTexts.push(subText);
                            startOffset += text.length;
                            if (!child.className) {
                                child.className = CLASS_NAME_TEXT;
                            }
                        }
                    });
                    onInput({
                        ...textAttribute,
                        content: node.innerText,
                        subTexts,
                    });
                } else {
                    nodeChildDivs.forEach((child) => {
                        if (!child.className) {
                            child.className = CLASS_NAME_TEXT;
                        }
                    });
                    onInput({ ...textAttribute, content: node.innerText });
                }
            }
        };

        const onFindSelectionText = (element: HTMLElement) => {
            let isSelected = false;
            if (window.getSelection) {
                const selection = window.getSelection();
                let text = "",
                    startOffset = -1;
                if (selection && selection.rangeCount > 0) {
                    const range = selection.getRangeAt(0);
                    isSelected = range.intersectsNode(element);
                    if (isSelected) {
                        text = selection.toString();
                        startOffset = range.startOffset;
                        let childrenSpans = element.querySelectorAll("span");

                        let indexNodeAnchor = Array.from(childrenSpans).indexOf(
                            selection.anchorNode.parentElement
                        );
                        let indexNodeFocus = Array.from(childrenSpans).indexOf(
                            selection.focusNode.parentElement
                        );
                        let indexNode = Math.min(
                            indexNodeAnchor,
                            indexNodeFocus
                        );
                        if (indexNode !== -1) {
                            let length = 0;
                            for (let i = 0; i < indexNode; i++) {
                                let child = childrenSpans[i];
                                length += child.innerText.length;
                            }
                            startOffset += length;
                        }
                    }
                }
                if (onSelectedText) {
                    onSelectedText({
                        startOffset,
                        endOffset: startOffset + text.length,
                    });
                }
            }
        };

        const onMouseDown = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (isEditable) {
                if (isActive && !isOnEditText) {
                    setIsOnEditText(true);
                }
            }
            if (document.activeElement === event.currentTarget) {
                const selection = window.getSelection();
                selection.removeAllRanges();
                if (event.currentTarget.textContent.length) {
                    event.stopPropagation();
                }
            }
        };

        const listenKeyDown = (event: any) => {
            if (
                (event.key === "Delete" || event.key === "Backspace") &&
                selectedText &&
                selectedText.startOffset < selectedText.endOffset
            ) {
                resetSelectionText();
            }
        };

        const onKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
            const node = event.currentTarget;
            if (event.key.includes("Arrow")) onFindSelectionText(node);
        };

        const onMouseUp = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (onSelectedText && isActive) {
                onFindSelectionText(event.currentTarget);
            }
        };

        const onMouseLeave = (
            event: React.MouseEvent<HTMLDivElement, MouseEvent>
        ) => {
            if (onSelectedText && isActive && event.buttons && focus) {
                onFindSelectionText(event.currentTarget);
            }
        };
        const bounding = getBoundingTextBox(textAttribute);
        return (
            <div
                id={`zone-${item.id}`}
                className={`canvas-text custom-text-container vertical-align-${textAttribute.verticalAlign} ${textAttribute.textCase === 'Aa' ? 'capitalize-first-letter' : ''}`}
                ref={(node) => {
                    if (typeof ref === "function") {
                        ref(node);
                    } else if (ref) {
                        ref.current = node;
                    }
                    refCurrent.current = node;
                }}
                contentEditable={isEditable && isOnEditText}
                style={{
                    fontSize: textAttribute.fontSize,
                    color: textAttribute.fontColor ?? "#212121",
                    fontFamily: textAttribute.fontFamily,
                    fontStyle: textAttribute.isItalic ? "italic" : "normal",
                    fontWeight: textAttribute.isBold ? "bold" : null,
                    WebkitTextStroke: textAttribute.isStroke ? `${textAttribute.strokeWidth}px ${textAttribute.strokeColor}` : null,
                    textAlign: textAttribute.align,
                    textDecoration:
                        textAttribute.underline &&
                            !textAttribute.subTexts.length
                            ? "underline"
                            : "none",
                    whiteSpace: "pre-line",
                    wordBreak: "keep-all",
                    outline: "none",
                    border: "none",
                    WebkitUserModify: focus
                        ? "read-write-plaintext-only"
                        : null,
                    opacity,
                    lineHeight:
                        textAttribute.lineHeight > 0
                            ? textAttribute.lineHeight
                            : null,
                    letterSpacing: textAttribute.letterSpacing + "em",
                    paddingLeft: bounding.paddingLeft,
                    textTransform: textAttribute.textCase === 'AA' ? 'uppercase' : textAttribute.textCase === 'aa' ? 'lowercase' : textAttribute.textCase === 'Aa' ? 'lowercase' : 'none'
                }}
                onInput={onChangeEditText}
                onMouseDown={onMouseDown}
                onMouseUp={onMouseUp}
                onMouseLeave={onMouseLeave}
                onBlur={() => {
                    setFocus(false);
                    setIsOnEditText(false);
                    makeContent();
                }}
                onFocus={() => {
                    setFocus(true);
                }}
                onKeyUp={onKeyUp}
                onKeyDown={(event) => {
                    if (event.ctrlKey || event.metaKey) {
                        if (event.key === "z") event.currentTarget.blur();
                    }
                }}
                suppressContentEditableWarning
            ></div>
        );
    }
);

export default CustomText;
