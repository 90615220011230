import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../../../../redux/hook';
import { removeResourceItems, updateResourceItem } from '../../../../../redux/reducers/createWorksheet';
import { Command, IResourceItemNew } from '../../../../../shared/models/resourceItemNew';
import { EditableMathField, addStyles } from 'abc-react-mathquill';
// inserts the required css to the <head> block.
// you can skip this, if you want to do that by yourself.
addStyles()

import { getBoundingTextBox } from '../../../../../resource-user/utils';
import { extractMathExpression } from '../../../../../resource-user/utils/worksheet.utils';

const customeStyles = () => {
    // Get the element using its class
    let style = (document.getElementById('react-mathquill-styles') as any)
    // remove border and hover
    style.sheet.cssRules[4].style = {}
    style.sheet.cssRules[5].style = {}
}

const EditableMathFormula = ({
    item,
    pageIndex,
    currentElement,
    setCurrentElement,
}: {
    item: IResourceItemNew;
    pageIndex: number;
    ratio?: number;
    currentElement: IResourceItemNew;
    setCurrentElement?: (currentElement: IResourceItemNew) => void;
}) => {
    const textAttribute = item?.textAttribute;
    const dispatch = useAppDispatch();
    const refCurrent = useRef<HTMLDivElement>(null);

    const [zoneDimenstion, setZoneDimenstion] = useState<object>({ width: item.width, height: item.height });
    const pageWorksheet = useAppSelector((state) => state.createWorksheetState.pagesWorksheet[pageIndex]);
    const fontSizeResourceItemsGroup = useAppSelector((state) => state.createWorksheetState.fontSizeResourceItemsGroup);
    const allFontsLoaded = useAppSelector((state) => state.createWorksheetState.allFontsLoaded);

    const expressions = useMemo(() => extractMathExpression(textAttribute.content), [textAttribute.content]);

    useEffect(() => {
        const getOriginalDimenstionOfZone = () => {
            if (pageWorksheet.hasOwnProperty('resourceItems') && (item.customZoneID !== null)) {
                const items = pageWorksheet['resourceItems'];

                const originalZone = items.find((zone) => zone.id === item.customZoneID);

                if (originalZone) {
                    setZoneDimenstion({
                        width: originalZone.width,
                        height: originalZone.height,
                    });
                };
            };
        };

        getOriginalDimenstionOfZone();
    }, [item.customZoneID]);

    useEffect(() => {
        customeStyles();
    }, []);

    // HOT FIX: convert Solvetheproblem\frac{2}{7}+\frac{1}{9}=? to Solve the problem \frac{2}{7}+\frac{1}{9}=?
    const splitWordsInMathFormular = (textContent) => {
        const mathExpressionItems = document.querySelectorAll('#math-formular-zone-' + item.customZoneID + ' > .mq-math-mode > .mq-root-block > var');
        console.log(mathExpressionItems.length, 'items', textContent);

        let nbSpaceCount = 0;
        if (textContent.indexOf(' ') > -1 && mathExpressionItems.length > 0) {
            for (let i = 0; i < textContent.length; i++) {
                if (textContent[i] === ' ') {
                    const item: any = mathExpressionItems[i - 1 - nbSpaceCount];
                    if (item && !item.classList.contains('mq-end-char-of-word')) {
                        item.classList.add('mq-end-char-of-word');
                    }
                    nbSpaceCount++;
                }
            }
        }

    }

    useEffect(() => {
        // Resize text initially and on window resize
        const resizeText = () => {
            if (refCurrent.current && allFontsLoaded) {
                const textComponent = refCurrent.current;
                let currentFontSize = parseInt(textComponent.style.fontSize, 10);

                if (textAttribute?.isAppliedAutoFontSize) {
                    console.log('>', textAttribute.content);
                    const containerWidth = zoneDimenstion['width'];
                    const containerHeight = zoneDimenstion['height'];

                    const fontSizeMin = 7;

                    let textHeight = textComponent.offsetHeight;
                    let textWidth = textComponent.scrollWidth;

                    const reduceFontSizeToFitTheZone = () => {
                        if ((textHeight > containerHeight || textWidth > containerWidth + 1) && currentFontSize > fontSizeMin) {
                            currentFontSize -= 1;

                            textComponent.style.fontSize = `${currentFontSize}px`;
                            textHeight = textComponent.offsetHeight;
                            textWidth = textComponent.scrollWidth;

                            setTimeout(reduceFontSizeToFitTheZone, 0);
                        } else {
                            updateTextComponentFontSize(currentFontSize);
                        }
                    };

                    reduceFontSizeToFitTheZone();
                };
            };
        };

        // Resize text initially and on window resize
        resizeText();

        // HOTFIX add space after , in math expression
        const mathExpressions = document.querySelectorAll('.mq-math-mode .mq-root-block');
        for (let i = 0; i < mathExpressions.length; i++) {
            for (let j = 0; j < mathExpressions[i].childNodes.length; j++) {
                const item: any = mathExpressions[i].childNodes[j];
                if (item.textContent === ',' && !item.classList.contains('mq-comma-space')) {
                    item.classList.add('mq-comma-space');
                    // mathExpressions[i].childNodes[j].textContent = ', ';
                }

                // if (item.classList.contains('mq-overline')) {
                //     console.log('OVER LINE > ', item);
                // }
            }
        }

        window.addEventListener('resize', resizeText);

        setTimeout(() => {
            splitWordsInMathFormular(textAttribute.content);
        }, 0);

        // Clean up event listener on component unmount
        return () => {
            window.removeEventListener('resize', resizeText);
        };
    }, [
        textAttribute?.isAppliedAutoFontSize,
        textAttribute.fontSize,
        textAttribute.fontFamily,
        textAttribute.content,
        textAttribute.lineHeight,
        textAttribute.letterSpacing,
        zoneDimenstion,
        allFontsLoaded,
        fontSizeResourceItemsGroup,
    ]);

    useEffect(() => {
        if (item.textAttribute.content.length === 0 && currentElement === null) {
            dispatch(
                removeResourceItems({
                    resourceIds: [item.id],
                    pageIndex: item.pageIndex,
                })
            );
        }
    }, [currentElement]);

    const updateTextComponentFontSize = (fontSize: number) => {
        const textComponent = refCurrent.current;
        textComponent.style.fontSize = `${fontSize}px`;

        dispatch(updateResourceItem({
            currentElementId: item.id,
            attrValues: [
                {
                    attr: 'textAttribute',
                    value: {
                        ...textAttribute,
                        subTexts: textAttribute.subTexts.map((subText) => ({ ...subText, fontSize })),
                        fontSize,
                    },
                },
            ],
            pageIndex,
        }));
    };

    const bounding = getBoundingTextBox(item.textAttribute);

    return (
        <div
            ref={refCurrent}
            className={`canvas-text custom-text-container vertical-align-${textAttribute.verticalAlign} ${textAttribute.textCase === 'Aa' ? 'capitalize-first-letter' : ''}`}
            style={{
                fontSize: textAttribute.fontSize,
                color: textAttribute.fontColor ?? "#212121",
                fontFamily: textAttribute.fontFamily,
                fontStyle: textAttribute.isItalic ? "italic" : "normal",
                fontWeight: textAttribute.isBold ? "bold" : null,
                WebkitTextStroke: textAttribute.isStroke ? `${textAttribute.strokeWidth}px ${textAttribute.strokeColor}` : null,
                textAlign: textAttribute.align,
                textDecoration:
                    textAttribute.underline &&
                        !textAttribute.subTexts.length
                        ? "underline"
                        : "none",
                whiteSpace: "pre-line",
                wordBreak: "keep-all",
                outline: "none",
                border: "none",
                WebkitUserModify: focus
                    ? "read-write-plaintext-only"
                    : null,
                opacity: item?.opacity,
                lineHeight:
                    textAttribute.lineHeight > 0
                        ? textAttribute.lineHeight
                        : null,
                letterSpacing: textAttribute.letterSpacing + "em",
                paddingLeft: bounding.paddingLeft,
                textTransform: textAttribute.textCase === 'AA' ? 'uppercase' : textAttribute.textCase === 'aa' ? 'lowercase' : textAttribute.textCase === 'Aa' ? 'lowercase' : 'none'
            }}
            id={'math-formular-zone-' + item.customZoneID}>
                {expressions.map((expression, index) => (
                    <Fragment key={index}>
                        {(expression.type === 'string') ? (
                            expression.content
                        ) : (expression.type === 'math') ? (
                            <EditableMathField
                                latex={expression.content}
                                cmd={item.textAttribute.cmd}
                                onChange={(mathField) => {
                                    let newLatex = mathField.latex();
                                    let el = mathField.el();
                                    // let width = el.clientWidth;
                                    // let height = el.clientHeight;
                                    if (newLatex !== expression.content) {
                                        let textContent = expressions.map((expression, eIndex) => eIndex === index ? newLatex : expression.content).join('');

                                        dispatch(
                                            updateResourceItem({
                                                currentElementId: item.id,
                                                pageIndex: pageIndex,
                                                attrValues: [
                                                    {
                                                        attr: "textAttribute",
                                                        value: {
                                                            ...item.textAttribute,
                                                            content: textContent,
                                                            cmd: new Command({
                                                                id: new Date().getTime(),
                                                                content: ""
                                                            })
                                                        },
                                                    },
                                                ],
                                            })
                                        );
                                    }
                                }}
                            />
                        ) : null
                        }
                    </Fragment>
                ))}
        </div>
    )
}

export default EditableMathFormula;